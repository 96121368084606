import React, { Fragment, Component } from 'react';
import compose from 'lodash/fp/compose';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Select, Input, Form, FormModal } from 'components/common/FormItems';
import {
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withAccount,
  withMsgInfo,
  withTranslation,
  withUserDefaultSpace,
  withDateFormat,
  withLanguage,
  withUserPreferences,
  withNumberFormat,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
} from 'components/hoc';
import * as actions from 'actions';
import { passwordStrength } from 'components/utils/passwordStrength';
import { sweetAlert } from 'components/utils';
import { updateUserNameService } from '../Settings/services/settings';
import StepsPrograssBar from 'react-line-progress-bar-steps';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import Button from '../../../components/common/FormItems/Button';

const StyledFormModal = styled(FormModal)`
  div.body {
    margin-top: 0;
    top: 10%;
    height: auto;
  }
`;

const UserNameDeleteAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    flex: 1;
  }

  > div:nth-child(2) {
    width: fit-content;
  }
`;

const DeleteAccount = styled.a`
  border: 1px solid;
  padding: 8px;
  border-radius: 20px;
`;

const ProgressFizeSize = styled.div`
  width: 200px;
`;

const changePwFormSchema = {
  old_password: {
    validation: 'required',
  },
  new_password: {
    validation: 'required|min:14',
  },
};

const UpdateSpaceAdmin = (props) => {
  const { open, toggleForm, spaceMember, assignSpaceAdmin, getUser } = props;
  const currentAdmin = React.useMemo(() => {
    return spaceMember.data.find(({ is_space_admin }) => is_space_admin)?.id;
  }, [spaceMember.data]);
  const [newAdmin, setNewAdmin] = React.useState(currentAdmin);
  const options = React.useMemo(() => {
    return spaceMember.data.map(({ id, username }) => ({
      id: String(id),
      name: username,
    }));
  }, [spaceMember.data]);

  const onSubmit = async () => {
    if (!newAdmin || String(currentAdmin) === String(newAdmin)) return;
    try {
      await assignSpaceAdmin({ new_admin: newAdmin });
      await getUser();
      toggleForm();
    } catch (e) {}
  };

  return (
    <StyledFormModal
      title={'Assign Space Admin'}
      open={open}
      toggleForm={toggleForm}
      onSubmit={onSubmit}
      actionButtonName={'Update'}
    >
      <Select
        options={options}
        name="newAdmin"
        value={newAdmin || currentAdmin}
        optionDefaultValue="Choose New Admin"
        onChange={({ target }) => setNewAdmin(target.value)}
        label="New Admin"
      />
    </StyledFormModal>
  );
};

const DefaultSpaceSelect = (props) => {
  const { changeUserDefaultSpace, userDefaultSpace, workspaces, setMsgInfo, tr } = props;
  const [defaultSpace, setDefaultSpace] = React.useState(userDefaultSpace.space);
  const loading = React.useRef(false);

  const dispatch = useDispatch();

  const handleChange = async ({ target }) => {
    const { value } = target;
    setDefaultSpace(value);
    if (!loading.current) {
      await changeUserDefaultSpace(value);
      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${tr('Workspace successfully changed')}`]
        }),
      );
      loading.current = true;
    }
  };

  return (
    <Select
      name="space"
      value={defaultSpace || userDefaultSpace.space}
      optionDefaultValue="Change Workspace"
      onChange={handleChange}
      options={workspaces}
      label="Change Workspace"
    />
  );
};

const FileSize = (props) => {
  let color1 = '#b8b79c';
  let color2 = '#b8b79c';
  let color3 = '#b8b79c';
  let color4 = '#b8b79c';

  if (props.totalFileSize == '1B' && props.totalFileSize <= '100KB') {
    color1 = '#ded916';
  } else if (props.totalFileSize == '100KB' && props.totalFileSize <= '500KB') {
    color2 = '#ded916';
  } else if (props.totalFileSize == '500GB' && props.totalFileSize <= '1GB') {
    color3 = '#ded916';
  } else if (props.totalFileSize == '1GB') {
    color4 = '#ded916';
  }

  const color = [color1, color2, color3, color4];
  // const color = ['#b8b79c', '#c9c8a1', '#ded916', '#dedb5d', '#ded916'];

  return (
    <ProgressFizeSize>
      <b>{props.totalFileSize} OF 1GB IN USE</b>
      <StepsPrograssBar colors={color} />
    </ProgressFizeSize>
  );
};

class User extends Component {
  constructor(props) {
    super(props);
    this.state = { userName: this.props.account.user.username };
  }

  state = {
    data_storage: 'local',
    workspace_type: 'personal',
    changePwForm: false,
    old_password: '',
    new_password: '',
    isLoading: false,
    updateSpaceAdmin: false,
    old_username: '',
    new_username: '',
  };

  getOptions = () => {
    const { dateFormatList, userPreLang } = this.props;
    return dateFormatList.map((item) => ({
      id: item.id,
      name: item[`long_description_${userPreLang}`],
    }));
  };

  onSelect = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onClickChangePassword = () => {
    this.onSelect('changePwForm', !this.state.changePwForm);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deleteAccount = () => {
    const { logout, deleteUserById, tr } = this.props;
    const [user] = this.props.user.data;
    if (user?.id) {
      sweetAlert({
        title: tr('Delete Account'),
        content: tr('Are you sure you want to delete account?'),
        tr,
        actions: [
          {
            label: tr('Yes'),
            onClick: async () => {
              try {
                await deleteUserById(user.id);
                logout();
              } catch (e) {}
            },
          },
        ],
      });
    }
  };

  isValidPasswordData(data) {
    const { new_password, old_password } = data,
      valid =
        !!new_password && !!old_password && !passwordStrength(new_password).hasError;
    return valid;
  }

  isValidUsernameData(data) {
    const { new_username, old_username } = data,
      valid = !!new_username && !!old_username;
    return valid;
  }

  onSubmitChangePassword = (e) => {
    e.preventDefault();
    if (!this.isValidPasswordData(this.state)) {
      const { errors } = passwordStrength(this.state.new_password);
      this.props.setMsgInfo({
        success: false,
        msg: errors,
      });
      return;
    }
    this.props
      .passwordChange(this.state)
      .then((data) => {
        this.setState({
          old_password: '',
          new_password: '',
        });
      })
      .catch((data) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onDateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.setDate({ date: e.target.value });
  };

  handleUsernameChange(event) {
    this.setState({ userName: event.target.value });
  }

  handleSubmitUsername = () => {
    const { tr } = this.props;
    if (
      this.state.userName &&
      this.state.userName !== this.props.account.user.username
    ) {
      sweetAlert({
        title: tr('Change Username'),
        content: tr('Are you sure you want to change username?'),
        tr,
        actions: [
          {
            label: tr('Yes'),
            onClick: async () => {
              try {
                updateUserNameService({
                  id: this.props.account.user.user_id,
                  org_id: this.props.account.user.org_id,
                  username: this.state.userName,
                }).then((data) => {
                  if (data) {
                    window.location.reload(false);
                  }
                });
              } catch (e) {}
            },
          },
        ],
      });
    }
  };

  handleChangeAdmin = () => {
    const { updateSpaceAdmin } = this.state;
    this.setState(() => ({ updateSpaceAdmin: !updateSpaceAdmin }));
  };

  render() {
    const { changePwForm, old_password, new_password } = this.state;

    const {
      userPersonalWorkspaceOptions,
      userOrganisationWorkspaceOptions,
      account,
      tr,
      selectedLocale,
      userPrefDateFormat,
      userPreNumberFormat,
      languageOptions,
      updateUserPreferences,
      handlePreferenceChange,
      isSubmitting,
      numberFormatLocaleOptions,
      user: {
        data: [user = {}],
      },
    } = this.props;

    const workspace_type =
      account.user.space_type === 'P' ? 'personal' : 'organisation';

    const workspaces = (
      workspace_type === 'personal'
        ? userPersonalWorkspaceOptions
        : userOrganisationWorkspaceOptions
    ).map(({ name }) => ({
      id: name,
      name,
    }));

    return (
      <>
        <UpdateSpaceAdmin
          {...this.props}
          open={this.state.updateSpaceAdmin}
          toggleForm={this.handleChangeAdmin}
          user={user}
        />

        <Form
          title={tr('User Setting')}
          onSubmit={() => updateUserPreferences()}
          render={() => (
            <Fragment>
              <div className="form-group">
                <div className="form-label">{tr('User Type')}</div>
                <input
                  type="text"
                  className="form-control
                  forms-input"
                  placeholder="Username"
                  value={workspace_type === 'personal' ? 'Personal' : 'Organisation'}
                  disabled={true}
                />
              </div>

              <div className="form-group hide">
                <div className="custom-controls-stacked custom-style">
                  <label className="custom-control custom-radio custom-control-inline">
                    <input
                      onChange={(e) => this.onSelect('workspace_type', 'personal')}
                      name="workspace_type"
                      type="radio"
                      className="custom-control-input"
                      checked={workspace_type === 'personal'}
                    />
                    <span className="custom-control-label">
                      {tr('Personal Spaces')}
                    </span>
                  </label>

                  <label
                    className="custom-control custom-radio
                    custom-control-inline"
                  >
                    <input
                      onChange={(e) => this.onSelect('workspace_type', 'org')}
                      name="workspace_type"
                      type="radio"
                      className="custom-control-input"
                      checked={workspace_type === 'org'}
                    />
                    <span className="custom-control-label">
                      {tr('Organisation Spaces')}
                    </span>
                  </label>
                </div>
              </div>

              <UserNameDeleteAccountWrapper>
                <div className="form-group">
                  <div className="form-label">{tr('Username')}</div>
                  <input
                    type="text"
                    className="form-control forms-input"
                    placeholder={account.user.username || 'Username'}
                    value={this.state.userName ? this.state.userName : null}
                    onChange={(text) => this.handleUsernameChange(text)}
                  />
                </div>

                <div className="form-group">
                  <p className="delete-account">
                    <DeleteAccount onClick={this.deleteAccount}>
                      {tr('Delete Account')}
                    </DeleteAccount>
                  </p>
                </div>
              </UserNameDeleteAccountWrapper>

              <div className="form-group">
                <div className="form-label">{tr('Email')}</div>
                <input
                  type="email"
                  className="form-control forms-input"
                  placeholder={account.user.email || 'yourname@domain.com'}
                  disabled
                />
              </div>

              <div className="form-group">
                <label className="form-label">{tr('Change Password')}</label>
                {!changePwForm && (
                  <div className="change-password-wrapper">
                    <div className="wrapper-info">
                      <span className="lock-icon">
                        <i className="fa fa-key"></i>
                      </span>

                      <span className="lock-info">{tr('Change Password')}</span>
                    </div>

                    <div className="edit-btn">
                      <button
                        onClick={this.onClickChangePassword}
                        className="btn btn-display"
                        type="button"
                      >
                        <i className="fa fa-edit"></i>
                        {tr('Edit')}
                      </button>
                    </div>
                  </div>
                )}

                {changePwForm && (
                  <Form
                    onSubmit={() => null}
                    formData={{ old_password, new_password }}
                    validationSchema={changePwFormSchema}
                    title=""
                    renderActionButtons={() => null}
                  >
                    <div className="change-password-wrapper inputs-wrapper">
                      <Input
                        name="old_password"
                        type="password"
                        placeholder="Enter current password"
                        onChange={this.onChange}
                        value={old_password}
                        autoComplete="off"
                        required
                      />
                      <Input
                        type="password"
                        value={new_password}
                        name="new_password"
                        onChange={this.onChange}
                        fieldType="password"
                        placeholder="Enter new password"
                        autoComplete="off"
                        required
                      />

                      <div className="flex-1">
                        <a
                          onClick={this.onClickChangePassword}
                          className="pw-cancel"
                        >
                          {tr('Cancel')}
                        </a>
                        <button
                          onClick={this.onSubmitChangePassword}
                          type="button"
                          className="btn btn-primary change-pw"
                        >
                          {tr('Save')}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </div>

              <div className="form-group">
                <DefaultSpaceSelect {...this.props} workspaces={workspaces} />

                {user.is_space_admin && (
                  <p className="admin">
                    {tr('You are the Administrator -')}{' '}
                    <a onClick={this.handleChangeAdmin}>{tr('Change')}</a>
                  </p>
                )}
              </div>

              <div className="mt-6 mb-6">
                <FileSize {...this.props} />
              </div>

              <Select
                name="selectedLocale"
                id="selectedLocale"
                value={selectedLocale}
                optionDefaultValue={tr('Choose Language')}
                onChange={handlePreferenceChange}
                options={languageOptions.filter(({ id }) =>
                  ['eng', 'deu'].includes(id),
                )}
                label={tr('Choose Language')}
              />
              <Select
                name="dateFormatId"
                id="dateFormat"
                value={userPrefDateFormat}
                optionDefaultValue="Choose Date Format"
                onChange={handlePreferenceChange}
                options={this.getOptions()}
                label="Choose Date Format"
              />
              <Select
                name="numberFormat"
                value={userPreNumberFormat}
                optionDefaultValue="Choose Number Format"
                onChange={handlePreferenceChange}
                localeOptions={numberFormatLocaleOptions}
                label="Choose Number Format"
              />
            </Fragment>
          )}
          renderActionButtons={() => (
            <Button
              type="submit"
              // className="btn btn-primary"
              actionButton
              disabled={isSubmitting}
              isLoading={isSubmitting}
              name={tr('Save')}
              onClick={this.handleSubmitUsername}
            >
              {tr('Save')}
            </Button>
          )}
        />
      </>
    );
  }
}

export default compose(
  withUserPersonalWorkspace,
  withUserOrganisationWorkspace,
  withAccount,
  withMsgInfo,
  withTranslation,
  withUserDefaultSpace,
  withDateFormat,
  withLanguage,
  withUserPreferences,
  withNumberFormat,
  withAuth,
  withSpace,
  withDocument,
  withPersonalInfo,
  withFileSize,
)(User);

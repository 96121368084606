import React from 'react';
// import { withTranslation, Trans } from "react-i18next";

import {
  createPersonalSpace,
  joinPersonalSpace,
  setMsgInfo,
} from '../../../../actions';
import ToggleSwitch from 'components/common/ToggleSwitch';
import compose from 'lodash/fp/compose';
import {
  withAllPersonalWorkspace,
  withMsgInfo,
  withTranslation,
} from 'components/hoc';
import { CenterContentDiv, FormLabel } from '../SettingsFormUtils';
import { Select, Input, Button } from 'components/common/FormItems';
import { useDispatch } from 'react-redux';

// const JOIN_WORKSPACE = 'p';
// const CREATE_WORKSPACE = 'o';

const PersonalComponent = (props) => {
  const { tr, setMsgInfo } = props;
  const [joinWorkspace, setJoinedWorkspace] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({});
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onToggleSwitch = () => {
    setJoinedWorkspace(!joinWorkspace);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let data;
      let payload;
      if (joinWorkspace) {
        payload = {
          space_name: values.name,
          spaceType: 'Personal User',
          create_personal_workspace: values.name,
        };
        data = await dispatch(joinPersonalSpace(values?.name, payload));
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('The access request was sent to the space administrator')}`]
          }),
        );
      } else {
        let payload = {
          space_name: values.name,
          spaceType: 'Personal User',
          create_personal_workspace: values.name,
        };
        data = await dispatch(createPersonalSpace(payload));
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Personal space successfully created')}`],
            // navigationCounter: 1,
          }),
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };


  return (
    <>
      <ToggleSwitch
        label={tr('Join Personal Workspace')}
        checked={joinWorkspace}
        handleChange={onToggleSwitch}
      />
      <ToggleSwitch
        label={tr('Create Personal Workspace')}
        checked={!joinWorkspace}
        handleChange={onToggleSwitch}
      />
      <FormLabel>
        {!joinWorkspace ? tr('Create Workspace') : tr('Join Workspace')}
      </FormLabel>
      <Input
        name="name"
        type="text"
        onChange={handleChange}
        value={values?.name}
        autoComplete="off"
        required
      />
      <CenterContentDiv>
        <Button
          actionButton
          name={tr('Save')}
          style={{ width: '130px' }}
          onClick={handleSubmit}
          isLoading={loading}
        />
      </CenterContentDiv>
    </>
  );
};

export default compose(
  withAllPersonalWorkspace,
  withTranslation,
  withMsgInfo,
)(PersonalComponent);

import React, { useState } from 'react';
import compose from 'lodash/fp/compose';

import { getSingleIncomeTransaction } from 'actions';

import { useDispatch } from 'react-redux';
import { withTranslation } from '../../../../hoc';

import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import Form from './Form';
import { useListTransaction } from '../hooks/useListTransaction';

const columns = [
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'person',
    label: 'Person',
  },
  {
    id: 'financial_account',
    label: 'Financial Account',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'date',
    label: 'Transaction Date',
  },
  {
    id: '',
    label: '',
  },
];

const options = ({ change }) => [
  {
    onClick: change,
    name: 'Revert/View',
  },
];

const Contract = (props) => {
  const [openModal, toggleModal] = useState(false);
  const [formMode, setFormMode] = useState('create');

  const dispatch = useDispatch();

  const handleChangeItem = (data) => {
    dispatch(getSingleIncomeTransaction(data.id));
    toggleModal(true);
    setFormMode('update');
  };

  const { data } = useListTransaction('asset_shift');

  const mapFields = () =>
    data?.map((income) => ({
      id: income.id,
      space_name: income.space,
      person: `${income?.personal_details?.first_name} ${
        income?.personal_details?.other_names ?? ''
      }`,
      description: income.description,
      financial_account: props.trObj(
        income.transaction_item[0].financial_account_details,
      ),
      date: props.fd(income.date),
      currency: income.transaction_item[0].currency,
      amount: income.transaction_item[0].amount,
      contract: income.contract_details?.description,
    }));

  const tableProps = {
    frontendTableName: "asset_shift",
    backendTableName: "bank_account",
  }

  return (
    <React.Fragment>
      {openModal && (
        <Form
          open={openModal}
          formMode={formMode}
          toggleForm={() => {
            toggleModal(!openModal);
            setFormMode('create');
          }}
        />
      )}
      <MyDataContentTemplate
        {...tableProps}
        columns={columns}
        data={mapFields() || []}
        toggleForm={() => toggleModal(!openModal)}
        hasBulkDelete={false}
        options={options({
          change: handleChangeItem,
        })}
      />
    </React.Fragment>
  );
};

export default compose(withTranslation)(Contract);

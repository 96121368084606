import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Button } from 'components/common/FormItems';
import {
  withBulkDelete,
  withUserLayoutChoice,
  withTranslation,
  withMsgInfo,
} from 'components/hoc';
import { approveSpaceMember, disapproveSpaceMember } from 'actions';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';
import swal from 'sweetalert';
import LottieLoader from 'components/common/LottieLoader';

// const ButtonWrapper = styled.div`
//   margin-top: -6px;
// `;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 5px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;

const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;

  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);
    width: auto;
    max-width: 94%;

    @media screen and (max-width: 768px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 87%;
    }

    @media screen and (min-width: 1200px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 100%;
    }
  }
`;
// export const StyledContentTemplate = styled(ContentTemplate)`
//   div.table-responsive {
//     height: auto;
//     min-height: 500px;
//     max-height: 100%;
//     width: auto;
//     max-width: 93%;
//     background: var(--admincat-color-grey-1);

//     @media screen and (max-width: 768px) {
//       height: auto;
//       min-height: 500px;
//       max-height: 100%;
//       width: auto;
//       max-width: 85%;
//       background: var(--admincat-color-grey-1);
//     }

//     @media screen and (min-width: 1200px) {
//       height: auto;
//       min-height: 500px;
//       max-height: 100%;
//       width: auto;
//       max-width: 93%;
//       background: var(--admincat-color-grey-1);
//     }
//   }
// `;

const SpaceContentTemplate = (props) => {
  const [approveMember, setApproveMember] = React.useState({});
  // const [disapproveMember, setDisapproveMember] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const {
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    msg,
    tr,
    setMsgInfo,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const approve = async (rowIds) => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You have not select any member to approve.`),
        'warning',
      );
      return;
    }

    try {
      setLoading(true);
      await dispatch(approveSpaceMember(rowIds));
      setMsgInfo({
        success: true,
        msg: [`${tr('Member successfully approved')}`],
      });
    } catch(e){
      setMsgInfo({
        success: false,
        msg: [`${tr('Member could not be approved')}`],
      });
    }
    finally {
      setLoading(false);
    }
  };

  const disapprove = async (rowIds) => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You have not select any member to decline.`),
        'warning',
      );
      return;
    }
    try {
      setLoading(true);
      const { status, ...rest } = await dispatch(disapproveSpaceMember(rowIds));
      setApproveMember({ status: status, ...rest });
      setMsgInfo({
        success: true,
        msg: [`${tr('Member successfully disapproved')}`],
      });
    } catch (err) {
        setMsgInfo({
          success: false,
          msg: [`${tr('Member could not be disapproved')}`],
        });
    }
    finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          <StyledButton
            onClick={() => approve(rowIds)}
            className="option-btn"
            reactIcon={<HandThumbsUp color="#0E8436" size={16} className="mr-2" />}
            name="Approve"
          />
          <StyledButton
            onClick={() => disapprove(rowIds)}
            className="option-btn"
            name="Decline"
            reactIcon={<HandThumbsDown color="#F00" size={16} className="mr-3" />}
          />
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
        </ButtonWrapper>
      </HeaderWrapper>
      <StyledContentTemplate
        {...rest}
        columns={columns}
        hasBulkDelete={hasBulkDelete}
        rowIds={rowIds}
      />
    </React.Fragment>
  );
};

SpaceContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withTranslation,
  withMsgInfo,
)(SpaceContentTemplate);

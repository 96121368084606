import axios from 'axios';
import { logout } from 'actions';
import { store } from './';
import API from 'actions/api';

const axiosInstance = axios.create();
const lang = localStorage.getItem('i18nextLng') || 'en';
axiosInstance.defaults.headers.common['Accept-Language'] = lang;

const ONE_MINUTE_IN_MILLISECONDS = 60000;
const MINUTES_TO_WAIT = 30;

// Add a function to set the timestamp in local storage
const setApiCallTimestamp = () => {
  localStorage.setItem('lastApiCallTimestamp', Date.now());
};

// Get the timestamp of the last API call from local storage
const getLastApiCallTimestamp = () => {
  return localStorage.getItem('lastApiCallTimestamp');
};

const refreshTokens = async () => {
  // Make an API request to refresh the tokens, and update local storage with the new tokens
  try {
    const response = await axiosInstance.post(`${API}/token/refresh/`, {
      refresh: localStorage.getItem('refreshToken'),
    });
    const { access } = response.data;

    localStorage.setItem('token', access);
  } catch (error) {
    // if you cannot refreshToken, log the user out
    store.dispatch(logout());
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      config.headers['Authorization'] = `Token ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    setApiCallTimestamp();
    return response;
  },
  async (error) => {
    // Check if the error response indicates an expired access token (401 status code)
    if (error.response.status === 401) {
      const lastApiCallTimestamp = getLastApiCallTimestamp();

      if (lastApiCallTimestamp) {
        const currentTime = Date.now();
        const timeElapsedSinceLastApiCall = currentTime - lastApiCallTimestamp;

        // Check if more than a minute has passed since the last API call
        if (
          timeElapsedSinceLastApiCall <
          ONE_MINUTE_IN_MILLISECONDS * MINUTES_TO_WAIT
        ) {
          // Perform a token refresh and handle the new tokens
          await refreshTokens();

          // Retry the original request with the new access token
          const accessToken = localStorage.getItem('token');
          error.config.headers['Authorization'] = `Token ${accessToken}`;
          return axiosInstance(error.config);
        }
      }

      // If not enough time has passed since the last API call, log the user out
      store.dispatch(logout());

      // Redirect to the login page with a message
      const encodedPathname = encodeURIComponent(window.location.pathname);
      window.location.reload();
      window.location.href = `/login?sessionExpired=true&path=${encodedPathname}`;
      return;
    } else {
      setApiCallTimestamp();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;


import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router-dom';
import {
  withEducation,
  withOrgFunctionAssignment,
  withPersonalInfo,
  withCustomRouter,
  withSchoolType,
  withSchoolGraduation,
  withSchoolSubject,
  withStudyField,
  withLanguage,
  withLanguageLevel,
  withProfession,
  withIndustryType,
  withOrganisation,
  withTranslation,
  withUserPreferences,
  withMsgInfo,
} from 'components/hoc';
import {
  Form as BaseForm,
  Select,
  InputDatePicker,
  ButtonLink,
  FlashMessage,
} from 'components/common/FormItems';
import { MultiColumns } from '../styles';
import { degreeOptions, lastClassOptions, getViewName } from '../utils';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`

  span {
    text-transform: uppercase;
  }
`;
const required = { validation: ['required'] };

export class Form extends React.Component {
  state = {
    valid_to: '9999-12-31',
    valid_from: '1990-03-10',
    error: null,
    submittingForm: false,
    loadingData: false,
  };

  async componentDidMount() {
    const { routeParams, educationCategory, getEducationById } = this.props;

    const { id } = routeParams;
    const { category } = educationCategory[this.pathName] || {};
    let data = {};

    if (id) {
      this.setState({ loadingData: true });
      try {
        data = await getEducationById(id);
      } finally {
        this.setState({ loadingData: false });
      }
    }
    this.setState({ ...data, category });
  }

  onChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  onSubmit = async () => {
    if (this.formLoading) return;

    const { 
      addEducation, 
      updateEducation, 
      // gotoDetailPath, 
      history, 
      basePath,
      setMsgInfo,
      tr,
    } = this.props;

    const { id, ...formData } = this.state;
    let data = {};

    try {
      this.setState({ submittingForm: true, error: null });
      
      if (id) {
        data = await updateEducation(id, formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Education updated successfully')}`],
        });
      } else {
        data = await addEducation(formData);
        setMsgInfo({
          success: true,
          msg: [`${tr('Education added successfully')}`],
        });
      }
      
      history.push(basePath);
      window.scrollTo(0, 0);
    } catch (error) {
      this.setState({ error: error.message });
    } finally {
      this.setState({ submittingForm: false });
    }

    // const formAction = id
    //   ? () => updateEducation(id, formData)
    //   : () => addEducation(formData);

    // try {
    //   this.setState({ submittingForm: true, error: null });
    //   data = await formAction();
    //   history.push(basePath);
    //   window.scrollTo(0, 0);
    //   // gotoDetailPath(data.id)
    // } finally {
    //   this.setState({ submittingForm: false });
    // }
  };

  get formLoading() {
    const { submittingForm, loadingData } = this.state;
    return submittingForm || loadingData;
  }

  useValidityPeriod = true;

  required = required;

  educationFormSchema = {};

  degreeOptions = degreeOptions;

  lastClassOptions = lastClassOptions;

  pathName = this.props.educationPathName;

  get studyFieldOptions() {
    return this.props.studyFieldOptions;
  }

  get schoolSubjectOptions() {
    return this.props.schoolSubjectOptions;
  }

  get languageOptions() {
    return this.props.languageOptions;
  }

  get activeLanguageOptions() {
    return this.props.activeLanguageOptions;
  }

  get userPreferencesOptions() {
    return this.props.userPreferences;
  }

  get languageLevelOptions() {
    return this.props.languageLevelOptions;
  }

  get professionOptions() {
    return this.props.professionOptions;
  }

  get industryTypeOptions() {
    return this.props.industryTypeOptions;
  }

  get schoolType() {
    const { school_type } = this.props.educationCategory[this.pathName] || {};
    return school_type;
  }

  get organisationOptions() {
    return this.props.getOrgFunctionAssignmentBySchoolType(this.schoolType);
  }

  get schoolTypeOptions() {
    return this.props.getSchoolTypeByType(this.schoolType);
  }

  get schoolGraduationOptions() {
    return this.props.getSchoolGraduationByType(this.schoolType);
  }

  get favoriteOrgOptions() {
    return this.props.orgOptions;
  }

  getSchoolTypeValue = (org) => {
    if (!org) return null;
    const orgDetail =
      this.organisationOptions.find(({ id }) => {
        return String(id) === String(org);
      }) || {};
    return orgDetail['school_type_detail.id'];
  };

  renderUpperInputs = () => null;

  renderLowerInputs = () => null;

  baseEducationFormSchema = {
    ...(this.useValidityPeriod && {
      valid_from: {
        validation: [...required.validation, 'before:valid_to'],
      },
      valid_to: {
        validation: [...required.validation, 'after:valid_from'],
      },
    }),
    person: required,
  };

  render() {
    const { state, props } = this;
    const {
      // getSchoolTypeByType,
      // getSchoolGraduationByType,
      personalInfoOptions,
      // educationCategory,
      basePath,
      tr,
    } = props;

    const formName = tr(getViewName(this.pathName));

    const formSchema = {
      ...this.baseEducationFormSchema,
      ...this.educationFormSchema,
    };

    return (
      <Wrapper className="form-wrapper">
          <div className="flex">
            <StyledButtonLink
              to={basePath}
              className="option-btn"
              icon="angle double left"
              name="All Entries"
            />
          </div>
          <div className="row">
            <div
              className="col-7 ml-10 mt-30 container"
              style={{
                position: 'relative',
                background: 'var(--admincat-color-grey-1)',
              }}
            >
            <br />
            <BaseForm
              onSubmit={this.onSubmit}
              formData={state}
              validationSchema={formSchema}
              title={formName}
              isLoading={state.submittingForm}
              loadingData={state.loadingData}
            >
              <Select
                onChange={this.onChange}
                value={state.person}
                name="person"
                label="Person"
                optionDefaultValue="Choose Person"
                options={personalInfoOptions}
                showRequiredAsterisk
              />
              {this.renderUpperInputs(state)}

              {this.useValidityPeriod && (
                <MultiColumns col={2}>
                  <InputDatePicker
                    onChange={this.onChange}
                    value={state.valid_from}
                    name="valid_from"
                    label="Valid From"
                    showRequiredAsterisk
                  />
                  <InputDatePicker
                    onChange={this.onChange}
                    value={state.valid_to}
                    name="valid_to"
                    label="Valid To"
                    showRequiredAsterisk
                  />
                </MultiColumns>
              )}
              {this.renderLowerInputs(state)}
              {state.error && (
                <FlashMessage hasError={state.error}>{state.error}</FlashMessage>
              )}
            </BaseForm>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export const withFormProps = compose(
  withEducation,
  withOrgFunctionAssignment,
  withPersonalInfo,
  withCustomRouter,
  withSchoolType,
  withSchoolGraduation,
  withSchoolSubject,
  withStudyField,
  withLanguage,
  withLanguageLevel,
  withProfession,
  withIndustryType,
  withOrganisation,
  withTranslation,
  withUserPreferences,
  withRouter,
  withMsgInfo,
);

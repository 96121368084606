import React, { useState, useMemo } from 'react';

export const useTableSort = (items, options = {}) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  });

  const sortData = (a, b, key) => {
    // Get values using the provided getValueByKey function or fallback to direct property access
    let valueA = options.getValueByKey ? options.getValueByKey(a, key) : a[key];
    let valueB = options.getValueByKey ? options.getValueByKey(b, key) : b[key];

    // Special handling for date column
    if (key === 'date') {
      const dateA = new Date(a.created_at || '');
      const dateB = new Date(b.created_at || '');
      return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
    }

    // Handle null/undefined values
    if (valueA === null || valueA === undefined) valueA = '';
    if (valueB === null || valueB === undefined) valueB = '';

    // Convert to lowercase for string comparison
    if (typeof valueA === 'string') valueA = valueA.toLowerCase();
    if (typeof valueB === 'string') valueB = valueB.toLowerCase();

    // Sort logic
    if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedItems = useMemo(() => {
    if (!sortConfig.key || !items) return items;

    console.log('Sorting by:', sortConfig.key, 'Direction:', sortConfig.direction);
    return [...items].sort((a, b) => sortData(a, b, sortConfig.key));
  }, [items, sortConfig, options.getValueByKey]);

  const SortIndicator = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) return null;
    return (
      <span style={{ marginLeft: '4px' }}>
        {sortConfig.direction === 'asc' ? '↑' : '↓'}
      </span>
    );
  };

  return {
    sortedItems,
    sortConfig,
    handleSort,
    SortIndicator,
  };
};

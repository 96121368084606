import React from 'react';
import { compose } from 'lodash/fp';
import { Button, Popup, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  withTranslation,
  withUserDefaultSpace,
  withCustomRouter,
  withUserPreferences,
} from '../../hoc';
import BaseDropdown from '../BaseDropdown';
import { CheckBox } from '../FormItems';
import { isEmptyValue } from '../../utils';
import { QuestionCircle } from 'react-bootstrap-icons';

const DropdownMenu = BaseDropdown.DropdownMenu;

const CheckBoxColumn = styled.td`
  &&&:first-child {
    padding-left: 3px;
  }
`;

const StyledCheckbox = styled(CheckBox)`
  &&& {
    position: relative;
    width: 20%;
    height: 100%;
    margin-right: 20px;
    margin-top: 20px;

    label.form-label,
    label.checkbox {
      position: absolute;
      margin-right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    input[type='checkbox']:checked + label.checkbox::before {
      top: -10.2px;
      margin-right: 20px;
    }
  }
  &&&:last-child {
    margin-right: 3px;
  }
`;

const StyledAnchor = styled.a`
  &&& {
    border-bottom: ${({ header }) => (header ? '1px solid #aaa' : '')};
    text-align: ${({ header }) => (header ? 'center' : '')};
    user-select: none;
    display: block;
    color: ${({ disabled }) => (disabled ? '#aaa' : '')};

    &:hover {
      color: ${({ disabled }) => (disabled ? '#aaa' : 'blue')};
    }
  }
`;

const StyledDropdownColumn = styled.td`
  width: 50px;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  top: 14px;
  right: 10px;
  min-width: 15rem;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  max-width: 400px;
  ${
    '' /* overflow: hidden;
  text-overflow: ellipsis; */
  }
`;

const Ellipsis = styled(({ className, ellipsisType }) => (
  <span className={className}>
    <Icon
      name={`ellipsis ${ellipsisType == 'vertical' ? 'vertical' : 'horizontal'}`}
    />
  </span>
))`
  cursor: pointer;
`;

export class Dropdown extends BaseDropdown {
  render() {
    const {
      tr,
      options,
      data,
      userDefaultSpace,
      type,
      headerTitle,
      loading = false,
    } = this.props;
    let ellipsisType = type === 'vertical' ? 'vertical' : 'horizontal';
    const canChange = userDefaultSpace.space === data.space_name;

    return (
      <StyledDropdownColumn
        ref={(e) => (this.dropdownTriggerRef = e)}
        onClick={this.changeDropdownState}
        className="position-rel clickable"
      >
        <>
          <Ellipsis ellipsisType={ellipsisType} />
          {loading && <i className="fa fa-spinner fa-spin"></i>}
          <StyledDropdownMenu ref={(e) => (this.dropdownRef = e)}>
            {(headerTitle != null || headerTitle != false) && (
              <DropdownMenu.Item key={headerTitle}>
                <StyledAnchor disabled={true} onClick={() => {}} header={true}>
                  {tr(headerTitle)}
                </StyledAnchor>
              </DropdownMenu.Item>
            )}
            {options.map(
              ({
                name,
                onClick = () => {},
                isLink = true,
                type = 'button',
                show = true,
                loadingIcon = null, // New property for custom icons
              }) => {
                if (type == 'header') {
                  return (
                    <DropdownMenu.Item key={name}>
                      <StyledAnchor disabled={true} onClick={() => {}} header={true}>
                        {tr(name)}
                      </StyledAnchor>
                    </DropdownMenu.Item>
                  );
                } else {
                  if (show) {
                    if (!isLink) {
                      return (
                        <DropdownMenu.Item key={name}>
                          <StyledAnchor onClick={() => onClick()}>
                            {loadingIcon} {tr(name)}
                          </StyledAnchor>
                        </DropdownMenu.Item>
                      );
                    } else {
                      return (
                        <DropdownMenu.Item key={name}>
                          <StyledAnchor
                            disabled={!canChange}
                            onClick={() => canChange && onClick(data)}
                          >
                            {loadingIcon} {tr(name)}
                          </StyledAnchor>
                        </DropdownMenu.Item>
                      );
                    }
                  }
                }
              },
            )}
          </StyledDropdownMenu>
        </>
      </StyledDropdownColumn>
    );
  }
}

Dropdown.defaultProps = {
  options: [],
  data: { canChange: true },
};

const TableBody = (props) => {
  const {
    hasBulkDelete,
    columns,
    data,
    options,
    tr,
    userDefaultSpace,
    onRowChecked,
    isRowChecked,
    onRowSelected,
    gotoDetailPath,
    orgCondition = false,
    showDetailOnRowClick,
    executeOnRowClick,
    checkNumeric,
    handleOrgAction = () => {},
    hasQuestionMark = false,
    useContextMenu,
  } = props;

  return (
    <React.Fragment>
      {data.map((_data, index) => {
        const rowMisc = _data.__misc || {};
        const rowOptions = _data.__options || null;
        const newId = _data._id || _data.id;
        return (
          <tr {...rowMisc} onClick={() => onRowSelected(_data)} key={newId || index}>
            {hasBulkDelete && (
              <CheckBoxColumn>
                <div
                  style={{
                    display: 'flex',
                    direction: 'row',
                    justifyContent: 'space-around',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {hasQuestionMark && hasQuestionMark(_data) != '' ? (
                    <Popup
                      content={hasQuestionMark(_data)}
                      trigger={
                        <div>
                          <QuestionCircle
                            color="orange"
                            size={16}
                            className="mt-3"
                          />
                        </div>
                      }
                    />
                  ) : (
                    <div>
                      <QuestionCircle
                        color="transparent"
                        size={16}
                        className="mt-3"
                      />
                    </div>
                  )}
                  <StyledCheckbox
                    name={_data.id}
                    onChange={() => onRowChecked(_data.id)}
                    value={isRowChecked(_data.id)}
                  />
                </div>
              </CheckBoxColumn>
            )}

            {columns
              .filter(({ id }) => !!id)
              .map(({ id }, index) => {
                const value = _data[id];
                const rowData = isEmptyValue(value) ? '-' : value;

                // check if the value is numberic
                const isNumeric = checkNumeric(value);

                if (showDetailOnRowClick) {
                  return (
                    <td
                      key={index}
                      onClick={() => {
                        executeOnRowClick(_data);

                        if (orgCondition) {
                          handleOrgAction(_data?.id, _data);
                        } else {
                          gotoDetailPath(_data?.id, _data);
                        }
                      }}
                    >
                      <Text
                        className={`text-muted cell-data ${
                          isNumeric ? 'text-right' : ''
                        }`}
                      >
                        {rowData}
                      </Text>
                    </td>
                  );
                } else {
                  return (
                    <td key={index}>
                      <Text
                        className={`text-muted cell-data ${
                          isNumeric ? 'text-right' : ''
                        }`}
                      >
                        {rowData}
                      </Text>
                    </td>
                  );
                }
              })}
            {/* {useContextMenu && (
              <Dropdown
                options={rowOptions || options}
                data={_data}
                tr={tr}
                userDefaultSpace={userDefaultSpace}
              />
            )} */}
          </tr>
        );
      })}
    </React.Fragment>
  );
};

TableBody.defaultProps = {
  hasBulkDelete: false,
  useContextMenu: true,
  onRowChecked: () => {},
  onRowSelected: () => {},
  isRowChecked: () => {},
  showDetailOnRowClick: false,
  executeOnRowClick: () => {},
};

export default compose(
  withTranslation,
  withUserDefaultSpace,
  withCustomRouter,
  withUserPreferences,
)(TableBody);

/* eslint-disable */
import React from 'react';
import compose from 'lodash/fp/compose';
import { useSelector } from 'react-redux';
import {
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
} from 'components/hoc';
import TransactionContentTemplate from '../../common/TransactionContentTemplate';
// import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import { useListTransaction } from '../hooks/useListTransaction';
import { getFullName } from 'components/utils';
import { getTransactionItem, getDCIndicator } from '../utils';
import LottieLoader from 'components/common/LottieLoader';

const Income = (props) => {
  const loading = useSelector((state) => state.transaction.loading);
  const { data, refreshTransactions } = useListTransaction('income');
  const { fn, fd, userDefaultSpace } = props;


  const mapFields = () => {
    return data?.map((income) => {
      const transactionItem = getTransactionItem(income.transaction_item[0], fn);
      return {
        ...income,
        ...transactionItem,
        space_name: income.space,
        person: getFullName(income.transaction_item[1].person_details),
        description: income.description,
        date: fd(income.date),
        partner: income?.partner_detail?.name,
        amount: fn(income.transaction_item[1].amount),
        amount_sc: fn(income.transaction_item[1].amount_sc),
        sp_currency: userDefaultSpace.space_detail.currency,
        currency: transactionItem.currency,
        contract: income.contract_details?.description,
        dc_indicator: getDCIndicator(transactionItem.dc_indicator),
        org_indicator: income.org_indicator,
        account_category: props.trObj(
          income.transaction_item[1].account_category_details,
        ),
        financial_account: props.trObj(
          income.transaction_item[1].financial_account_details,
        ),
        offset_account_category: props.trObj(
          income.transaction_item[0].account_category_details,
        ),
        offset_account: props.trObj(
          income.transaction_item[0].financial_account_details,
        ),
        reversal: income.reversal ? '\u2713' : '\u2717',
        reversed: income.reversed ? '\u2713' : '\u2717',
      };
    });
  };


  const { gotoCreatePath } = props;

  const tableProps = {
    frontendTableName: 'income',
    backendTableName: 'transaction',
  };

  if (loading)
    return (
      <>
        <LottieLoader />
      </>
    );

  return (
    <TransactionContentTemplate
      {...tableProps}
      columns={[]}
      data={mapFields() || []}
      toggleForm={gotoCreatePath}
      hasBulkDelete={false}
      refreshTransactions={refreshTransactions}
    />
  );
};

export default compose(
  withTranslation,
  withUserPreferences,
  withCustomRouter,
  withUserDefaultSpace,
)(Income);

import { BaseActionClass } from "../common/BaseActionClass"
import { AUTO_MOBILE_INFO } from '../types'

const path = "/dashboard/auto_mobile";
const name = "Auto Mobile";

class AutoMobileInfo extends BaseActionClass {

  constructor() { super(path, AUTO_MOBILE_INFO, name) }

  addAutoMobile = this._add;

  updateAutoMobile = this._update;

  deleteAutoMobileById = this._deleteById;

  getAutoMobile = this._getAll;

  getAutoMobileById = this._getById;

  deleteBulkAutoMobile = this._deleteBulk;
}

export const { 
  addAutoMobile, 
  updateAutoMobile, 
  deleteAutoMobileById,
  getAutoMobileById, 
  getAutoMobile,
  deleteBulkAutoMobile,
} = new AutoMobileInfo()


import React from 'react'
import compose from 'lodash/fp/compose';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { documentSlice } from 'rootStateHandler/selectors';
import { listDocumentsThunk } from 'components/Dashboard/Documents/documentStates/documentThunk';
import styled from 'styled-components';
import {
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withOrganisation,
  withMsgInfo,
  withAutoMobileInfo,
  withAutoRegistrationInfo,
} from 'components/hoc';
import {
  Select,
  Input,
  Form,
  InputDatePicker,
  ButtonLink,
} from 'components/common/FormItems';
import { useFavoriteOrganisation } from 'hooks/useFavoriteOrganisation';


const Wrapper = styled.div``;

const DualInputWrapper = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
> div {
  min-width: 150px;
  flex: 1;
  margin-right: 1rem;

  :last-child {
    margin-right: 0px;
  }
}
`;

const StyledButtonLink = styled(ButtonLink)`
    span {
      text-transform: uppercase;
    }
`;

const AutoRegistrationFormSchema = {
  auto_mobile: {
    validation: 'required',
  },
  person: {
    validation: 'required',
  },
  registration_org: {
    validation: 'required',
  },
  licence_plate: {
    validation: 'required',
  },
};

const AutoRegistrationForm = (props) => {
  const {
    tr,
    basePath,
    routeParams,
    updateAutoRegistration,
    addAutoRegistration,
    getAutoRegistrationById,
    setMsgInfo,
  } = props;

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);

  const { id } = routeParams;
  const history = useHistory();
  const dispatch = useDispatch();

  const favoriteOrganisation = useFavoriteOrganisation();
  const { list_documents } = useSelector(documentSlice);

  const fetchData = async () => {
    if (id) {
    try {
        setFormLoading(true);
        const data = await getAutoRegistrationById(id);
        setFormData({ ...data });
        setFormLoading(false);
    } catch (e) {
        setFormData({});
        setFormLoading(false);
    }
    }
};

React.useEffect(() => {
    fetchData();
}, []);

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const documemtOptions = list_documents?.map((document) => ({
    id: document?.id,
    long_description_deu: document?.filename,
    long_description_eng: document?.filename,
  }));

  React.useEffect(() => {
    dispatch(listDocumentsThunk());
  }, [dispatch]);


  const onSubmit = async (e) => {
    try {
    let response;
    setFormSubmitting(true);
    if (id) {
        response = await updateAutoRegistration(id, formData);
        dispatch(
        setMsgInfo({
            success: true,
            msg: [`${tr('Auto Registration updated successfully')}`],
        }),
        );
    } else {
        response = await addAutoRegistration(formData);
        dispatch(
        setMsgInfo({
            success: true,
            msg: [`${tr('Auto Registration added successfully')}`],
        }),
        );
    }
    setFormSubmitting(false);

    history.push(basePath);
    window.scrollTo(0, 0);

    if (response?.response?.status >= 400) return;
    return;
    } catch (e) {
    setFormSubmitting(false);
    return;
    }
};

  return (
    <Wrapper className="form-wrapper">
      <div className="flex">
        <StyledButtonLink
          to={basePath}
          className="option-btn"
          icon="angle double left"
          name="All Entries"
        />
      </div>
      <div className="row">
        <div
          className="col-7 ml-10 mt-30 container"
          style={{
            position: 'relative',
            background: 'var(--admincat-color-grey-1)',
          }}
        >
          <br />

          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={AutoRegistrationFormSchema}
            title="Auto Registration"
          >
            <Select
              optionDefaultValue={tr('Select Automobile')}
              label={tr('Auto Mobile')}
              value={formData?.auto_mobile || ''}
              name="auto_mobile"
              onChange={onChange}
              options={props.autoMobileInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <Select
              optionDefaultValue={tr('Select Person')}
              label={tr('Person')}
              value={formData?.person}
              name="person"
              onChange={onChange}
              options={props.personalInfoOptions}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <Select
              optionDefaultValue={tr('Select Registration Organization')}
              label={tr('Registration Organization')}
              value={formData?.registration_org}
              name="registration_org"
              onChange={onChange}
              options={favoriteOrganisation}
              deselectable
              sort={false}
              showRequiredAsterisk
            />

            <DualInputWrapper>
              <InputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Valid from"
                value={formData.valid_from}
                name="valid_from"
                onChange={onChange}
              />
              <InputDatePicker
                className="ml-sm-1 mr-md-1"
                label="Valid to"
                value={formData.valid_to}
                name="valid_to"
                onChange={onChange}
              />
            </DualInputWrapper>

            <Input
              className="mr-md-1"
              value={formData.licence_plate || ''}
              onChange={onChange}
              name="licence_plate"
              id="licence_plate"
              placeholder="Enter Licence plate"
              label="Licence plate"
              showRequiredAsterisk
            />

            <div className="row values-row justify-content-between">
              <div className="col-md-12">
                <Select
                  label={tr('Document')}
                  optionDefaultValue={tr('Choose Document')}
                  value={formData.document}
                  name="document"
                  id="document"
                  localeOptions={documemtOptions}
                  onChange={onChange}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Wrapper>
  )
}

export default compose(
  withPersonalInfo,
  withCustomRouter,
  withTranslation,
  withAutoMobileInfo,
  withAutoRegistrationInfo,
  withOrganisation,
  withMsgInfo,
)(AutoRegistrationForm);

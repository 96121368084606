export * from './dashboard/finance/types';
export const AUTH = 'AUTH';
export const USER_LANGUAGE = 'USER_LANGUAGE';
export const MSGINFO = 'MSGINFO';
export const CONTACT_DROPDOWN = 'CONTACT_DROPDOWN';
export const DATE_FORMAT = 'DATE_FORMAT';

export const USER_DEFAULT_SPACE = 'USER_DEFAULT_SPACE';

// Person
export const PERSONAL_INFO = 'PERSONAL_INFO';

export const ORGANISATION_PROPOSAL = 'ORGANISATION_PROPOSAL';
export const ORGANISATION_FUNCTION_PROPOSAL = 'ORGANISATION_FUNCTION_PROPOSAL';

export const ADD_ORG_FUNCTION_PROPOSAL = 'ADD_ORG_FUNCTION_PROPOSAL';

// export const ORGANISATION_FUNCTION_PROPOSAL = 'ORGANISATION_FUNCTION_PROPOSAL';
// export const ORGANISATION_FUNCTION_PROPOSAL = 'ORGANISATION_FUNCTION_PROPOSAL';

// Contact
export const CONTACT_INFO = 'CONTACT_INFO';

export const ADD_CONTACT_INFO = 'ADD_CONTACT_INFO';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const DELETE_CONTACT_INFO = 'DELETE_CONTACT_INFO';
export const DELETE_BULK_CONTACT_INFO = 'DELETE_BULK_CONTACT_INFO';
export const GET_CONTACT_INFO = 'GET_CONTACT_INFO';
export const GET_ALL_CONTACT_INFO = 'GET_ALL_CONTACT_INFO';
export const EMPTY_CONTACT_INFO = 'EMPTY_CONTACT_INFO';
export const CONTACT_INFO_FORM = 'CONTACT_INFO_FORM';
export const CONTACT_INFO_VIEW = 'CONTACT_INFO_VIEW';

export const ADD_RELATION_INFO = 'ADD_RELATION_INFO';
export const UPDATE_RELATION_INFO = 'UPDATE_RELATION_INFO';
export const DELETE_RELATION_INFO = 'DELETE_RELATION_INFO';
export const DELETE_BULK_RELATION_INFO = 'DELETE_BULK_RELATION_INFO';
export const GET_RELATION_INFO = 'GET_RELATION_INFO';
export const GET_ALL_RELATION_INFO = 'GET_ALL_RELATION_INFO';
export const EMPTY_RELATION_INFO = 'EMPTY_RELATION_INFO';
export const RELATION_INFO_VIEW = 'RELATION_INFO_VIEW';
export const RELATION_INFO_FORM = 'RELATION_INFO_FORM';

// Residence
export const RESIDENCE_INFO = 'RESIDENCE_INFO';

export const EMPTY_RESIDENCE_INFO = 'EMPTY_RESIDENCE_INFO';

// Address
export const ADDRESS_INFO = 'ADDRESS_INFO';

// CitizenShip
export const CITIZENSHIP_INFO = 'CITIZENSHIP_INFO';

export const ADD_FORM = 'ADD_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_BULK_FORM = 'DELETE_BULK_FORM';
export const GET_FORM = 'GET_FORM';
export const GET_ALL_FORM = 'GET_ALL_FORM';
export const EMPTY_FORM = 'EMPTY_FORM';

export const ADD_FIELDS = 'ADD_FIELDS';
export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const UPDATE_FIELD_OPTION = 'UPDATE_FIELD_OPTION';
export const DELETE_FIELDS = 'DELETE_FIELDS';
export const DELETE_BULK_FIELDS = 'DELETE_BULK_FIELDS';
export const GET_FIELDS = 'GET_FIELDS';
export const GET_ALL_FIELDS = 'GET_ALL_FIELDS';
export const GET_FORM_FIELDS = 'GET_FORM_FIELDS';
export const EMPTY_FIELDS = 'EMPTY_FIELDS';
export const FIELD_DETAIL = 'FIELD_DETAIL';
export const FIELD_FORM = 'FIELD_FORM';

export const USER_PERSONAL_WORKSPACE = 'USER_PERSONAL_WORKSPACE';
export const USER_ORGANISATION_WORKSPACE = 'USER_ORGANISATION_WORKSPACE';

export const GET_ORGANISATION = 'GET_ORGANISATION';
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const ADD_ORGANISATION_PROPOSAL = 'ADD_ORGANISATION_PROPOSAL';
export const ORGANISATION_FORM = 'ORGANISATION_FORM';
export const SEARCH_ORGANISATION = 'SEARCH_ORGANISATION';
export const FAVORITE_ORGANISATION = 'FAVORITE_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const DELETE_FAVORITE_ORGANISATION = 'DELETE_FAVORITE_ORGANISATION';
export const UPDATE_FAVORITE_ORGANISATION = 'UPDATE_FAVORITE_ORGANISATION';
export const GET_SINGLE_ORGANISATION = 'GET_SINGLE_ORGANISATION';
export const GET_SINGLE_FAVORITE_ORGANISATION = 'GET_SINGLE_FAVORITE_ORGANISATION';

export const GET_USER_SCHOOLS = 'GET_USER_SCHOOLS';

export const SPACE_MEMBERS = 'SPACE_MEMBERS';
export const UPDATE_SPACE_MEMBER = 'UPDATE_SPACE_MEMBER';
export const UPDATE_SPACE_MEMBERS = 'UPDATE_SPACE_MEMBERS';

export const COUNTRY = 'COUNTRY';
export const STATE = 'STATE';
export const CITY = 'CITY';
export const TITLE = 'TITLE';
export const SEX = 'SEX';
export const ETHNIC_GROUP = 'ETHNIC_GROUP';
export const RELIGION = 'RELIGION';
export const MARITAL_STATUS = 'MARITAL_STATUS';
export const HEIGHT_UNIT = 'HEIGHT_UNIT';
export const RELATION_PART = 'RELATION_PART';
export const ADDRESS_TYPE = 'ADDRESS_TYPE';
export const STREET_DIRECTION = 'STREET_DIRECTION';
export const CONTACT_TYPE = 'CONTACT_TYPE';
export const EYE_COLOR = 'EYE_COLOR';
export const FORM_TYPE = 'FORM_TYPE';
export const FORM_STATUS = 'FORM_STATUS';
export const LANGUAGE = 'LANGUAGE';
export const COMMENT_CATEGORY = 'COMMENT_CATEGORY';
export const WISH_TYPE = 'WISH_TYPE';
export const NATIONALITY = 'NATIONALITY';
export const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
export const LEGAL_FORM = 'LEGAL_FORM';
export const FIELD_CATALOG = 'FIELD_CATALOG';
export const CURRENCY = 'CURRENCY';
export const INDUSTRY_TYPE = 'INDUSTRY_TYPE';
export const DATA_AREA = 'DATA_AREA';
export const PERSON_NAMES_REASON = 'PERSON_NAMES_REASON';
export const ORG_MOTIVATION = 'ORG_MOTIVATION';
export const ORG_FUNCTION = 'ORG_FUNCTION';
export const PUBLIC_RESP_AREA = 'PUBLIC_RESP_AREA';
export const ANIMAL = 'ANIMAL';

export const AUTO_BRAND = 'AUTO_BRAND';
export const ENERGY_SOURCE = 'ENERGY_SOURCE';
export const MEANS_OF_TRANSPORT = 'MEANS_OF_TRANSPORT';
export const MOTOR_COLORS = 'MOTOR_COLORS';
export const YEARS = 'YEARS';

export const DEU_LOCALE = 'DEU_LOCALE';
export const ENG_LOCALE = 'ENG_LOCALE';

export const LOADING_INACTIVE = 'LOADING_INACTIVE';
export const LOADING_ACTIVE = 'LOADING_ACTIVE';

export const SET_JOIN_BUILDER_STATE = 'SET_JOIN_BUILDER_STATE';

export const UPDATE_JOIN_BUILDER_SUCCESS = 'UPDATE_JOIN_BUILDER_SUCCESS';
export const UPDATE_JOIN_BUILDER_LOADING = 'UPDATE_JOIN_BUILDER_LOADING';
export const UPDATE_JOIN_BUILDER_FAILURE = 'UPDATE_JOIN_BUILDER_FAILURE';

export const ADD_JOIN_BUILDER_SUCCESS = 'ADD_JOIN_BUILDER_SUCCESS';
export const ADD_JOIN_BUILDER_LOADING = 'ADD_JOIN_BUILDER_LOADING';
export const ADD_JOIN_BUILDER_FAILURE = 'ADD_JOIN_BUILDER_FAILURE';

export const DELETE_JOIN_BUILDER_SUCCESS = 'DELETE_JOIN_BUILDER_SUCCESS';
export const DELETE_JOIN_BUILDER_LOADING = 'DELETE_JOIN_BUILDER_LOADING';
export const DELETE_JOIN_BUILDER_FAILURE = 'DELETE_JOIN_BUILDER_FAILURE';

export const GET_ALL_JOIN_BUILDER_SUCCESS = 'GET_ALL_JOIN_BUILDER_SUCCESS';
export const GET_ALL_JOIN_BUILDER_LOADING = 'GET_ALL_JOIN_BUILDER_LOADING';
export const GET_ALL_JOIN_BUILDER_FAILURE = 'GET_ALL_JOIN_BUILDER_FAILURE';

export const GET_JOIN_BUILDER_BY_ID_LOADING = 'GET_JOIN_BUILDER_BY_ID_LOADING';
export const GET_JOIN_BUILDER_BY_ID_SUCCESS = 'GET_JOIN_BUILDER_BY_ID_SUCCESS';
export const GET_JOIN_BUILDER_BY_ID_FAILURE = 'GET_JOIN_BUILDER_BY_ID_FAILURE';

export const ALL_WORKSPACE = 'ALL_WORKSPACE';
export const ALL_PERSONAL_WORKSPACE = 'ALL_PERSONAL_WORKSPACE';
export const ALL_ORGANISATION_WORKSPACE = 'ALL_ORGANISATION_WORKSPACE';

export const FORM_FILTER_SUCCESS = 'FORM_FILTER_SUCCESS';
export const FORM_FILTER_LOADING = 'FORM_FILTER_LOADING';
export const FORM_FILTER_FAILURE = 'FORM_FILTER_FAILURE';

export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const GET_PROFILES_LOADING = 'GET_PROFILES_LOADING';
export const GET_PROFILES_FAILURE = 'GET_PROFILES_FAILURE';

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_LOADING = 'ADD_COMMENT_LOADING';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const COMMENT_POPUP = 'COMMENT_POPUP';

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_INFO = 'GET_DOCUMENT_INFO';
export const DOCUMENT_FULL_TEXT_SEARCH = 'DOCUMENT_FULL_TEXT_SEARCH';
export const FILTER_CRITERIA = 'FILTER_CRITERIA';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT_BY_ID = 'DELETE_DOCUMENT_BY_ID';

export const DOCUMENT_FORM = 'DOCUMENT_FORM';
export const DOCUMENT_VIEW = 'DOCUMENT_VIEW';

export const ADD_CRITERIA_SET = 'ADD_CRITERIA_SET';
export const UPDATE_CRITERIA_SET = 'UPDATE_CRITERIA_SET';
export const CRITERIA_SET_FORM = 'CRITERIA_SET_FORM';
export const GET_CRITERIA_SET = 'GET_CRITERIA_SET';
export const DELETE_CRITERIA_SET_BY_ID = 'DELETE_CRITERIA_SET_BY_ID';

export const CRITERIA_SET_VALUE = 'CRITERIA_SET_VALUE';
export const CRITERIA_SET_VALUE_FORM = 'CRITERIA_SET_VALUE_FORM';
export const CRITERIA_SET_VALUE_LOADING = 'CRITERIA_SET_VALUE_LOADING';

export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';

export const GET_DATE_FORMAT = 'GET_DATE_FORMAT';
export const NUMBER_FORMAT = 'NUMBER_FORMAT';
export const USER_PREFERENCES_LOADER = 'USER_PREFERENCES_LOADER';

export const GET_REGISTER_INSTITUTE = 'GET_REGISTER_INSTITUTE';

export const GET_MAILS = 'GET_MAILS';
export const GET_MAIL_SERVER_INBOX = 'GET_MAIL_SERVER_INBOX';
export const ADD_MAIL = 'ADD_MAIL';
export const DELETE_MAIL = 'DELETE_MAIL';
export const DELETE_BULK_MAIL = 'DELETE_BULK_MAIL';
export const UPDATE_BULK_MAIL = 'UPDATE_BULK_MAIL';
export const DELETE_MAIL_PARTIALLY = 'DELETE_MAIL_PARTIALLY';
export const DELETE_BULK_MAIL_PARTIALLY = 'DELETE_BULK_MAIL_PARTIALLY';

export const EXPENSE_CATEGORY = 'EXPENSE_CATEGORY';
export const EXPENSE_ITEM_REFERENCE = 'EXPENSE_ITEM_REFERENCE';
export const MEASUREMENT_UNIT = 'MEASUREMENT_UNIT';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const GET_CONTRACT = 'GET_CONTRACT';

export const DELETE_CONTRACTS = 'DELETE_CONTRACTS';
export const GET_SINGLE_CONTRACT = 'GET_SINGLE_CONTRACT';
export const SET_SINGLE_CONTRACT = 'SET_SINGLE_CONTRACT';
export const GET_ITEM_REFERENCE_ID = 'GET_ITEM_REFERENCE_ID';

export const ADD_ATTACHMENT_RULE = 'ADD_ATTACHMENT_RULE';
export const GET_ATTACHMENT_RULE = 'GET_ATTACHMENT_RULE';
export const UPDATE_ATTACHMENT_RULE = 'UPDATE_ATTACHMENT_RULE';
export const DELETE_ATTACHMENT_RULE_BY_ID = 'DELETE_ATTACHMENT_RULE_BY_ID';
export const DELETE_BULK_ATTACHMENT_RULE = 'DELETE_BULK_ATTACHMENT_RULE';

export const ATTACHMENT_RULE_FORM = 'ATTACHMENT_RULE_FORM';
export const ATTACHMENT_RULE_VIEW = 'ATTACHMENT_RULE_VIEW';

export const ADD_TIME_DEPENDENT_NAME = 'ADD_TIME_DEPENDENT_NAME';
export const GET_TIME_DEPENDENT_NAME = 'GET_TIME_DEPENDENT_NAME';
export const UPDATE_TIME_DEPENDENT_NAME = 'UPDATE_TIME_DEPENDENT_NAME';
export const DELETE_TIME_DEPENDENT_NAME_BY_ID = 'DELETE_TIME_DEPENDENT_NAME_BY_ID';

// DISEASE
export const DISEASE_HISTORY = 'DISEASE_HISTORY';

export const GET_DISEASE_TYPES = 'GET_DISEASE_TYPES';

export const GET_LANGUAGE_LEVEL = 'GET_LANGUAGE_LEVEL';
export const GET_SCHOOL_GRADUATION = 'GET_SCHOOL_GRADUATION';
export const GET_SCHOOL_SUBJECT = 'GET_SCHOOL_SUBJECT';
export const GET_SCHOOL_TYPE = 'GET_SCHOOL_TYPE';
export const GET_STUDY_FIELD = 'GET_STUDY_FIELD';
export const GET_PROFESSION = 'GET_PROFESSION';

// FINANCE
export const GET_ACCOUNT_CATEGORY = 'ACCOUNT_CATEGORY';
export const GET_FINANCIAL_CATEGORY = 'GET_FINANCIAL_CATEGORY';

// ORG_FUNCTION_ASSIGNMENT
export const ADD_ORG_FUNCTION_ASSIGNMENT = 'ADD_ORG_FUNCTION_ASSIGNMENT';
export const GET_ORG_FUNCTION_ASSIGNMENT = 'GET_ORG_FUNCTION_ASSIGNMENT';
export const UPDATE_ORG_FUNCTION_ASSIGNMENT = 'UPDATE_ORG_FUNCTION_ASSIGNMENT';
export const DELETE_ORG_FUNCTION_ASSIGNMENT_BY_ID =
  'DELETE_ORG_FUNCTION_ASSIGNMENT_BY_ID';
export const GET_ORG_FUNCTION_ASSIGNMENT_REGISTER_INSTITUTE_OPTIONS =
  'GET_ORG_FUNCTION_ASSIGNMENT_REGISTER_INSTITUTE_OPTIONS';
export const GET_ORG_FUNCTION_ASSIGNMENT_SCHOOL_OPTIONS =
  'GET_ORG_FUNCTION_ASSIGNMENT_SCHOOL_OPTIONS';
export const GET_ORG_FUNCTION_ASSIGNMENT_MEDICAL_FACILITY_OPTIONS =
  'GET_ORG_FUNCTION_ASSIGNMENT_MEDICAL_FACILITY_OPTIONS';
export const GET_ORG_FUNCTION_ASSIGNMENT_CREDIT_CARD_OPTIONS =
  'GET_ORG_FUNCTION_ASSIGNMENT_CREDIT_CARD_OPTIONS';
export const GET_ORG_FUNCTION_ASSIGNMENT_BANK_OPTIONS =
  'GET_ORG_FUNCTION_ASSIGNMENT_BANK_OPTIONS';

// VACCINATION
export const GET_VACCINATION_HISTORY = 'GET_VACCINATION_HISTORY';
export const GET_SINGLE_VACCINATION_HISTORY = 'GET_SINGLE_VACCINATION_HISTORY';
export const ADD_VACCINATION_HISTORY = 'ADD_VACCINATION_HISTORY';
export const UPDATE_VACCINATION_HISTORY = 'UPDATE_VACCINATION_HISTORY';
export const DELETE_VACCINATION_HISTORY = 'DELETE_VACCINATION_HISTORY';

// VACCINE_HISTORY_DISEASE
export const GET_VACCINE_HISTORY_DISEASE = 'GET_VACCINE_HISTORY_DISEASE';
export const GET_SINGLE_VACCINE_HISTORY_DISEASE =
  'GET_SINGLE_VACCINE_HISTORY_DISEASE';
export const ADD_VACCINE_HISTORY_DISEASE = 'ADD_VACCINE_HISTORY_DISEASE';
export const UPDATE_VACCINE_HISTORY_DISEASE = 'UPDATE_VACCINE_HISTORY_DISEASE';
export const DELETE_VACCINE_HISTORY_DISEASE = 'DELETE_VACCINE_HISTORY_DISEASE';

// USER_LAYOUT_CHOICE
export const UPDATE_USER_LAYOUT_CHOICE = 'UPDATE_USER_LAYOUT_CHOICE';
export const GET_USER_LAYOUT_CHOICE = 'GET_USER_LAYOUT_CHOICE';

// COMPANION ANIMAL
export const COMPANION_ANIMAL_INFO = 'COMPANION_ANIMAL_INFO';

export const BS_POSITION = 'BS_POSITION';

// PERSON_GROUP
export const PERSON_GROUP = 'PERSON_GROUP';

// PERSON_GROUP_MEMBER
export const PERSON_GROUP_MEMBER = 'PERSON_GROUP_MEMBER';

// EDUCATION
export const EDUCATION = 'EDUCATION';

// AUTHENTICATION
export const AUTHENTICATION = 'AUTHENTICATION';
export const USER = 'USER';

// SPACE
export const SPACE = 'SPACE';

// SPACE_MEMBER
export const SPACE_MEMBER = 'SPACE_MEMBER';

// DOCUMENT CATEGORY

export const GET_DOCUMENT_CATEGORY = 'DOCUMENT_CATEGORY';

// CURRENCT RATE
export const GET_CURRENCY_RATE = 'GET_CURRENCY_RATE';

// CONTRACT ALERT
export const GET_CONTRACT_ALERT = 'GET_CONTRACT_ALERT';

export const CREATE_CONTRACT_ALERT = 'CREATE_CONTRACT_ALERT';

export const UPDATE_CONTRACT_ALERT = 'UPDATE_CONTRACT_ALERT';

//
export const GET_NOTICATION_ALERT = 'GET_NOTICATION_ALERT';

export const CREATE_NOTICATION_ALERT = 'CREATE_NOTICATION_ALERT';

// RESET PASSWORD TYPES
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// CHANGE USERNAME
// export const USERNAME = "USERNAME"
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_USERNAME_SUCCESS = 'CHANGE_USERNAME_SUCCESS';
export const CHANGE_USERNAME_FAILED = 'CHANGE_USERNAME_FAILED';
export const CHANGE_USERNAME_LOADING = 'CHANE_USERNAME_LOADING';

export const MARK_MAIL = 'MARK_MAIL';
export const UNDO_MAIL_DELETE = 'UNDO_MAIL_DELETE';

export const WORK_EXPERIENCE_INFO = 'WORK_EXPERIENCE_INFO';

export const MEMBERSHIP_INFO = 'MEMBERSHIP_INFO';

export const GET_PAYMENTS = 'GET_PAYMENTS';

export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const GET_PAYMENTS_LOADING = 'GET_PAYMENTS_LOADING';

export const ENABLE_USER_SUBSCRIPTION_PLAN = 'AUTH_UPDATE_USER_PLAN';

// Proposal Action Types
export const ENTRY_PROPOSAL_LOADING = 'ENTRY_PROPOSALS_LOADING';
export const ENTRY_PROPOSAL_SUCCESS = 'ENTRY_PROPOSALS_SUCCESS';
export const ENTRY_PROPOSAL_FAILURE = 'ENTRY_PROPOSALS_FAILURE';
export const REJECT_ENTRY_PROPOSAL_SUCCESS = 'REJECT_ENTRY_PROPOSAL_SUCCESS';
export const APPROVE_ENTRY_PROPOSAL_SUCCESS = 'APPROVE_ENTRY_PROPOSAL_SUCCESS';
export const GET_ENTRY_PROPOSAL_SUCCESS = 'GET_ENTRY_PROPOSAL_SUCCESS';
export const GET_ALL_ENTRY_PROPOSAL_SUCCESS = 'GET_ALL_ENTRY_PROPOSAL_SUCCESS';
export const BULK_DELETE_ENTRY_PROPOSAL_SUCCESS =
  'BULK_DELETE_ENTRY_PROPOSAL_SUCCESS';

// Admin Dashboard Action Types
export const ADMIN_DASHBOARD_LOADING = 'ADMIN_DASHBOARD_LOADING';
export const FETCH_ADMIN_DASHBOARD_SUCCESS = 'FETCH_ADMIN_DASHBOARD_SUCCESS';
export const ADMIN_DASHBOARD_FAILURE = 'ADMIN_DASHBOARD_FAILURE';

// Org Proposal Action Types
export const ORG_PROPOSAL_LOADING = 'ORG_PROPOSAL_LOADING';
export const ORG_PROPOSAL_FAILURE = 'ORG_PROPOSAL_FAILURE';
export const GET_ALL_ORG_PROPOSAL_SUCCESS = 'GET_ALL_ORG_PROPOSAL_SUCCESS';
export const GET_ORG_PROPOSAL_SUCCESS = 'GET_ORG_PROPOSAL_SUCCESS';
export const APPROVE_ORG_PROPOSAL_SUCCESS = 'APPROVE_ORG_PROPOSAL_SUCCESS';
export const REJECT_ORG_PROPOSAL_SUCCESS = 'REJECT_ORG_PROPOSAL_SUCCESS';
export const BULK_DELETE_ORG_PROPOSAL_SUCCESS = 'BULK_DELETE_ORG_PROPOSAL_SUCCESS';


export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'GET_TRANSACTIONS_FAILURE';


export const ADD_WAITING_LIST = 'ADD_WAITING_LIST';
export const WAITING_LIST_SUCCESS = 'WAITING_LIST_SUCCESS';
export const WAITING_LIST_LOADING = 'WAITING_LIST_LOADING';
export const WAITING_LIST_FAILURE = 'WAITING_LIST_FAILURE';


// AUTO  MOBILE
export const AUTO_MOBILE_INFO = 'AUTO_MOBILE_INFO';

// AUTO  REGISTRATION
export const AUTO_REGISTRATION_INFO = 'AUTO_REGISTRATION_INFO';
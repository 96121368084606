import React from "react";
import { connect } from "react-redux";
import { getMeansOfTransport } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withMeansOfTransport = (WrapperComponent) => {
  class WithMeansOfTransport extends React.Component {
    componentDidMount() {
      const { getMeansOfTransport } = this.props;
      if (loaded) return;
      loaded = true;
      getMeansOfTransport();
    }
    getMeansOfTransportOptions = () => undefined

    getMeansOfTransportLocaleOptions = () => {
      const { meansOfTransport } = this.props;
      return meansOfTransport.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
            meansOfTransportOptions={this.getMeansOfTransportOptions()} 
            meansOfTransportLocaleOptions={this.getMeansOfTransportLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ meansOfTransport }) => ({ meansOfTransport })
  const WithMeansOfTransportHOC = connect(mapStateToProps, {
    getMeansOfTransport,
  })(WithMeansOfTransport);
  hoistNonReactStatics(WithMeansOfTransportHOC, WrapperComponent)
  return WithMeansOfTransportHOC
}
import React from "react";
import check from "check-types"
import compose from "lodash/fp/compose";
import { get } from "lodash";
import { withDeleteConfirmation } from "../DeleteConfirmation";
import { withTranslation } from "../Translation";

export const withBaseHOCProps = compose(withDeleteConfirmation, withTranslation,)

export class BaseHOC extends React.Component {

  _getOptions = (data=[], target=['id', 'name'], useTranslation=false) => {
    const { trObj } = this.props;
    return data.map((data) => {
      const composedData = target.reduce((accum, curr) => {
        const value = get(data, curr, null)
        accum[curr] = value
        return accum
      }, {})
      const { id, name, ...rest } = composedData
      return { id, name: useTranslation ? trObj(data) : name, ...rest }
    })
  }

  _deleteById = async (deleteById, data) => {
    const { deleteConfirmation, tr } = this.props
    try {
      await deleteConfirmation.init(deleteById, data, tr);
    } catch(e) {}
  }

  _deleteBulk = async (deleteBulk, data) => {
    const { deleteConfirmation, tr } = this.props
    try {
      await deleteConfirmation.initBulk(deleteBulk, data, tr);
    } catch(e) {}
  }
  
  render() { return null; }
}



import * as types from '../actions';
import baseStore from './utils/baseStore';

export const country = baseStore(types.COUNTRY);
export const state = baseStore(types.STATE);
export const city = baseStore(types.CITY);
export const title = baseStore(types.TITLE);
export const sex = baseStore(types.SEX);
export const ethnicGroup = baseStore(types.ETHNIC_GROUP);
export const religion = baseStore(types.RELIGION);
export const maritalStatus = baseStore(types.MARITAL_STATUS);
export const relationPart = baseStore(types.RELATION_PART);
export const heightUnit = baseStore(types.HEIGHT_UNIT);
export const addressType = baseStore(types.ADDRESS_TYPE);
export const contactType = baseStore(types.CONTACT_TYPE);
export const streetDirection = baseStore(types.STREET_DIRECTION);
export const eyeColor = baseStore(types.EYE_COLOR);
export const language = baseStore(types.LANGUAGE);
export const commentCategory = baseStore(types.COMMENT_CATEGORY);
export const wishType = baseStore(types.WISH_TYPE);
export const nationality = baseStore(types.NATIONALITY);
export const formStatus = baseStore(types.FORM_STATUS);
export const formType = baseStore(types.FORM_TYPE);
export const legalForm = baseStore(types.LEGAL_FORM);
export const allWorkspace = baseStore(types.ALL_WORKSPACE);
export const allPersonalWorkspace = baseStore(types.ALL_PERSONAL_WORKSPACE);
export const allOrganisationWorkspace = baseStore(types.ALL_ORGANISATION_WORKSPACE);
export const fieldCatalog = baseStore(types.FIELD_CATALOG);
export const currency = baseStore(types.CURRENCY);
export const industryType = baseStore(types.INDUSTRY_TYPE);
export const dataArea = baseStore(types.DATA_AREA);
export const expenseCategory = baseStore(types.EXPENSE_CATEGORY);
export const expenseItemReference = baseStore(types.EXPENSE_ITEM_REFERENCE);
export const measurementUnits = baseStore(types.MEASUREMENT_UNIT);
export const schoolType = baseStore(types.GET_SCHOOL_TYPE);
export const schoolGraduation = baseStore(types.GET_SCHOOL_GRADUATION);
export const schoolSubject = baseStore(types.GET_SCHOOL_SUBJECT);
export const studyField = baseStore(types.GET_STUDY_FIELD);
export const profession = baseStore(types.GET_PROFESSION);
export const languageLevel = baseStore(types.GET_LANGUAGE_LEVEL);
export const personNamesReason = baseStore(types.PERSON_NAMES_REASON);
export const organisationFunction = baseStore(types.ORG_FUNCTION);
export const organisationMotivation = baseStore(types.ORG_MOTIVATION);
export const publicRespArea = baseStore(types.PUBLIC_RESP_AREA);
export const financialCategory = baseStore(types.GET_FINANCIAL_CATEGORY);
export const accountCategory = baseStore(types.GET_ACCOUNT_CATEGORY);
export const diseaseTypes = baseStore(types.GET_DISEASE_TYPES);
export const numberFormat = baseStore(types.NUMBER_FORMAT);
export const animal = baseStore(types.ANIMAL);
export const bsPosition = baseStore(types.BS_POSITION);
export const documentCategory = baseStore(types.GET_DOCUMENT_CATEGORY);

export const autoBrand = baseStore(types.AUTO_BRAND);
export const energySource = baseStore(types.ENERGY_SOURCE);
export const meansOfTransport = baseStore(types.MEANS_OF_TRANSPORT);
export const motorColors = baseStore(types.MOTOR_COLORS);
export const years = baseStore(types.YEARS);


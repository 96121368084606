import { BaseActionClass } from "../common/BaseActionClass"
import { AUTO_REGISTRATION_INFO } from '../types'

const path = "/dashboard/auto_registration";
const name = "Auto Registration";

class AutoRegistrationInfo extends BaseActionClass {

  constructor() { super(path, AUTO_REGISTRATION_INFO, name) }

  addAutoRegistration = this._add;

  updateAutoRegistration = this._update;

  deleteAutoRegistrationById = this._deleteById;

  getAutoRegistration = this._getAll;

  getAutoRegistrationById = this._getById;

  deleteBulkAutoRegistration = this._deleteBulk;
}

export const { 
  addAutoRegistration, 
  updateAutoRegistration, 
  deleteAutoRegistrationById,
  getAutoRegistrationById, 
  getAutoRegistration,
  deleteBulkAutoRegistration,
} = new AutoRegistrationInfo()


import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as autoRegistrationInfoActions from "actions/dashboard/autoRegistrationInfo";
import { BaseHOC, withBaseHOCProps } from "../common/BaseHOC";

const initialState = {
  loaded: false,
}

export const withAutoRegistrationInfo = (WrappedComponent) => {

  class WithAutoRegistrationInfo extends BaseHOC {

    state = { ...initialState }

    async componentDidMount() {
      const { getAutoRegistration } = this.props
      if (!this.state.loaded)  {
        await getAutoRegistration();
        this.setState({...initialState, loaded: true });
      }
    }
    
    deleteAutoRegistrationInfoById = async (data) => {
      const { deleteAutoRegistrationById } = this.props
      await this._deleteById(deleteAutoRegistrationById, data);
    }

    deleteBulkAutoRegistrationInfo = async (data) => {
      const { deleteBulkAutoRegistration } = this.props
      await this._deleteBulk(deleteBulkAutoRegistration, data);
    }

    render() {
      return (
        <WrappedComponent 
          {...this.props} 
          loaded={this.state.loaded}
          deleteBulkAutoRegistrationInfo={this.deleteBulkAutoRegistrationInfo}
          deleteAutoRegistrationInfoById={this.deleteAutoRegistrationInfoById}
        
        />
      )
    }
  }
  const mapStateToProps = ({ autoRegistrationInfo }) => ({ autoRegistrationInfo })
  const AutoRegistrationInfo = connect(mapStateToProps, autoRegistrationInfoActions)(WithAutoRegistrationInfo);
  hoistNonReactStatics(AutoRegistrationInfo, WrappedComponent)
  return compose(withBaseHOCProps)(AutoRegistrationInfo);
}

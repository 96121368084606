import AutoMobile from './AutoMobile';
import AutoMobileForm from './AutoMobile/Form';
import AutoRegistration from './AutoRegistration';
import AutoRegistrationForm from './AutoRegistration/Form';

const path = '/dashboard/data/mobility';

export default [
  {
    name: "Auto Mobile",
    path: `${path}/auto-mobile`,
    icon: "user",
    component: AutoMobile,
    exact: true,
  },
  {
    name: "Auto Mobile Form",
    path: [
      `${path}/auto-mobile/create`,
      `${path}/auto-mobile/:id`,
      `${path}/auto-mobile/:id/update`,
    ],
    icon: "user",
    component: AutoMobileForm,
    exact: true,
  },

  {
    name: "Auto Registration",
    path: `${path}/auto-registration`,
    icon: "user",
    component: AutoRegistration,
    exact: true,
  },
  {
    name: "Auto Mobile Form",
    path: [
      `${path}/auto-registration/create`,
      `${path}/auto-registration/:id`,
      `${path}/auto-registration/:id/update`,
    ],
    icon: "user",
    component: AutoRegistrationForm,
    exact: true,
  },
];

export const redirects = [
  {
    from: `${path}`,
    to: `${path}/auto-mobile`,
  },
  {
    from: `${path}/*`,
    to: `${path}/auto-mobile`,
  },
];

import axios from '../../axios';
import * as types from "../types";
import { setMsgInfo, createAction } from "../";
import filterError from "../common/filterError";
import API from "../api";

const baseUrl = API + "/dashboard";

const extendFilterError = (errors) => {
  // eslint disable no-mixed-operators
  const err = filterError(errors);
  if (err.length) return err;
  else if(
    err.error && err.error[0] === "The fields space, relation_to, relation_part must make a unique set."
    || 
    err.length && /The fields space, relation_to, relation_part must make a unique set/.test(errors[0].error[0])
  ){
    return  ["\"Relation to\" and \"Relation part\" already added to the same workspace"];
  }
  return "Error occurred"; 
}


export const addRelationInfo = (payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.post(`${baseUrl}/relation_info`, payload)
      dispatch(createAction(types.ADD_RELATION_INFO, data));
      return data;
    } catch (err) {
      const errors = extendFilterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const getRelationInfo = () => { 
  return async dispatch => { 
    try {
      const { data } = await axios.get(`${baseUrl}/relation_info`)
      dispatch(createAction(types.GET_ALL_RELATION_INFO, data));
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export const getRelationInfoById = (id, payload) => { 
  return async dispatch => { 
    try {
      await axios.get(`${baseUrl}/relation_info/${id}`)
      dispatch(createAction(types.GET_RELATION_INFO, payload));
      return payload;
    } catch (err) {
      throw err;
    }
  }
}

export const updateRelationInfo = (id, payload) => { 
  return async dispatch => { 
    try {
      const { data } = await axios.put(`${baseUrl}/relation_info/${id}`, payload)
      dispatch(createAction(types.UPDATE_RELATION_INFO, {
        newData: data,
        datedData: payload,
      }));
      return payload;
    } catch (err) {
      const errors = extendFilterError(err);
      dispatch(setMsgInfo({
        success: false, 
        msg: errors,
      }));
      throw err;
    }
  }
}

export const deleteRelationInfoById = (id, payload) => { 
  return async dispatch => { 
    try {
      await axios.delete(`${baseUrl}/relation_info/${id}`)
      dispatch(createAction(types.DELETE_RELATION_INFO, payload));
      return payload;
    } catch (err) {
      throw err;
    }
  }
}

export const deleteBulkRelationInfo = (payload) => { 
  return async dispatch => { 
    try {
      await axios.post(`${baseUrl}/relation_info/bulk_delete`, {ids: payload})
      dispatch(createAction(types.DELETE_BULK_RELATION_INFO, payload));
      return payload;
    } catch (err) {
      throw err
    }
  }
}


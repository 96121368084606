import axios from '../axios';
import * as types from './types';
import { createTypes } from './createTypes';
import { setMsgInfo } from './msgInfo';
import API from './api';
import filterError from './common/filterError';
// import { getQueryParams } from './common/getQueryParams';

const baseUrl = API + '/organisation-function-proposal';

export const addOrgFunctionProposal = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/`, payload);
      dispatch({
        type: createTypes(types.ADD_ORG_FUNCTION_PROPOSAL).success,
        payload: data,
      });
      // dispatch(
      //   setMsgInfo({
      //     success: true,
      //     msg: ['Org Function proposal added successfully'],
      //     navigationCounter: 2,
      //   }),
      // );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch({
        type: createTypes(types.ADD_ORG_FUNCTION_PROPOSAL).failure,
        payload: errors,
      });
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const addBulkOrgFunctionProposal = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/bulk_create/`, payload);
      // dispatch(
      //   setMsgInfo({
      //     success: true,
      //     msg: ['Org Function proposal added successfully'],
      //     navigationCounter: 2,
      //   }),
      // );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};


export const getRegisterInstituteOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/register_institute_options/`);
      dispatch({
        type: createTypes(
          types.GET_ORG_FUNCTION_ASSIGNMENT_REGISTER_INSTITUTE_OPTIONS,
        ).success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const getSchoolOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/school_options/`);
      dispatch({
        type: createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_SCHOOL_OPTIONS).success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const getMedicalFacilityOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/medical_facility_options/`);
      dispatch({
        type: createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_MEDICAL_FACILITY_OPTIONS)
          .success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const getCreditCardOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/credit_card_options/`);
      dispatch({
        type: createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_CREDIT_CARD_OPTIONS)
          .success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export const getBankOptions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}/bank_options/`);
      dispatch({
        type: createTypes(types.GET_ORG_FUNCTION_ASSIGNMENT_BANK_OPTIONS).success,
        payload: data,
      });
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
    }
  };
};

export function deleteOrgFunctionAssignment(orgFunctionAssignmentIds) {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}/bulk_delete`, {
        selected_org_ids: orgFunctionAssignmentIds,
      });

      dispatch({
        type: types.DELETE_FAVORITE_ORGANISATION,
        payload: orgFunctionAssignmentIds,
      });
    } catch (err) {
      throw err;
    }
  };
}


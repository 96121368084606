/* eslint-disable */
import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withCustomRouter,
  withUserPreferences,
} from "components/hoc";
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withAutoMobileInfo } from "components/hoc/AutoMobileInfo";


const AutoMobile = (props) => {
  const mapFields = () => {
    const { 
      autoMobileInfo, 
      trObj, 
      fd,
    } = props;

    console.log(autoMobileInfo) 

    return autoMobileInfo.data.map((data) => {
      return { 
        ...data,
        id: data.id, 
        auto_brand: trObj(data?.auto_brand_detail),
        means_of_transport: trObj(data?.means_of_transport_detail),
        mot_color: trObj(data?.mot_color_detail),
        energy_source: trObj(data?.energy_source_detail),
        year_of_manufacture: trObj(data?.year_of_manufacture_detail),
        vehicle_no: data?.vehicle_id_no,
        initial_reg_date: fd(data.initial_reg_date)
      }
    });
  }

  const { 
    loaded,
    deleteBulkAutoMobileInfo,
    gotoCreatePath,
  } = props;

  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <MyDataContentTemplate 
      tableName="automobile"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkAutoMobileInfo}
    />
  )
}

export default compose(
  withTranslation,
  withAutoMobileInfo,
  withCustomRouter,
  withUserPreferences,
)(AutoMobile);


import React from "react";
import { connect } from "react-redux";
import { getEnergySource } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withEnergySource = (WrapperComponent) => {
  class WithEnergySource extends React.Component {
    componentDidMount() {
      const { getEnergySource } = this.props;
      if (loaded) return;
      loaded = true;
      getEnergySource();
    }

    getEnergySourceOptions = () => undefined

    getEnergySourceLocaleOptions = () => {
      const { energySource } = this.props;
      return energySource.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
            energySourceOptions={this.getEnergySourceOptions()} 
            energySourceLocaleOptions={this.getEnergySourceLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ energySource }) => ({ energySource })
  const WithEnergySourceHOC = connect(mapStateToProps, {
    getEnergySource,
  })(WithEnergySource);
  hoistNonReactStatics(WithEnergySourceHOC, WrapperComponent)
  return WithEnergySourceHOC
}
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { withTranslation, withUserPreferences } from '../../../hoc';
import { useDataArea } from '../../../../hooks/useDataArea';
import { useOrgIndicator } from '../../../../hooks/useOrgIndicator';
import { ORG_INDICATOR_TYPES } from '../../../../constants';
import { documentSlice } from 'rootStateHandler/selectors';
import { setMsgInfo } from 'actions/msgInfo';
import styled from 'styled-components';
import Modal from 'components/common/Modal';
import Button from 'components/common/FormItems/Button';
import {
  filterCriteriaThunk,
  onFilterCriteriaThunk,
  listDocumentCategoriesThunk,
  listDocumentTypesThunk,
  listPersonsThunk,
  listFavoriteFiltersThunk,
} from 'components/Dashboard/Documents/documentStates/documentThunk';
import {
  monthFormater,
  yearFormater,
  reformatDate,
  getDateFormat,
} from 'components/Dashboard/Documents/utils';
import Icon from 'components/Icon';
import './FilterCriteria.css';
import { isEmpty } from 'lodash';
import {
  createFavoriteFilterService,
  deleteFavoriteFilterService,
} from '../services/documentServices';

const StyledButton = styled(Button)`
  background: #4c81be;
  border: 3px solid #1d579b;
  border-radius: 2px;
  color: #ffffff;
  font-weight: 500;
  height: 38px;
  border: none;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease-in-out 300ms;
  white-space: nowrap;
  z-index: 10;
  &:disabled {
    color: #fff;
  }
  &:hover {
    box-shadow: 0 4px 10px #4c81be;
    background: ${(props) => (props.disabled ? '' : '#4c81be')};
    color: #fff;
  }
`;

const FilterCriteria = (props) => {
  const { tr, trObj, dateFormat } = props;
  const organization = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.ORG,
  });
  const person = useOrgIndicator({
    defaultOrgIndicator: ORG_INDICATOR_TYPES.PERSON,
  });
  const { dataAreaOptions } = useDataArea();

  const [toggleAddFavFilterModal, setToggleAddFavFilterModal] = useState(false);

  const [selectedFavCriteria, setSelectedFavCriteria] = useState(null);

  const [fitlerTitle, setFitlerTitle] = useState('');
  const [issuerState, setIssuerState] = useState('Issuer');
  const [toggleIssuer, setToggleIssuer] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filterFavorites, setFilterFavorites] = useState([]);
  const [filterParameters, setFilterParameters] = useState({});

  const [toggleDataArea, setToggleDataArea] = useState(false);
  const [toggleDocumentCategory, setToggleDocumentCategory] = useState(false);
  const [toggleDocumentType, setToggleDocumentType] = useState(false);
  const [toggleConcernedPerson, setToggleConcernedPerson] = useState(false);
  const [toggleIssuingDate, setToggleIssuingDate] = useState(false);
  const [toggleReferenceYear, setToggleReferenceYear] = useState(false);

  const [toggleReferenceMonth, setToggleReferenceMonth] = useState(false);
  const [referenceMonth, setReferenceMonth] = useState(null);
  const [referenceMonthEndDate, setReferenceMonthEndDate] = useState(null);

  const [selectedIssuingStartDate, setSelectedIssuingStartDate] = useState(null);
  const [selectedIssuingEndDate, setSelectedIssuingEndDate] = useState(null);

  const [validTo, setValidTo] = useState(null);
  const [validFrom, setValidFrom] = useState(null);
  const [selectedValidFrom, setSelectedValidFrom] = useState(null);
  const [selectedValidTo, setSelectedValidTo] = useState(null);

  const [referenceYearEndDate, setReferenceYearEndDate] = useState(null);
  const [referenceYear, setReferenceYear] = useState(null);

  const [toggleValidRange, setToggleValidRange] = useState(false);
  const [clearField, setClearField] = useState(false);
  const [clearYearField, setClearYearField] = useState(false);
  const [clearMonthField, setClearMonthField] = useState(false);
  const [toggleClearBtn, setToggleClearBtn] = useState(false);

  const [favoriteFilter, setFavoriteFilter] = useState('select-favorite');

  const {
    list_document_categories,
    list_document_types,
    list_persons,
    favorite_filters,
  } = useSelector(documentSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilterFavorites(favorite_filters);
  }, [setFilterFavorites, fitlerTitle, toggleAddFavFilterModal, favorite_filters]);

  useEffect(() => {
    dispatch(listDocumentCategoriesThunk());
    dispatch(listDocumentTypesThunk());

    dispatch(listPersonsThunk());

    dispatch(listFavoriteFiltersThunk());

    if (toggleClearBtn || clearField || clearYearField || clearMonthField) {
      dispatch(onFilterCriteriaThunk(true));
    } else {
      dispatch(onFilterCriteriaThunk(false));
    }
  }, [
    dispatch,
    validTo,
    validFrom,
    toggleClearBtn,
    clearField,
    clearYearField,
    clearMonthField,
  ]);

  useEffect(() => {
    const cleanedFilterKeys = Object.keys(filterParameters)
      .filter(
        (key) =>
          Array.isArray(filterParameters[key]) && filterParameters[key].length != 0,
      )
      .reduce((acc, key) => {
        acc[key] = filterParameters[key];
        return acc;
      }, {});

    if (Object.values(cleanedFilterKeys).length >= 1) {
      dispatch(onFilterCriteriaThunk(true));
    } else {
      dispatch(onFilterCriteriaThunk(false));
    }
  }, [Object.values(filterParameters)]);

  const handleToggleDataArea = () => {
    setToggleDataArea((toggleDataArea) => !toggleDataArea);
    setDropdownOpen(false);
  };

  const handleToggleDocumentCategory = () => {
    setToggleDocumentCategory((toggleDocumentCategory) => !toggleDocumentCategory);
    setDropdownOpen(false);
  };

  const handleToggleConcerendPerson = () => {
    setToggleConcernedPerson((toggleConcernedPerson) => !toggleConcernedPerson);
    setDropdownOpen(false);
  };

  const handleToggleIssuer = () => {
    setToggleIssuer((toggleIssuer) => !toggleIssuer);
    setDropdownOpen(false);
  };

  const handleToggleIssuerState = (issuerState) => {
    setIssuerState(issuerState);
    setToggleIssuer((toggleIssuer) => !toggleIssuer);
    setDropdownOpen(false);
  };

  const handleToggleValidRange = () => {
    setToggleValidRange((toggleValidRange) => !toggleValidRange);
    setDropdownOpen(false);
  };

  const handleToggleDocumentType = () => {
    setToggleDocumentType((toggleDocumentType) => !toggleDocumentType);
    setDropdownOpen(false);
  };

  const handleIssuingDate = () => {
    setToggleIssuingDate((toggleIssuingDate) => !toggleIssuingDate);
    setDropdownOpen(false);
  };

  const handleToggleReferenceYear = () => {
    setToggleReferenceYear((toggleReferenceYear) => !toggleReferenceYear);
    setDropdownOpen(false);
  };

  const handleToggleReferenceMonth = () => {
    setToggleReferenceMonth((toggleReferenceMonth) => !toggleReferenceMonth);
    setDropdownOpen(false);
  };

  const handleFilterParameters = (filterField, { target: { checked, value } }) => {
    if (checked) {
      setFilterParameters(
        filterParameters,
        (filterParameters[filterField] = filterParameters[filterField]
          ? [...filterParameters[filterField], value]
          : [value]),
      );

      dispatch(filterCriteriaThunk(filterParameters));
    } else {
      setFilterParameters(
        filterParameters,
        (filterParameters[filterField] = filterParameters[filterField]?.filter(
          (item) => item !== value,
        )),
      );

      if (!filterParameters[filterField].length) {
        setFilterParameters({ ...delete filterParameters[filterField] });
      }
      dispatch(filterCriteriaThunk(filterParameters));
    }
  };

  const handleIssuingDateFilterParameters = (filterField, date) => {
    if (date) {
      setClearField(true);

      const formatStartDate =
        filterField == 'inssuing_start_date'
          ? reformatDate(date)
          : selectedIssuingStartDate == null
          ? reformatDate(new Date('1, 01, 1900'))
          : reformatDate(selectedIssuingStartDate);

      const formatEndDate =
        filterField == 'inssuing_end_date'
          ? reformatDate(date)
          : selectedIssuingEndDate == null
          ? reformatDate(new Date('12, 31, 9999'))
          : reformatDate(selectedIssuingEndDate);

      if (formatEndDate + 1 > formatStartDate) {
        setFilterParameters(
          filterParameters,

          (filterParameters['inssuing_date'] = [formatStartDate, formatEndDate]),
        );

        dispatch(filterCriteriaThunk(filterParameters));

        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [`${tr('Start date can not be younger than end date')}`],
          }),
        );

        setFilterParameters({ ...delete filterParameters['inssuing_date'] });
      }
    } else {
      setClearField(false);
      setFilterParameters({ ...delete filterParameters['inssuing_date'] });

      dispatch(filterCriteriaThunk(filterParameters));
    }
  };

  const handleClearIssuingDateIput = () => {
    setClearField(false);
    setSelectedIssuingStartDate(null);
    setSelectedIssuingEndDate(null);

    setFilterParameters({ ...delete filterParameters['inssuing_date'] });

    dispatch(filterCriteriaThunk(filterParameters));
  };

  const handleReferenceMonthParameters = ({ target: { value, name } }) => {
    setClearMonthField(true);

    if (value > 0) {
      //Date format should be like this: 01

      const formatStartDate =
        name == 'referenceMonthStart'
          ? monthFormater(referenceMonth, value)
          : referenceMonth == null
          ? monthFormater(referenceMonth, '01')
          : monthFormater(null, referenceMonth);

      const formatEndDate =
        name == 'referenceMonthEndDate'
          ? monthFormater(referenceMonthEndDate, value)
          : referenceMonthEndDate == null
          ? monthFormater(referenceMonthEndDate, '12')
          : monthFormater(null, referenceMonthEndDate);

      if (
        parseInt(formatStartDate) < 1 ||
        parseInt(formatStartDate) > 12 ||
        parseInt(formatEndDate) <= 0 ||
        parseInt(formatEndDate) > 12
      ) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              `${tr(
                'Start or End month should not be less than 01 or greater than 12',
              )}`,
            ],
          }),
        );
        setFilterParameters({
          ...delete filterParameters['referenceMonth'],
        });
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      }

      if (parseInt(formatEndDate) + 1 > parseInt(formatStartDate)) {
        setFilterParameters(
          filterParameters,

          (filterParameters['referenceMonth'] = [formatStartDate, formatEndDate]),
        );

        dispatch(filterCriteriaThunk(filterParameters));

        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [`${tr('Start date can not be younger than end date')}`],
          }),
        );

        setFilterParameters({
          ...delete filterParameters['referenceMonth'],
        });
      }
    } else {
      setClearMonthField(false);

      setFilterParameters({ ...delete filterParameters['referenceMonth'] });

      dispatch(filterCriteriaThunk(filterParameters));
    }
  };

  const handleClearMonthIput = () => {
    setReferenceMonth(null);
    setReferenceMonthEndDate(null);
    setClearMonthField(false);

    setFilterParameters({ ...delete filterParameters['referenceMonth'] });

    dispatch(filterCriteriaThunk(filterParameters));
  };

  const handleReferenceYearParameters = ({ target: { value, name } }) => {
    setClearYearField(true);
    if (value > 0) {
      //Date format should be like this: 2023
      const formatStartDate =
        name == 'referenceYearStart'
          ? yearFormater(referenceYear, value)
          : referenceYear == null
          ? yearFormater(referenceYear, '1900')
          : yearFormater(null, referenceYear);

      const formatEndDate =
        name == 'referenceYearEndDate'
          ? yearFormater(referenceYearEndDate, value)
          : referenceYearEndDate == null
          ? yearFormater(referenceYearEndDate, '9999')
          : yearFormater(null, referenceYearEndDate);

      if (
        parseInt(formatStartDate) < 1900 ||
        parseInt(formatStartDate) > 9999 ||
        parseInt(formatEndDate) < 1900 ||
        parseInt(formatEndDate) > 9999
      ) {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [
              `${tr(
                'Start or End year should not be younger than 9999 or older than 1900',
              )}`,
            ],
          }),
        );
        setFilterParameters({
          ...delete filterParameters['referenceYear'],
        });
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      }

      if (parseInt(formatEndDate) + 1 > parseInt(formatStartDate)) {
        setFilterParameters(
          filterParameters,

          (filterParameters['referenceYear'] = [formatStartDate, formatEndDate]),
        );

        dispatch(filterCriteriaThunk(filterParameters));

        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [`${tr('start date can not be younger than end date')}`],
          }),
        );

        setFilterParameters({
          ...delete filterParameters['referenceYear'],
        });
      }
    } else {
      setClearYearField(false);

      setFilterParameters({ ...delete filterParameters['referenceYear'] });

      dispatch(filterCriteriaThunk(filterParameters));
    }
  };

  const handleClearYearIput = () => {
    setReferenceYear(null);
    setReferenceYearEndDate(null);
    setClearYearField(false);

    setFilterParameters({ ...delete filterParameters['referenceYear'] });
    dispatch(filterCriteriaThunk(filterParameters));
  };

  const handleValidRangeFilterParameters = (filterField, date) => {
    if (date) {
      setToggleClearBtn(true);

      const formatStartDate =
        filterField == 'valid_from'
          ? reformatDate(date)
          : selectedValidFrom == null
          ? reformatDate(new Date('1, 01, 1900'))
          : reformatDate(selectedValidFrom);

      const formatEndDate =
        filterField == 'valid_to'
          ? reformatDate(date)
          : selectedValidTo == null
          ? reformatDate(new Date('12, 31, 9999'))
          : reformatDate(selectedValidTo);

      if (formatEndDate + 1 > formatStartDate) {
        setFilterParameters(
          filterParameters,

          (filterParameters['valid_range'] = [formatStartDate]),
        );

        dispatch(filterCriteriaThunk(filterParameters));

        dispatch(
          setMsgInfo({
            success: false,
            msg: [],
          }),
        );
      } else {
        dispatch(
          setMsgInfo({
            success: false,
            msg: [`${tr('start date can not be younger than end date')}`],
          }),
        );

        setFilterParameters({ ...delete filterParameters['valid_range'] });
      }
    } else {
      setToggleClearBtn(false);
      setFilterParameters({ ...delete filterParameters['valid_range'] });

      dispatch(filterCriteriaThunk(filterParameters));
    }
  };

  const handleValidDateClear = () => {
    setValidFrom(null);
    setValidTo(null);
    setSelectedValidFrom(null);
    setSelectedValidTo(null);
    setToggleClearBtn(false);

    setFilterParameters({ ...delete filterParameters['valid_range'] });

    dispatch(filterCriteriaThunk(filterParameters));
  };

  const handleSaveFilterParameters = async () => {
    try {
      if (filterParameters && fitlerTitle) {
        setFilterFavorites((prevFavorites) => [...(prevFavorites || [])]);

        const payload = {
          title: fitlerTitle,
          criterias: filterParameters,
        };

        await createFavoriteFilterService(payload);

        dispatch(listFavoriteFiltersThunk());

        setToggleAddFavFilterModal(false);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleSelectFilter = (favoriteCriteria) => {
    console.log('favoriteCriteria: ', favoriteCriteria.criterias);

    setFavoriteFilter(favoriteCriteria.title);
    setFilterParameters(favoriteCriteria.criterias);

    setSelectedFavCriteria(favoriteCriteria.criterias);

    dispatch(filterCriteriaThunk(favoriteCriteria.criterias));
  };

  const handlClearFavoritFilter = () => {
    setFavoriteFilter('select-favorite');
    setFilterParameters({});
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDeleteFilterParameters = async (favoriteId, favoriteName) => {
    try {
      const filterFavorites_copy = [...filterFavorites];

      const filteredFavorites = filterFavorites_copy.filter((favorite) => {
        return favorite.id !== favoriteId;
      });

      setFilterFavorites(filteredFavorites);

      await deleteFavoriteFilterService(favoriteId);

      dispatch(
        setMsgInfo({
          success: true,
          msg: [`${favoriteName} deleted successfully`],
        }),
      );

      dispatch(listFavoriteFiltersThunk());
    } catch (error) {
      throw error;
    }
  };

  const dataAreaToExclude = ['V', 'A'];

  const favoriteDataAreaFilters = selectedFavCriteria
    ? selectedFavCriteria['data_area']
    : [];

  const data_area = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'fileIcon'} width={24} height={24} />

        <button className="dropdown-btn" onClick={() => handleToggleDataArea()}>
          {tr('Data Area')}
          <span>
            {!toggleDataArea ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>
        </button>
      </div>

      {toggleDataArea && (
        <div className="dropdown-inner-container">
          {dataAreaOptions
            ?.filter((options) => !dataAreaToExclude.includes(options.id))
            .map((option, index) => (
              <div key={option.id + '_' + index.toString} className="checkboc-list">
                <input
                  type="checkbox"
                  name="filters"
                  value={option.id}
                  checked={
                    favoriteDataAreaFilters?.includes(option.id)
                      ? favoriteDataAreaFilters?.includes(option.id)
                      : undefined
                  }
                  onChange={
                    favoriteDataAreaFilters?.includes(option.id)
                      ? undefined
                      : (event) => handleFilterParameters('data_area', event)
                  }
                />
                <label>{trObj(option)}</label>
              </div>
            ))}
        </div>
      )}
    </>
  );

  const favoriteDocumentCategoryFilters = selectedFavCriteria
    ? selectedFavCriteria['document_category']
    : [];

  const document_category = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'cpuIcon'} width={24} height={24} />

        <button
          className="dropdown-btn"
          onClick={() => handleToggleDocumentCategory()}
        >
          {tr('Document Category')}
          <span>
            {!toggleDocumentCategory ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>
        </button>
      </div>

      {toggleDocumentCategory && (
        <div className="dropdown-inner-container">
          {list_document_categories.map((option, index) => (
            <div key={option.id + '_' + index.toString} className="checkboc-list">
              <input
                type="checkbox"
                name="filters"
                value={option.id}
                checked={favoriteDocumentCategoryFilters?.includes(option.id)}
                onChange={
                  favoriteDocumentCategoryFilters?.includes(option.id)
                    ? undefined
                    : (event) => handleFilterParameters('document_category', event)
                }
              />
              <label>{trObj(option)}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const favoriteDocumentTypeFilters = selectedFavCriteria
    ? selectedFavCriteria['document_type']
    : [];

  const document_type = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'docType'} width={24} height={24} />

        <button className="dropdown-btn" onClick={() => handleToggleDocumentType()}>
          {tr('Document Type')}
          <span>
            {!toggleDocumentType ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>
        </button>
      </div>

      {toggleDocumentType && (
        <div className="dropdown-inner-container">
          {list_document_types.map((option, index) => (
            <div key={option.id + '_' + index.toString} className="checkboc-list">
              <input
                type="checkbox"
                name="filters"
                value={option.id}
                checked={favoriteDocumentTypeFilters?.includes(option.id)}
                onChange={
                  favoriteDocumentTypeFilters?.includes(option.id)
                    ? undefined
                    : (event) => handleFilterParameters('document_type', event)
                }
              />
              <label>{trObj(option)}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const favoriteConcernedPersonFilters = selectedFavCriteria
    ? selectedFavCriteria['concerned_person']
    : [];

  const concerned_person = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'person'} width={24} height={24} />

        <button
          className="dropdown-btn"
          onClick={() => handleToggleConcerendPerson()}
        >
          {tr('Concerned Person')}
          <span>
            {!toggleConcernedPerson ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>
        </button>
      </div>

      {toggleConcernedPerson && (
        <div className="dropdown-inner-container">
          {list_persons?.map((option, index) => (
            <div key={option.id + '_' + index.toString} className="checkboc-list">
              <input
                type="checkbox"
                name="filters"
                value={option.id}
                checked={favoriteConcernedPersonFilters?.includes(option.id)}
                onChange={
                  favoriteConcernedPersonFilters?.includes(option.id)
                    ? undefined
                    : (event) => handleFilterParameters('concerned_person', event)
                }
              />
              <label>{option.first_name + ' ' + option.last_name}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const favoritIssuerOrgFilters = selectedFavCriteria
    ? selectedFavCriteria['issuing_org']
    : [];

  const issuer = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'organization'} width={24} height={24} />
        <button className="dropdown-btn" onClick={() => handleToggleIssuer()}>
          {tr(issuerState)}
          <span>
            {!toggleIssuer || issuerState == 'issuer' ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>
        </button>
      </div>

      {toggleIssuer && (
        <div className="dropdown-title-container">
          <div
            className="dropdown-title-items"
            onClick={() => handleToggleIssuerState('Issuer')}
          >
            {tr('Issuer')}
          </div>
          <div
            className="dropdown-title-items"
            onClick={() => handleToggleIssuerState('Issuing organization')}
          >
            {tr('Issuing organization')}
          </div>
          <div
            className="dropdown-title-items"
            onClick={() => handleToggleIssuerState('Issuing person')}
          >
            {tr('Issuing person')}
          </div>
        </div>
      )}

      {issuerState == 'Issuing organization' && !toggleIssuer && (
        <div className="dropdown-inner-container">
          {organization.organisationOrPersonOptions.map((option, index) => (
            <div key={option.id + '_' + index.toString} className="checkboc-list">
              <input
                type="checkbox"
                name="filters"
                value={option.id}
                checked={favoritIssuerOrgFilters?.includes(option.id)}
                onChange={
                  favoritIssuerOrgFilters?.includes(option.id)
                    ? undefined
                    : (event) => handleFilterParameters('issuing_org', event)
                }
              />
              <label>{trObj(option)}</label>
            </div>
          ))}
        </div>
      )}

      {issuerState == 'Issuing person' && !toggleIssuer && (
        <div className="dropdown-inner-container">
          {person.organisationOrPersonOptions.map((option, index) => (
            <div key={option.id + '_' + index.toString} className="checkboc-list">
              <input
                type="checkbox"
                name="filters"
                value={option?.id}
                checked={favoritIssuerOrgFilters?.includes(option.id)}
                onChange={
                  favoritIssuerOrgFilters?.includes(option.id)
                    ? undefined
                    : (event) => handleFilterParameters('issuing_org', event)
                }
              />
              <label>{trObj(option)}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const issuing_date = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'calendarEvent'} width={24} height={24} />

        <button className="dropdown-btn" onClick={() => handleIssuingDate()}>
          {tr('issuing date')}
          <span>
            {!toggleIssuingDate ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>

          {clearField && (
            <button className="clear-btn" onClick={handleClearIssuingDateIput}>
              <Icon icon={'cancle'} width={16} height={16} />
            </button>
          )}
        </button>
      </div>

      {toggleIssuingDate && (
        <>
          <div className="dropdown-inner-container-inputDateField ">
            <div className="inputDateField">
              <label>{tr('start date')}:</label>
              <DatePicker
                placeholderText={getDateFormat(dateFormat)}
                dateFormat={getDateFormat(dateFormat)}
                selected={selectedIssuingStartDate}
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                onChange={(date) => {
                  setSelectedIssuingStartDate(date);

                  if (selectedIssuingEndDate == null) {
                    setSelectedIssuingEndDate(new Date('12, 31, 9999'));
                  }

                  handleIssuingDateFilterParameters('inssuing_start_date', date);
                }}
                // isClearable={true}
              />
            </div>

            <div className="inputDateField">
              <label>{tr('end date')}:</label>
              <DatePicker
                placeholderText={getDateFormat(dateFormat)}
                dateFormat={getDateFormat(dateFormat)}
                selected={selectedIssuingEndDate}
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                onChange={(date) => {
                  setSelectedIssuingEndDate(date);

                  if (selectedIssuingStartDate == null) {
                    setSelectedIssuingStartDate(new Date('1, 01, 1900'));
                  }

                  handleIssuingDateFilterParameters('inssuing_end_date', date);
                }}
                // isClearable={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );

  const reference_year = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'calendarYear'} width={24} height={24} />

        <button className="dropdown-btn" onClick={() => handleToggleReferenceYear()}>
          {tr('reference year')}
          <span>
            {!toggleReferenceYear ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>

          {clearYearField && (
            <button className="clear-btn" onClick={handleClearYearIput}>
              <Icon icon={'cancle'} width={16} height={16} />
            </button>
          )}
        </button>
      </div>

      {toggleReferenceYear && (
        <>
          <div className="dropdown-inner-container-inputDateField ">
            <div className="inputDateField">
              <label>{tr('From')}:</label>
              <input
                type="text"
                name="referenceYearStart"
                value={referenceYear}
                placeholder="yyyy"
                onChange={({ target }) => {
                  setReferenceYear(target.value);

                  if (referenceYearEndDate == null) setReferenceYearEndDate('9999');

                  handleReferenceYearParameters({ target });
                }}
              />
            </div>

            <div className="inputDateField">
              <label>{tr('To')}:</label>
              <input
                type="text"
                name="referenceYearEndDate"
                value={referenceYearEndDate}
                placeholder="yyyy"
                onChange={({ target }) => {
                  setReferenceYearEndDate(target.value);

                  if (referenceYear == null) setReferenceYear('1900');

                  handleReferenceYearParameters({ target });
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );

  const reference_month = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'calendarYear'} width={24} height={24} />

        <button
          className="dropdown-btn"
          onClick={() => handleToggleReferenceMonth()}
        >
          {tr('reference month')}
          <span>
            {!toggleReferenceMonth ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>

          {clearMonthField && (
            <button className="clear-btn" onClick={handleClearMonthIput}>
              <Icon icon={'cancle'} width={16} height={16} />
            </button>
          )}
        </button>
      </div>

      {toggleReferenceMonth && (
        <div className="dropdown-inner-container-inputDateField ">
          <div className="inputDateField">
            <label>{tr('From')}:</label>
            <input
              type="text"
              name="referenceMonthStart"
              value={referenceMonth}
              placeholder="mm"
              onChange={({ target }) => {
                setReferenceMonth(target.value);

                if (referenceMonthEndDate == null) setReferenceMonthEndDate('12');

                return handleReferenceMonthParameters({ target });
              }}
            />
          </div>

          <div className="inputDateField">
            <label>{tr('To')}:</label>
            <input
              type="text"
              name="referenceMonthEndDate"
              value={referenceMonthEndDate}
              placeholder="mm"
              onChange={({ target }) => {
                setReferenceMonthEndDate(target.value);

                if (referenceMonth == null) setReferenceMonth('01');

                return handleReferenceMonthParameters({ target });
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  const valid_range = (
    <>
      <div className="filterAreaBox">
        <Icon icon={'calendarRange'} width={24} height={24} />
        <button className="dropdown-btn" onClick={() => handleToggleValidRange()}>
          {tr('valid on')}
          <span>
            {!toggleValidRange ? (
              <i className="fa fa-caret-down"></i>
            ) : (
              <i className="fa fa-caret-up"></i>
            )}
          </span>

          {toggleClearBtn && (
            <button className="clear-btn" onClick={handleValidDateClear}>
              <Icon icon={'cancle'} width={16} height={16} />
            </button>
          )}
        </button>
      </div>

      {toggleValidRange && (
        <>
          <div className="dropdown-inner-container-inputDateField ">
            <div className="validityInputDateField">
              <label>{tr('valid on')}:</label>
              <DatePicker
                placeholderText={getDateFormat(dateFormat)}
                dateFormat={getDateFormat(dateFormat)}
                selected={selectedValidFrom}
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                onChange={(date) => {
                  setSelectedValidFrom(date);

                  if (selectedValidTo == null) {
                    setSelectedValidTo(new Date('12, 31, 9999'));
                  }

                  handleValidRangeFilterParameters('valid_from', date);
                }}

                // isClearable={true}
              />
            </div>

            {/* <div className="validityInputDateField">
              <label>{tr('valid to')}:</label>
              <DatePicker
                placeholderText={getDateFormat(dateFormat)}
                dateFormat={getDateFormat(dateFormat)}
                selected={selectedValidTo}
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                onChange={(date) => {
                  setSelectedValidTo(date);

                  if (selectedValidFrom == null) {
                    setSelectedValidFrom(new Date('1, 01, 1900'));
                  }

                  handleValidRangeFilterParameters('valid_to', date);
                }}
                // isClearable={true}
              />
            </div> */}
          </div>
        </>
      )}
    </>
  );

  const disable =
    favoriteFilter === 'select-favorite' && isEmpty(filterParameters)
      ? true
      : !favoriteFilter === 'select-favorite' && !isEmpty(filterParameters)
      ? true
      : favoriteFilter === 'select-favorite' && !isEmpty(filterParameters)
      ? false
      : true;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="selection-box-container"
      >
        <div className="select-container">
          <div
            className="dropdown-btn"
            onClick={() => setDropdownOpen(!isDropdownOpen)}
          >
            {favoriteFilter === 'select-favorite'
              ? tr('--Select favorite--')
              : favoriteFilter}

            <span>
              {!isDropdownOpen ? (
                <i className="fa fa-caret-down"></i>
              ) : (
                <i className="fa fa-caret-up"></i>
              )}
            </span>
          </div>
          {isDropdownOpen && (
            <div className="dropdown-list">
              <div className="list-item">
                <span onClick={handlClearFavoritFilter}>
                  {tr('--Select favorite--')}
                </span>
              </div>

              {filterFavorites?.map((favorite, index) => (
                <div className="list-item" key={index}>
                  <span
                    onClick={() => {
                      handleSelectFilter(favorite);
                      setDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    {favorite?.title}
                  </span>

                  <div
                    onClick={() =>
                      handleDeleteFilterParameters(favorite.id, favorite?.title)
                    }
                  >
                    <Icon icon={'trashIcon'} width={16} height={16} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <StyledButton
          name={tr('Save criteria')}
          disabled={disable}
          onClick={() => {
            setToggleAddFavFilterModal(!toggleAddFavFilterModal);
          }}
        />
      </div>

      {data_area}
      {document_category}
      {document_type}
      {concerned_person}
      {issuer}
      {issuing_date}
      {reference_month}
      {reference_year}
      {valid_range}

      <Modal
        toggleModal={toggleAddFavFilterModal}
        // title={tr('Delete Document')}
        hasInput
        label={tr('Description of the filter')}
        name={'title'}
        value={fitlerTitle}
        // placeholder={'Date based'}
        handleChange={({ target }) => setFitlerTitle(target.value)}
        hasDualBtn
        buttonTitle={tr('Save')}
        onClick={handleSaveFilterParameters}
        handleToggleModal={() =>
          setToggleAddFavFilterModal(!toggleAddFavFilterModal)
        }
        // message={tr('E')}
      />
    </>
  );
};

export default compose(withTranslation, withUserPreferences)(FilterCriteria);

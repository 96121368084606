import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import check from 'check-types';
import { genId, isNumber } from 'components/utils';
import {
  withTranslation,
  withUserPreferences,
  withFieldError,
} from 'components/hoc';
import { SelectOptions } from '../SelectOptions';

const TinyInfoText = styled.small`
  color: gray;
  font-size: 0.8rem;
  margin-top: -10px;
  display: block;
`;

export const InputWrapper = styled.div`
  width: 98%;
  margin-bottom: 0.7rem; /* Add margin-bottom to create space for the input field */

  .displayError {
    margin-left: 0;
    width: 99.5%;
  }
`;

export const inputStyle = `
  font-size: 15px;
  width: 100%;
  border-radius: 5px;
  padding: 1.5px;
  padding-left: 10px;
  height: 42px;
  /* Remove margin-bottom to prevent overlap with the containing div */
  /* margin-bottom: 0.7rem; */

  &:focus {
    box-shadow: none;
    border: 1px solid #4285f4;
  }

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
    color: #bbb;
  }

  &::-webkit-input-placeholder { /* Edge */
    color: #bbb;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bbb;
  }

  &::placeholder {
    color: 'var(--admincat-color-grey-2)',
  }

  /* Additional styles to help prevent autocomplete styling */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: inherit !important;
  }
`;

const StyledInput = styled.input`
  &&& {
    ${inputStyle}
    border: ${({ error }) =>
      error ? '1px solid red !important' : '1px solid var(--admincat-color-grey-2)'};
    height: 38px;
    color: 'var(--admincat-color-grey-2)',
    box-sizing: border-box; 
    width: 100%; 
  }
`;

class Input extends React.Component {
  onChange = ({ target }) => {
    const { name, value } = target;
    // fieldtype to fix type="number" bug occuring on FireFox browsers,
    // in case number specific input is to be used in conjunction with
    // with below condition, remember to pass fieldType="number"
    const { onChange, fieldType, xfn } = this.props;
    if (fieldType === 'number') {
      target = { name, value: xfn(value, true) };
      // only numbers and dot(.) entry are allowed
      // e.g 12345.
      if (isNumber(target.value) || target.value.endsWith('.')) {
        onChange({ target });
      }
    } else {
      onChange({ target });
    }
    // const { onChange, min, max, fieldType } = this.props;
    // if (
    //   (fieldType === 'number' && !isNumber(value)) ||
    //   (parseFloat(value) && (min || max) && (+value < min || +value > max))
    // ) {
    //   // for a number input, we want to ensure the value enter
    //   // does not spill outside the min and max values
    //   // nor a non-number value allowed
    //   return;
    // }
  };

  render() {
    const {
      style,
      inputStyle,
      name,
      label,
      required,
      showRequiredAsterisk,
      type,
      value,
      placeholder,
      langPlaceholder,
      langOptions,
      localeOptions,
      options,
      className,
      disabled,
      id,
      tr,
      error,
      forwardedRef,
      tinyInfo,
      fieldType,
      fn,
      ...rest
    } = this.props;

    const _id = id || genId();
    const listid = genId();
    const hasOptions = langOptions || localeOptions || options;
    const isNumberInput = fieldType === 'number';
    const extraProps = {
      ...(isNumberInput
        ? { value: fn(value, true)}
        : { value: value || '' }),
    };

    return (
      <InputWrapper
        className={`form-group w-90 user-title ${className}`}
        style={{ ...style }}
      >
        {label && (
          <label className="form-label" htmlFor={_id}>
            {tr(label || '')}
            {/* {(required || showRequiredAsterisk) && ( */}
            {(required || showRequiredAsterisk) && (
              <span className="form-required">*</span>
            )}
          </label>
        )}
        <StyledInput
          {...rest}
          type={type}
          ref={forwardedRef}
          name={name}
          id={_id}
          onChange={(e) => this.onChange(e)}
          placeholder={tr(placeholder)}
          style={{ ...inputStyle, ...inputStyle }}
          disabled={disabled}
          required={required}
          showRequiredAsterisk={showRequiredAsterisk}
          list={listid}
          error={error}
          autoComplete="new-password" // This is more effective than "off" in many browsers
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          data-form-type="other"
          data-lpignore="true"
          {...extraProps}
        />
        {hasOptions && (
          <datalist id={listid}>
            <SelectOptions
              localeOptions={langOptions || localeOptions}
              options={options}
              {...rest}
            />
          </datalist>
        )}
        {tinyInfo && (
          <TinyInfoText>
            {check.string(tinyInfo) ? tr(tinyInfo) : tinyInfo}
          </TinyInfoText>
        )}
        <small id="passwordHelp" className="text-danger">
          {tr(error)}
        </small>
      </InputWrapper>
    );
  }
}

Input.defaultProps = {
  style: {},
  inputStyle: {},
  value: '',
  listid: '',
  fieldType: null,
  type: 'text',
  disabled: false,
  required: false,
  showRequiredAsterisk: false,
  className: '',
  tinyInfo: '',
  onChange: () => {},
};

export default compose(withTranslation, withUserPreferences, withFieldError)(Input);

import React from "react";
import { connect } from "react-redux";
import { getYears } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withYears = (WrapperComponent) => {
  class WithYears extends React.Component {
    componentDidMount() {
      const { getYears } = this.props;
      if (loaded) return;
      loaded = true;
      getYears();
    }
    getYearsOptions = () => undefined
    getYearsLocaleOptions = () => {
      const { years } = this.props;
      return years.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
            yearsOptions={this.getYearsOptions()} 
            yearsLocaleOptions={this.getYearsLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ years }) => ({ years })
  const WithYearsHOC = connect(mapStateToProps, {
    getYears,
  })(WithYears);
  hoistNonReactStatics(WithYearsHOC, WrapperComponent)
  return WithYearsHOC
}

import React from 'react';
import DashboardIcon from './icons/dashboard.svg';
import DashboardIconDark from './icons/dashboardDark.svg';
import EmailIcon from './icons/email.svg';
import EmailIconDark from './icons/emailDark.svg';
import FileIcon from './icons/file.svg';
import FileIconDark from './icons/fileDark.svg';
import FolderIcon from './icons/folder.svg';
import FolderIconDark from './icons/folderDark.svg';
import BagIcon from './icons/sub/bag.svg';
import BagIconDark from './icons/sub/bagDark.svg';
import CarIcon from './icons/sub/car.svg';
import CarIconDark from './icons/sub/carDark.svg';
import EduIcon from './icons/sub/edu.svg';
import EduIconDark from './icons/sub/eduDark.svg';
import FinanceIcon from './icons/sub/finance.svg';
import FinanceIconDark from './icons/sub/financeDark.svg';
import HealthIcon from './icons/sub/health.svg';
import HealthIconDark from './icons/sub/healthDark.svg';
import HouseIcon from './icons/sub/house.svg';
import HouseIconDark from './icons/sub/houseDark.svg';
import LeisureIcon from './icons/sub/leisure.svg';
import LeisureIconDark from './icons/sub/leisureDark.svg';
import BaseIcon from './icons/base.svg';
import BaseIconDark from './icons/baseDark.svg';
import PersonIcon from './icons/person.svg';
import PersonIconDark from './icons/personDark.svg';
import GroupIcon from './icons/group.svg';
import GroupIconDark from './icons/groupDark.svg';
import DogIcon from './icons/dog.svg';
import DogIconDark from './icons/dogDark.svg';
import StarIcon from './icons/star.svg';
import StarIconDark from './icons/starDark.svg';
import ContractIcon from './icons/contract.svg';
import ContractIconDark from './icons/contractDark.svg';
import DiseaseIcon from './icons/virus.svg';
import DiseaseIconDark from './icons/virusDark.svg';
import VaccinationIcon from './icons/syring.svg';
import VaccinationIconDark from './icons/syringDark.svg';
import LocationIcon from './icons/location.svg';
import LocationIconDark from './icons/locationDark.svg';
import ContactIcon from './icons/contact.svg';
import ContactIconDark from './icons/contactDark.svg';
import HomeIcon from './icons/Home.svg';
import HomeIconDark from './icons/HomeDark.svg';
import CardIcon from './icons/Card.svg';
import CardIconDark from './icons/CardDark.svg';
import LiabilityIcon from './icons/Liability.svg';
import LiabilityIconDark from './icons/LiabilityDark.svg';
import IncomeIcon from './icons/income.svg';
import IncomeIconDark from './icons/incomeDark.svg';
import AssetIcon from './icons/asset.svg';
import AssetIconDark from './icons/assetDark.svg';
import ConsumeIcon from './icons/consume.svg';
import ConsumeIconDark from './icons/consumeDark.svg';
import BookReaderIcon from './icons/book-reader.svg';
import BookReaderIconDark from './icons/book-reader-dark.svg';
import BookIcon from './icons/book.svg';
import BookIconDark from './icons/bookDark.svg';
import InboxIcon from './icons/inbox.svg';
import InboxIconDark from './icons/inboxDark.svg';
import SentIcon from './icons/outbox.svg';
import SentIconDark from './icons/outboxDark.svg';
import AttachIcon from './icons/attach.svg';
import AttachIconDark from './icons/attachDark.svg';
import TrashIcon from './icons/trash.svg';
import TrashIconDark from './icons/trashDark.svg';
import VideoIcon from './icons/video.svg';
import videoIconDark from './icons/videoDark.svg';
import DatabaseIcon from './icons/database.svg';
import ServerIcon from './icons/server.svg';
import HardDriveIcon from './icons/hard_drive.svg';
import { ADMIN_TYPES } from 'config/adminTypes';

// const path_base = "/dashboard/data/base";

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon,
    iconDark: DashboardIconDark,
    iconClosed: '',
    iconOpened: '',
  },
  {
    title: 'Data',
    path: '/dashboard/data/base/person',
    iconClosed: '',
    iconOpened: '',
    icon: FolderIcon,
    iconDark: FolderIconDark,
    subNav: [
      {
        title: 'Base',
        path: '/dashboard/data/base/person',
        icon: BaseIcon,
        iconDark: BaseIconDark,
        subNav: [
          {
            title: 'Person',
            path: '/dashboard/data/base/person',
            icon: PersonIcon,
            iconDark: PersonIconDark,
            cName: 'sub-nav',
          },
          {
            title: 'Person Groups',
            path: '/dashboard/data/base/person-groups',
            icon: GroupIcon,
            iconDark: GroupIconDark,
            cName: 'sub-nav',
          },
          {
            title: 'Companion Animal',
            path: '/dashboard/data/base/companion-animal',
            icon: DogIcon,
            iconDark: DogIconDark,
          },
          {
            title: 'Relation',
            path: '/dashboard/data/base/relation',
            icon: GroupIcon,
            iconDark: GroupIconDark,
          },
          // {
          //   title: 'Favourite Organisation',
          //   path: '/dashboard/data/base/favorite-org',
          //   icon: StarIcon,
          // },
          // {
          //   title: 'Organization',
          //   path: '/dashboard/data/base/organisation',
          //   icon: StarIcon,
          // },
          {
            title: 'Favourite Organization',
            path: '/dashboard/data/base/favorite-organisation',
            icon: StarIcon,
            iconDark: StarIconDark,
          },
          {
            title: 'Contract',
            path: '/dashboard/data/base/contract',
            icon: ContractIcon,
            iconDark: ContractIconDark,
          },
        ],
      },
      {
        title: 'Health',
        path: '/dashboard/data/health/disease',
        icon: HealthIcon,
        iconDark: HealthIconDark,
        subNav: [
          {
            title: 'Disease',
            path: '/dashboard/data/health/disease',
            icon: DiseaseIcon,
            iconDark: DiseaseIconDark,
          },
          {
            title: 'Vaccination',
            path: '/dashboard/data/health/vaccination',
            icon: VaccinationIcon,
            iconDark: VaccinationIconDark,
          },
        ],
      },
      {
        title: 'Housing',
        path: '/dashboard/data/housing/address',
        icon: HouseIcon,
        iconDark: HouseIconDark,
        subNav: [
          {
            title: 'Address',
            path: '/dashboard/data/housing/address',
            icon: LocationIcon,
            iconDark: LocationIconDark,
          },
          {
            title: 'Residence',
            path: '/dashboard/data/housing/residence',
            icon: HomeIcon,
            iconDark: HomeIconDark,
          },
          {
            title: 'Contact',
            path: '/dashboard/data/housing/contact',
            icon: ContactIcon,
            iconDark: ContactIconDark,
          },
          {
            title: 'Citizenship',
            path: '/dashboard/data/housing/citizenship',
            icon: GroupIcon,
            iconDark: GroupIconDark,
          },
        ],
      },
      {
        title: 'Finance',
        path: '/dashboard/data/finance/bank-account',
        icon: FinanceIcon,
        iconDark: FinanceIconDark,
        subNav: [
          {
            title: 'Bank Account',
            path: '/dashboard/data/finance/bank-account',
            icon: GroupIcon,
            iconDark: GroupIconDark,
          },
          {
            title: 'Credit Card',
            icon: CardIcon,
            iconDark: CardIconDark,
            path: '/dashboard/data/finance/credit-card',
          },
          {
            title: 'Asset Account',
            path: '/dashboard/data/finance/asset',
            icon: AssetIcon,
            iconDark: AssetIconDark,
          },
          {
            title: 'Liability Account',
            path: '/dashboard/data/finance/liability',
            icon: LiabilityIcon,
            iconDark: LiabilityIconDark,
          },
          {
            title: 'Income',
            path: '/dashboard/data/finance/income',
            icon: IncomeIcon,
            iconDark: IncomeIconDark,
          },
          {
            title: 'Consumption',
            path: '/dashboard/data/finance/consumption',
            icon: ConsumeIcon,
            iconDark: ConsumeIconDark,
          },
        ],
      },
      {
        title: 'Education',
        path: '',
        icon: EduIcon,
        iconDark: EduIconDark,
        subNav: [
          {
            title: 'Pre-school',
            path: '/dashboard/data/education/pre-school',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'General Education',
            path: '/dashboard/data/education/general-education',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'vocational school',
            path: '/dashboard/data/education/vocational-school',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'vocational training',
            path: '/dashboard/data/education/vocational-training',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'academic studies',
            path: '/dashboard/data/education/academic-studies',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'further training',
            path: '/dashboard/data/education/further-training',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
          {
            title: 'languages',
            path: '/dashboard/data/education/languages',
            icon: BookReaderIcon,
            iconDark: BookReaderIconDark,
          },
        ],
      },

      {
        title: 'Work',
        path: '/dashboard/data/work/experience',
        icon: BagIcon,
        iconDark: BagIconDark,
        subNav: [
          {
            title: 'Work Experience',
            path: '/dashboard/data/work/experience',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
        ],
      },
      {
        title: 'Mobility',
        path: '/dashboard/data/mobility',
        icon: CarIcon,
        iconDark: CarIconDark,
        subNav: [
          {
            title: 'Auto Mobile',
            path: '/dashboard/data/mobility/auto-mobile',
            icon: CarIcon,
            iconDark: CarIconDark,
          },
          {
            title: 'Auto Registration',
            path: '/dashboard/data/mobility/auto-registration',
            icon: CarIcon,
            iconDark: CarIconDark,
          },
        ],
      },
      {
        title: 'leisure',
        path: '/dashboard/data/leisure/membership',
        icon: LeisureIcon,
        iconDark: LeisureIconDark,
        subNav: [
          {
            title: 'Membership',
            path: '/dashboard/data/leisure/membership',
            icon: BookIcon,
            iconDark: BookIconDark,
          },
        ],
      },
    ],
  },
  {
    title: 'Documents',
    path: '/dashboard/documents',
    icon: FileIcon,
    iconDark: FileIconDark,
  },
  {
    title: 'Mail',
    path: '/dashboard/mail',
    icon: EmailIcon,
    iconDark: EmailIconDark,
    subNav: [
      {
        title: 'Inbox',
        path: '/dashboard/mail/inbox',
        icon: InboxIcon,
        iconDark: InboxIconDark,
      },
      {
        title: 'Sent',
        icon: SentIcon,
        iconDark: SentIconDark,
        path: '/dashboard/mail/sent',
      },
      {
        title: 'Trash',
        path: '/dashboard/mail/trash',
        icon: TrashIcon,
        iconDark: TrashIconDark,
      },
      {
        title: 'Attachment Rule',
        path: '/dashboard/mail/attachment-rules',
        icon: AttachIcon,
        iconDark: AttachIconDark,
      },
    ],
  },
  {
    title: 'Quick tour',
    path: '/dashboard/quick-tour',
    icon: VideoIcon,
    iconDark: videoIconDark,
  },
];

export const AdminSidebarData = [
  {
    title: 'Admin Dashboard',
    path: '/admin/dashboard',
    icon: DashboardIcon,
    iconDark: DashboardIconDark,
    iconClosed: '',
    iconOpened: '',
    allowedAdminTypes: Object.values(ADMIN_TYPES),
  },
  {
    title: 'Mail',
    path: '/admin/dashboard/mail',
    icon: EmailIcon,
    iconDark: EmailIconDark,
    allowedAdminTypes: [ADMIN_TYPES.COMMUNICATION, ADMIN_TYPES.SUPER_USER],
  },
  {
    title: 'Proposals',
    path: '/admin/dashboard/proposal',
    icon: FolderIcon,
    iconDark: FolderIconDark,
    subNav: [
      {
        title: 'Entry Proposal',
        path: '/admin/dashboard/proposal',
        icon: HardDriveIcon,
        iconDark: HardDriveIcon,
      },
      {
        title: 'Organisation Proposal',
        icon: DatabaseIcon,
        iconDark: DatabaseIcon,
        path: '/admin/dashboard/proposal/org',
      },
      {
        title: 'Org Function Proposal',
        path: '/admin/dashboard/proposal/function_assignment',
        icon: ServerIcon,
        iconDark: ServerIcon,
      },
    ],
    allowedAdminTypes: [ADMIN_TYPES.DATA_ADMIN, ADMIN_TYPES.SUPER_USER],
  },
];

/* eslint-disable */
import React from "react";
import compose from "lodash/fp/compose";
import { 
  withTranslation,
  withCustomRouter,
  withUserPreferences,
} from "components/hoc";
import MyDataContentTemplate from 'components/Dashboard/MyData/common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';
import { withAutoRegistrationInfo } from "components/hoc/AutoRegistration";

const AutoRegistration = (props) => {
  const mapFields = () => {
    const { 
      autoRegistrationInfo, 
      trObj, 
      fd,
    } = props;

    console.log(autoRegistrationInfo)

    return autoRegistrationInfo.data.map((data) => {
      return { 
        ...data,
        valid_from: fd(data?.valid_from),
        valid_to: fd(data?.valid_to),
        auto_mobile: trObj(data?.auto_mobile_detail?.auto_brand_detail),
        person: data?.person_detail?.first_name + ' ' + data?.person_detail?.last_name,
        registration_org: data?.registration_org_detail?.name,
        document: data?.document_detail?.description,
        valid_from: fd(data.valid_from),
        valid_to: fd(data.valid_to),
      }
    });
  }

  const { 
    loaded,
    deleteBulkAutoRegistrationInfo,
    gotoCreatePath,
  } = props;


  if (!loaded) {
    return (
      <>
          <LottieLoader />
      </>)
  }

  return (
    <MyDataContentTemplate 
      tableName="auto_registration"
      columns={[]}
      data={mapFields()}
      handleCreateForm={gotoCreatePath}
      bulkDelete={deleteBulkAutoRegistrationInfo}
    />
  )
}

export default compose(
  withTranslation,
  withAutoRegistrationInfo,
  withCustomRouter,
  withUserPreferences,
)(AutoRegistration);


/* eslint-disable camelcase */
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalInfo, getFavoriteOrg } from '../actions';
import { ORG_INDICATOR_TYPES } from '../constants';


export const useOrgIndicator = ({
  defaultOrgIndicator = ORG_INDICATOR_TYPES.PERSON,
  onOrgIndicatorChange
}) => {
  const dispatch = useDispatch();
  const [org_indicator, setOrgIndicator] = useState(defaultOrgIndicator);

  useEffect(() => {
    dispatch(getPersonalInfo());
    dispatch(getFavoriteOrg());
  }, [dispatch]);

  const persons = useSelector((state) => state.personalInfo.data);
  const { favorites } = useSelector((state) => state.organisation);

  const handleOrgIndicatorChange = (value) => {
    setOrgIndicator(value);
    if (onOrgIndicatorChange) {
      onOrgIndicatorChange(value);
    }
  };

  const organisationOrPersonOptions = useMemo(() => {
    if (!org_indicator) return [];
    
    return org_indicator === ORG_INDICATOR_TYPES.ORG
      ? favorites.map(({ id, organisation_data }) => ({
          id,
          long_description_eng: organisation_data.name,
          long_description_deu: organisation_data.name,
          country_id: organisation_data.country,
          state_id: organisation_data.state,
          city_location: organisation_data.city_location,
        }))
      : persons.map(({ id, first_name, other_names, last_name }) => ({
          id,
          long_description_eng: `${first_name} ${last_name || other_names}`,
          long_description_deu: `${first_name} ${last_name || other_names}`,
        }));
  }, [org_indicator, favorites, persons]);

  return {
    org_indicator,
    handleOrgIndicatorChange,
    organisationOrPersonOptions,
  };
};


// export const useOrgIndicator = ({
//   defaultOrgIndicator = ORG_INDICATOR_TYPES.PERSON,
// }) => {
//   const dispatch = useDispatch();
//   const [org_indicator, handleOrgIndicatorChange] = useState(defaultOrgIndicator);

//   useEffect(() => {
//     dispatch(getPersonalInfo());
//     dispatch(getFavoriteOrg());
//   }, []);

//   const persons = useSelector((state) => state.personalInfo.data);
//   const { favorites } = useSelector((state) => state.organisation);


//   const getOrgIndicatorOptions = () => {
//     const contractorOptions =
//       org_indicator === ORG_INDICATOR_TYPES.ORG
//         ? favorites.map(({ id, organisation_data }) => ({
//             id,
//             long_description_eng: organisation_data.name,
//             long_description_deu: organisation_data.name,
//             country_id: organisation_data.country,
//             state_id: organisation_data.state,
//             city_location: organisation_data.city_location,
//           }))
//         : persons.map(({ id, first_name, other_names, last_name }) => ({
//             id,
//             long_description_eng: `${first_name} ${last_name || other_names}`,
//             long_description_deu: `${first_name} ${last_name || other_names}`,
//           }));

//     return contractorOptions;
//   };
//   const organisationOrPersonOptions = useMemo(getOrgIndicatorOptions, [
//     org_indicator,
//     favorites,
//     persons,
//   ]);

//   return {
//     org_indicator,
//     handleOrgIndicatorChange,
//     organisationOrPersonOptions,
//   };
// };


  // const getOrgIndicatorOptions = () => {
  //   const contractorOptions =
  //     org_indicator === ORG_INDICATOR_TYPES.PERSON
  //       ? persons.map(({ id, first_name, other_names, last_name }) => ({  
  //         id,
  //         long_description_eng: first_name,
  //         long_description_deu: first_name,
  //       }))
  //       : favorites.map(({ id, organisation_data }) => ({
  //         id,
  //         long_description_eng: organisation_data.name,
  //         long_description_deu: organisation_data.name,
  //         country_id: organisation_data.country,
  //         state_id: organisation_data.state,
  //         city_location: organisation_data.city_location,
  //       }));

  //   return contractorOptions;
  // };



import React from "react";
import { connect } from "react-redux";
import { getAutoBrand } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withAutoBrand = (WrapperComponent) => {
  class WithAutoBrand extends React.Component {
    componentDidMount() {
      const { getAutoBrand } = this.props;
      if (loaded) return;
      loaded = true;
      getAutoBrand();
    }
    getAutoBrandOptions = () => undefined
    getAutoBrandLocaleOptions = () => {
      const { autoBrand } = this.props;
      return autoBrand.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    render() {
      return (
        <WrapperComponent 
          autoBrandOptions={this.getAutoBrandOptions()} 
          autoBrandLocaleOptions={this.getAutoBrandLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ autoBrand }) => ({ autoBrand })
  const WithAutoBrandHOC = connect(mapStateToProps, {
    getAutoBrand,
  })(WithAutoBrand);
  hoistNonReactStatics(WithAutoBrandHOC, WrapperComponent)
  return WithAutoBrandHOC
}

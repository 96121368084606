import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { withUserLayoutChoice, withTranslation } from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';

// const ButtonWrapper = styled.div`
//   margin-top: -6px;
// `;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 5px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;


export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    background: var(--admincat-color-grey-1);
    width: auto;
    max-width: 94%;

    @media screen and (max-width: 768px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      width: auto;
      background: var(--admincat-color-grey-1);
      max-width: 87%;
    }
    @media screen and (min-width: 1200px) {
      min-height: 70vh;
      max-height: 70vh;
      overflow-y: auto;
      background: var(--admincat-color-grey-1);
      width: auto;
      max-width: 96%;
    }
  }
`;

const ViewDataContent = (props) => {
  const {
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    tr,
    msg,
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />
        </ButtonWrapper>
        </HeaderWrapper>
      <StyledContentTemplate {...rest} columns={columns} />
    </React.Fragment>
  );
};

ViewDataContent.defaultProps = {
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(withUserLayoutChoice, withTranslation)(ViewDataContent);

import React from "react";
import { connect } from "react-redux";
import {
  getMeasurementUnit,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'


let loaded = false;

export const withMeasurementUnit = (WrapperComponent) => {
  class WithMeasurementUnit extends React.Component {
    componentDidMount() {
      const { getMeasurementUnit } = this.props;
      if (loaded) return;
      loaded = true;
      getMeasurementUnit();
    }
    getMeasurementUnitOptions = () => undefined
    
    getMeasurementUnitLocaleOptions = () => {
      const { measurementUnits } = this.props;
      return measurementUnits.map(({ created_at, ...rest }) => ({ ...rest }));
    }

    getTimeMeasurementUnitLocaleOptions = () => {
      const { measurementUnits } = this.props;
      return measurementUnits.filter((unit) => unit.dimension === 'TIME');
    }

    getSpeedMeasurementUnitLocaleOptions = () => {
      const { measurementUnits } = this.props;
      return measurementUnits.filter((unit) => unit.dimension === 'SPEED');
    }

  
    render() {
      return (
        <WrapperComponent 
          measurementUnitOptions={this.getMeasurementUnitOptions()} 
          measurementLocaleOptions={this.getMeasurementUnitLocaleOptions()} 
          timeMeasurementLocaleOptions={this.getTimeMeasurementUnitLocaleOptions()}
          speedMeasurementLocaleOptions={this.getSpeedMeasurementUnitLocaleOptions()}
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ measurementUnits }) => ({ measurementUnits })

  const WithMeasurementUnitHOC = connect(mapStateToProps, {
    getMeasurementUnit,
  })(WithMeasurementUnit);

  hoistNonReactStatics(WithMeasurementUnitHOC, WrapperComponent)
  return WithMeasurementUnitHOC
}

/* eslint-disable */
import axios from '../../axios';
import * as types from '../types';
import createAction from '../createAction';
import { setMsgInfo } from '../';
import { getDocuments } from './documents';
import API from '../api';
import filterError from '../common/filterError';

// path for admin mail
const baseUrl = API + '/admin/mail/';

export const addMail = (payload) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          if (key != 'attachments') {
            formData.append(key, payload[key]);
          }
        }
      }

      if (payload.hasOwnProperty('attachments')) {
        if (payload.attachments.length > 0) {
          // loop over and insert all files here
          for (let i = 0; i < payload.attachments.length; i++) {
            formData.append(`attachments[${i}]`, payload.attachments[i]);
          }
          formData.append('number_of_attachments', payload.attachments.length);
        }
      }

      const { data } = await axios.post(baseUrl, formData);
      dispatch(createAction(types.ADD_MAIL, data));
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Mail sent successfully'],
        }),
      );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const getAllMail = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(baseUrl);
      dispatch(createAction(types.GET_MAILS, data));
      return data;
    } catch (err) {}
  };
};

export const updateMailArea = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}bulk_update_mail/`, payload);
      dispatch(createAction(types.GET_MAILS, data));
      // updating localstore with the updated documents
      await getDocuments()(dispatch);
    } catch (err) {
      throw err;
    }
  };
};

export const deleteMailById = (id, payload) => {
  return async (dispatch) => {
    try {
      await axios.delete(`${baseUrl}${id}/`);
      dispatch(createAction(types.DELETE_MAIL, payload));
    } catch (err) {}
  };
};

export const deleteBulkMail = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}bulk_delete/`, { ids: payload });
      dispatch(createAction(types.DELETE_BULK_MAIL, payload));
    } catch (err) {}
  };
};

export const deleteMailByIdPartially = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}partial_delete/`, { id: payload });
      dispatch(createAction(types.DELETE_MAIL_PARTIALLY, payload));
    } catch (err) {
      throw err;
    }
  };
};

export const deleteBulkMailPartially = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}bulk_partial_delete/`, { ids: payload });
      dispatch(createAction(types.DELETE_BULK_MAIL_PARTIALLY, payload));
    } catch (err) {
      throw err;
    }
  };
};

export const getMailServerInbox = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`${baseUrl}mail_server_inbox/`);
      // dispatch(createAction(types.GET_MAIL_SERVER_INBOX, data));
      dispatch(createAction(types.GET_MAILS, data));
      return data;
    } catch (err) {
      // throw err;
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Recent mails could not be retrieved'],
        }),
      );
    }
  };
};

export const undoMailDelete = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}undo_delete/`, { ids: payload });
      dispatch(createAction(types.UNDO_MAIL_DELETE, payload));
    } catch (err) {}
  };
};

export const markMailAsRead = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}mark_read/`, { ids: payload });
      dispatch(createAction(types.MARK_MAIL, payload));
    } catch (err) {}
  };
};

export const markMailAsUnread = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}mark_unread/`, { ids: payload });
      dispatch(createAction(types.MARK_MAIL, payload));
    } catch (err) {}
  };
};

export const exportMail = (payload) => {
  return async (dispatch) => {
    try {
      await axios.get(`${baseUrl}export_mail/`);
    } catch (err) {}
  };
};

import { getNoificationsService, dashboardSummaryService } from '../services';
import { setMsgInfo } from 'actions';
import {
  FailedApiCall,
  notifications,
  dashboardSummary,
  initializingApi,
} from '../slices';

export const dashboardSummaryThunk = () => async (dispatch) => {
  try {
    dispatch(initializingApi());
    const { data } = await dashboardSummaryService();
    dispatch(dashboardSummary(data));
  } catch (error) {
    // dispatch(FailedApiCall());
    // console.log(error.response)
    // console.log(error.response.status)
    if (error.response && error.response.status === 403) {
      // Set your custom error message
      dispatch(
        setMsgInfo({
          success: false,
          msg: ["You are not assigned to any Space yet. Under Settings you can create one"],
        })
      );
    } else {
      dispatch(
        setMsgInfo({
          success: false,
          msg: ["An unexpected error occurred"],
        })
      );
    }
  }
};

export const getNotificationsThunk = () => async (dispatch) => {
  try {
    dispatch(initializingApi());
    const data = await getNoificationsService();

    dispatch(notifications(data));
  } catch (error) {
    dispatch(FailedApiCall());
  }
};

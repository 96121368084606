/* eslint-disable */
import React, { Fragment } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withAccount, withOrganisation, withMsgInfo } from '../hoc';
import { Input, Form, Button, BigCheckbox } from '../common/AuthFormItems';
import { BaseWorkspace } from '../common/Workspace';
import CreateOrgForm from '../common/Workspace/CreateOrgForm';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { RegisterBody, RegisterDivImage, RegisterDivChild } from './style';
// import ReactHtmlParser from 'react-html-parser';

const StyledMessage = styled.span`
  max-width: 100px; 
  white-space: normal; 
  word-wrap: break-word; 
`;

const BreakLine = styled.span`
  display: block;
`;

const StyledForm = styled(Form)`
   {
    width: ${(props) => (props.showNewOrgForm ? '600px !important' : '540px')};
  }
`;

const OrgRegisterFormButtonWrapper = styled.div`
  {
    display: flex
    & > button:first-child {
      background-color: gray !important;
      width: 30% !important;
    }
  }
`;

const regSchema = {
  username: {
    validation: 'required|min:2',
  },
  email: {
    validation: 'required|email',
  },
  password: {
    validation: 'required|min:14',
  },
};

const RegistrationActionButtons = (props) => {
  const { t, i18n } = useTranslation();
  const {
    isLoading,
    disabled,
    newOrganisationUserType,
    showNewOrgForm,
    hideOrgForm,
  } = props;

  let buttonName = t('Sign up');

  if (newOrganisationUserType) {
    buttonName = 'Continue';
  }

  return (
    <React.Fragment>
      {showNewOrgForm ? (
        <OrgRegisterFormButtonWrapper>
          <Button
            type="button"
            name={'Back'}
            disabled={isLoading}
            onClick={hideOrgForm}
          />
          <Button
            type="submit"
            name={'Finish Registration'}
            isLoading={isLoading}
            disabled={disabled}
          />
        </OrgRegisterFormButtonWrapper>
      ) : (
        <Button
          type="submit"
          name={buttonName}
          isLoading={isLoading}
          disabled={disabled}
        />
      )}
      <p
        className="font-small black-text d-flex justify-content-end"
        style={{ fontSize: 15 }}
      >
        {t('Already have an account?')}
        <Link to="/login" className="blue-text ml-1 font-weight-bold">
          {t('Login')}
        </Link>
      </p>
    </React.Fragment>
  );
};


class Register extends BaseWorkspace {
  isRegistrationPage = true;
  joinOrg = this.asyncAction('joinOrgSignup');
  createOrg = this.asyncAction('createOrgSignup');
  joinPersonal = this.asyncAction('joinPersonalSignup');
  createPersonal = this.asyncAction('createPersonalSignup');

   // Allowed email array
   allowedEmails = [
      'bmkluge@hotmail.com', 
      'benjamin.kluge@googlemail.com', 
      'benjamin.kluge@posteo.de', 
      'azizakinsola@gmail.com',  
      'harkzed@gmail.com', 
      'azeez.akinsola@outlook.com',
      'cooverotech@gmail.com',
      'support@admincat.net',
      'data@admincat.net',
      'admin@admincat.net',
      'comm@admincat.net',
      'prodtest1@admincat.net',
      'prodtest2@admincat.net',
      'test@admincat.net',
      'akinsola@eminirekanprayerministry.com',
      'azeeztesting@gmail.com',
      'akinsolatesting@gmail.com',
      'bmkluge@web.de',
      'bmkluge@yahoo.de',
      'azeez@eminirekanprayerministry.com',
      'ayodamolatiwa@gmail.com',
      'segun.akinsola@hotmail.com',
      'adeniyi.akinsola@hotmail.com',
      'bmkluge@protonmail.com',
      'azeezakinsola@protonmail.com',
  ];

   // Method to check if email is allowed
   isEmailAllowed = (email) => {
     return this.allowedEmails.includes(email);
   };

   // Handle custom submit method  to check email
   handleCustomSubmit = (e, orgFormData) => {
    e.preventDefault();
    const { email } = this.state;
    const { setMsgInfo, t } = this.props;
    if (this.isEmailAllowed(email)) {
      //Proceed with original submit logic
      this.onSubmit(e, orgFormData);
    } else {
      // Store the email in localStorage before redirecting
      localStorage.setItem('waitingListEmail', email);
      // Handle case where email is not allowed
      setMsgInfo({
        success: false,
        // msg: [`${t('Registration is not allowed for this email address, please check back')}`],
        msg: [<StyledMessage>
          {t('Oh, sorry! Unfortunately the limit of registrations has already been reached.')}{' '}
          <BreakLine>{t('However')} {t(', you are welcome to put your name ')}
          <Link to="/waiting-list">{t('on the waiting list')}</Link></BreakLine>
          
        </StyledMessage>],
        
      });
    }
  };


  render() {
    const { state, onChange, hideOrgForm } = this;
    const {
      tr,
      organisation: { orgFormData },
      onChangeOrg,
      getRegisterInstitute,
      registerInstitutesOptions,
      t,
    } = this.props;

    const { spaceType, newOrganisationUserType, showNewOrgForm } = this.state;
    const style = {
      height: '100%',
      background: '#fff',
    };
    return (
      <div style={style}>
        <RegisterBody>
          <RegisterDivImage></RegisterDivImage>
          <RegisterDivChild>
            <StyledForm
              // onSubmit={(e) => this.onSubmit(e, orgFormData)}
              onSubmit={(e) => this.handleCustomSubmit(e, orgFormData)}
              isLoading={state.isLoading}
              title={showNewOrgForm ? t('Organization Information') : t('Register')}
              formData={state}
              showNewOrgForm={showNewOrgForm}
              validationSchema={regSchema}
              renderActionButtons={() => {
                return (
                  <RegistrationActionButtons
                    isLoading={state.isLoading}
                    hideOrgForm={hideOrgForm}
                    disabled={!state.termsAgreed}
                    tr={tr}
                    spaceType={spaceType}
                    newOrganisationUserType={newOrganisationUserType}
                    showNewOrgForm={showNewOrgForm}
                  />
                );
              }}
            >
              {(formProps) => (
                <Fragment>
                  {!showNewOrgForm ? (
                    <Fragment>
                      {/* {this.renderRadioButtonArray()} */}

                      <Input
                        icon="envelope"
                        onChange={onChange}
                        value={state.email}
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        {...formProps}
                      />
                      <div className="md-form password-block">
                        <Input
                          icon="lock"
                          onChange={onChange}
                          type="password"
                          value={state.password}
                          name="password"
                          label="Password"
                          placeholder={t('Password')}
                          fieldType="password"
                          {...formProps}
                        />
                        <Input
                          icon="user"
                          onChange={onChange}
                          value={state.username}
                          name="username"
                          label={'Username'}
                          placeholder={t('Username')}
                          {...formProps}
                        />
                      </div>
                      {this.renderPersonalOrOrganisationForm()}

                      <BigCheckbox
                        onChange={onChange}
                        value={state.termsAgreed}
                        name="termsAgreed"                
                      >
                       <span style={{color:'#000'}}>{t('I agree with the')}{' '}</span> 
                        <Link to="/user-terms">{t('Terms and Conditions.')}</Link>
                      </BigCheckbox>

                      <br />
                    </Fragment>
                  ) : (
                    <CreateOrgForm
                      onChangeOrg={onChangeOrg}
                      orgFormData={orgFormData}
                      getRegisterInstitute={getRegisterInstitute}
                      registerInstitutesOptions={registerInstitutesOptions}
                    />
                  )}
                </Fragment>
              )}
            </StyledForm>
          </RegisterDivChild>
        </RegisterBody>
      </div>
    );
  }
}

export default compose(withAccount, withTranslation(), withOrganisation, withMsgInfo)(Register);

import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import ContentTemplate from 'components/common/ContentTemplate';
import { Button } from 'components/common/FormItems';
import {
  withBulkDelete,
  withUserLayoutChoice,
  withTranslation,
} from 'components/hoc';
import { DnDPortal } from 'components/common/FormItems/DnDPortal';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import { TrashFill, Clipboard2, PlusLg, ArrowDownUp } from 'react-bootstrap-icons';
import swal from 'sweetalert';

// const ButtonWrapper = styled.div`
//   margin-top: -6px;
// `;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  margin-top: 5px;
  disply: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
`;


const StyledButton = styled(Button)`
  background: var(--admincat-color-grey-6);
  border-radius: 2px;
  height: 38px;
  color: ${(props) => props.color || 'var(--admincat-color-grey-7)'};
  border: none;
  font-size: 14px;
  line-height: 10px;
  // remove this styles below,
  // if you want to see the full button when resized
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledContentTemplate = styled(ContentTemplate)`
  div.table-responsive {
    height: auto;
    min-height: 70vh;
    max-height:70vh;
    overflow-y: auto
    width: auto;
    background: var(--admincat-color-grey-1);
    max-width: 94%;
  
    @media screen and (max-width: 768px) {
        height: auto;
        min-height: 70vh;
        max-height:70vh;
        overflow-y: auto
        width: auto;
        max-width: 87%;
        background: var(--admincat-color-grey-1);
    }
    @media screen and (min-width: 1200px) {
      height: auto;
      min-height: 70vh;
      max-height:70vh;
      overflow-y: auto
      width: auto;
      max-width: 96%;
      background: var(--admincat-color-grey-1);
  }
  }
`;

const OrgContentTemplate = (props) => {
  const {
    toggleForm,
    bulkDelete = () => {},
    rowIds,
    hasBulkDelete,
    viewData,
    getLayoutColumnProps,
    tableName,
    frontendTableName: fTableName,
    backendTableName: bTableName,
    columns: defaultColumns,
    useAddModalForm,
    handleCreateForm,
    isFile,
    msg,
    tr,
    revertView,
    viewName = false,
    gotoDetailPath,
    handleViewForm,
    options,
    setRowDetailToState = () => {},
    executeOnRowClick = () => {},
    ...rest
  } = props;

  const frontendTableName = fTableName || tableName;
  const backendTableName = bTableName || tableName;

  const { columns, layoutColumns } = getLayoutColumnProps(
    frontendTableName,
    backendTableName,
    defaultColumns,
  );

  const deleteBulk = async () => {
    if (rowIds.length == 0) {
      swal(
        tr('Warning!'),
        tr(`You have not selected any item to delete`),
        'warning',
      );
      return;
    }
    // make api call
    try {
      await bulkDelete(rowIds);
    } finally {
      return;
    }
  };

  const viewTimeDependentName = () => {
    if (rowIds.length == 0) {
      swal(tr('Warning!'), tr(`You haven't selected any item to view.`), 'warning');
      return;
    }
    // history.push('/dashboard/data/base/person/create');
    // navigate to the detail page of the first item
    // leverage the view function in the options prop to navigate to a new page
    let viewOption = options.filter(
      (option) => option.name.toLowerCase() == 'Update Name'.toLowerCase(),
    );
    viewOption = viewOption.length > 0 ? viewOption[0] : () => {};
    const idToView = rowIds[0];

    viewOption.onClick({ id: idToView });
    return;
  };

  const onAddClicked = handleCreateForm || toggleForm;

  return (
    <React.Fragment>
      {msg}
      <HeaderWrapper className="flex">
        <ButtonWrapper className="flex" style={{ marginBottom: '15px' }}>
          {useAddModalForm && (
            <StyledButton
              className="option-btn"
              name="Add Favourite"
              reactIcon={<PlusLg color="#0E8436" size={12} className="mr-2" />}
              onClick={() => onAddClicked()}
              disabled={isFile}
            />
          )}
          {hasBulkDelete && (
            <StyledButton
              className="option-btn"
              onClick={deleteBulk}
              reactIcon={<TrashFill color="#F00" size={16} className="mr-3" />}
              name="Delete Marked"
            />
          )}
          <DnDPortal tableName={frontendTableName} columns={layoutColumns} />

          {viewName && (
            <StyledButton
              className="option-btn"
              onClick={viewTimeDependentName}
              reactIcon={<Clipboard2 color="#4C818E" size={16} className="mr-3" />}
              name="View Name"
            />
          )}

          {revertView && (
            <StyledButton
              className="option-btn"
              onClick={viewTimeDependentName}
              reactIcon={<ArrowDownUp color="#4C818E" size={16} className="mr-3" />}
              name="Revert"
            />
          )}
        </ButtonWrapper>
      </HeaderWrapper>
      <StyledContentTemplate
        {...rest}
        columns={columns}
        hasBulkDelete={hasBulkDelete}
        viewData={viewData}
        rowIds={rowIds}
        showDetailOnRowClick
        setRowDetailToState={setRowDetailToState}
        executeOnRowClick={executeOnRowClick}
      />
    </React.Fragment>
  );
};

OrgContentTemplate.defaultProps = {
  useAddModalForm: true,
  handleCreateForm: null,
  frontendTableName: null,
  backendTableName: null,
  columns: [],
};

export default compose(
  withBulkDelete,
  withUserLayoutChoice,
  withFileSize,
  withTranslation,
)(OrgContentTemplate);

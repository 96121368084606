import axios from '../axios';
import { createTypes } from './createTypes';
import createAction from './createAction';
import { setMsgInfo } from './msgInfo';
import API from './api';
import * as types from './types';

const baseUrl = `${API}/user/space/`;

export function setDefaultSpaceCurrency(data) {
  return async (dispatch) => {
    try {
      dispatch(createAction(createTypes(types.USER_DEFAULT_SPACE).success, data));
      return data;
    } catch (error) {
      throw error;
    }
  };
}


export function getUserDefaultSpace() {
  return async (dispatch) => {
    try {
      dispatch(createAction(createTypes(types.USER_DEFAULT_SPACE).loading, true));
      const { data } = await axios.get(`${baseUrl}`);
      dispatch(createAction(createTypes(types.USER_DEFAULT_SPACE).success, data));
      return data;
    } catch (error) {
      throw error;
    }
  };
}


export function setUserDefaultSpace(payload) {
  return async (dispatch) => {
    try {
      dispatch(createAction(createTypes(types.USER_DEFAULT_SPACE).loading, true));
      const { data } = await axios.put(`${baseUrl}`, payload);

      dispatch(createAction(createTypes(types.USER_DEFAULT_SPACE).success, data));

      // dispatch(
      //   setMsgInfo({
      //     success: true,
      //     msg: ['Workspace successfully changed'],
      //   }),
      // );
      return data;
    } catch (error) {
      throw error;
    }
  };
}


export function setSpaceCurrency(spaceName, payload) {
  return async (dispatch) => {
    try {
      await axios.patch(`${API}/space-currency/${spaceName}/`, payload);
      await getUserDefaultSpace()(dispatch);
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Default currency saved'],
        }),
      );
    } catch (error) {
      throw error;
    }
  };
}

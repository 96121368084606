import * as types from './types';
import baseAction from './baseAction';

export const getCountry = baseAction(types.COUNTRY, 'country');
export const getState = baseAction(types.STATE, 'state');
export const getCity = baseAction(types.CITY, 'city');
export const getTitle = baseAction(types.TITLE, 'title');
export const getSex = baseAction(types.SEX, 'sex');
export const getEthnicGroup = baseAction(types.ETHNIC_GROUP, 'ethnic-group');
export const getReligion = baseAction(types.RELIGION, 'religion');
export const getMaritalStatus = baseAction(types.MARITAL_STATUS, 'marital-status');
export const getLegalForm = baseAction(types.LEGAL_FORM, 'legal-form');
export const getRelationPart = baseAction(types.RELATION_PART, 'relation-part');
export const getAddressType = baseAction(types.ADDRESS_TYPE, 'address-type');
export const getStreetDirection = baseAction(
  types.STREET_DIRECTION,
  'street-direction',
);
export const getContactType = baseAction(types.CONTACT_TYPE, 'contact-type');
export const getEyeColor = baseAction(types.EYE_COLOR, 'eye-color');
export const getHeightUnit = baseAction(types.HEIGHT_UNIT, 'height-unit');
export const getLanguage = baseAction(types.LANGUAGE, 'language');
export const getCommentCategory = baseAction(
  types.COMMENT_CATEGORY,
  'comment-category',
);
export const getWishType = baseAction(types.WISH_TYPE, 'wish-type');
export const getNationality = baseAction(types.NATIONALITY, 'nationality');
export const getFormStatus = baseAction(types.FORM_STATUS, 'form-status');
export const getFormType = baseAction(types.FORM_TYPE, 'form-type');
export const getDocumentType = baseAction(types.DOCUMENT_TYPE, 'document-type');
export const getAllWorkspace = baseAction(types.ALL_WORKSPACE, 'spaces');
export const getAllPersonalWorkspace = baseAction(
  types.ALL_PERSONAL_WORKSPACE,
  'space/personal',
);
export const getAllOrganisationWorkspace = baseAction(
  types.ALL_ORGANISATION_WORKSPACE,
  'space/organisation',
);
export const getFieldCatalog = baseAction(types.FIELD_CATALOG, 'field-catalog');
export const getCurrency = baseAction(types.CURRENCY, 'currency');
export const getNumberFormat = baseAction(types.NUMBER_FORMAT, 'number-format');
export const getIndustryType = baseAction(types.INDUSTRY_TYPE, 'industry-type');
export const getDataArea = baseAction(types.DATA_AREA, 'data-area');
export const getExpenseCategory = baseAction(
  types.EXPENSE_CATEGORY,
  'expense-category',
);
export const getExpenseItemReference = baseAction(
  types.EXPENSE_ITEM_REFERENCE,
  'expense-item-reference',
);
export const getMeasurementUnit = baseAction(
  types.MEASUREMENT_UNIT,
  'measurement-unit',
);
export const getLanguageLevel = baseAction(
  types.GET_LANGUAGE_LEVEL,
  'language-level',
);
export const getSchoolGraduation = baseAction(
  types.GET_SCHOOL_GRADUATION,
  'school-graduation',
);
export const getSchoolSubject = baseAction(
  types.GET_SCHOOL_SUBJECT,
  'school-subject',
);
export const getSchoolType = baseAction(types.GET_SCHOOL_TYPE, 'school-type');
export const getStudyField = baseAction(types.GET_STUDY_FIELD, 'study-field');
export const getProfession = baseAction(types.GET_PROFESSION, 'profession');
export const getDiseaseTypes = baseAction(types.GET_DISEASE_TYPES, 'disease');
export const getPersonNamesReason = baseAction(
  types.PERSON_NAMES_REASON,
  'person-names-reason',
);
export const getOrganisationMotivation = baseAction(
  types.ORG_MOTIVATION,
  'org-motivation',
);
export const getOrganisationFunction = baseAction(
  types.ORG_FUNCTION,
  'org-function',
);
export const getPublicRespArea = baseAction(
  types.PUBLIC_RESP_AREA,
  'public-resp-area',
);
export const getAccountCategory = baseAction(
  types.GET_ACCOUNT_CATEGORY,
  'account_category',
);
export const getFinanceCategory = baseAction(
  types.GET_FINANCIAL_CATEGORY,
  'financial_category',
);
export const getAnimal = baseAction(
  types.ANIMAL,
  'animal',
);
export const getBSPosition = baseAction(
  types.BS_POSITION,
  'dashboard/bs_position',
);
export const getDocumentCategory = baseAction(
  types.GET_DOCUMENT_CATEGORY,
  'document_category',
);


export const getAutoBrand = baseAction(types.AUTO_BRAND, 'auto-brand');
export const getEnergySource = baseAction(types.ENERGY_SOURCE, 'energy-source');
export const getMeansOfTransport = baseAction(types.MEANS_OF_TRANSPORT, 'means-of-transport');
export const getMotorColors = baseAction(types.MOTOR_COLORS, 'motor-colors');
export const getYears = baseAction(types.YEARS, 'years');

// export const getDiseaseTypes = baseAction(
//   types.GET_DISEASE_TYPES,
//   'disease'
// )

import swal from 'sweetalert';

class DeleteConfirmation {

  constructor(){
    this.swal = swal;
    this.setDefault(); 
  }

  init = (DeleteRequest, selectedData, tr) => {
    this.DeleteRequest = DeleteRequest;
    this.selectedData = selectedData;
    this.isDeleteSingle = true;
    this.tr = tr; 
    return this.showModal();
  }

  initBulk = (DeleteRequest, selectedDataArray, tr) => {
    if(!selectedDataArray.length) return Promise.resolve();
    this.DeleteRequest = DeleteRequest;
    this.selectedDataArray = selectedDataArray;
    this.isDeleteSingle = false;
    this.tr = tr; 
    return this.showModal();
  }

  showModal = () => {
    return this.swal({
      title: this.tr("Delete?"),
      text: this.tr("Are you sure you want to delete?"),
      // text: this.tr("Are you sure you want to delete {{type}} data?", {
      //   type: this.isDeleteSingle ? this.tr("this") : this.tr("these")
      // }),
      // text: `Are you sure you want to delete ${this.isDeleteSingle ? "this" : "these"} data?`,
      icon: "warning",
      // buttons: true,
      // buttons: [true, this.tr("Yes!")],
      buttons: {
        cancel: {
          text: this.tr("No"),
          value: false,
          visible: true,
        },
        confirm: {
          text: this.tr("Yes"),
          value: true,
        }
      },
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          this.swal(
            this.tr("Deleting!"),
            this.tr("Please wait..."),
            "info", 
            {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          });

          if(this.isDeleteSingle){
            return this.deleteSingle();
          } else {
            return this.deleteBulk();
          }
        }
      })
  }

  deleteSingle = async () => {
    try {
      await this.DeleteRequest(this.selectedData.id, this.selectedData)
      this.swal(
        this.tr("Deleted!"),
        this.tr("Data has been deleted!"),
        "success"
      );
      this.setDefault();
    } catch (e) {
      this.swal(
        this.tr("Oops!"),
        this.tr("Data could not be deleted"),
        "error"
      )
      this.setDefault();
    }
  }

  deleteBulk = async () => {
    try {
      await this.DeleteRequest(this.selectedDataArray)
      this.swal(
        this.tr("Deleted!"),
        this.tr("Data has been deleted!"),
        "success"
      );
      this.setDefault();
    } catch (e) {
      this.swal(
        this.tr("Oops!"),
        this.tr("Data could not be deleted"),
        "error"
      )
      this.setDefault();
    }
  }

  setDefault(){
    this.selectedData = null;
    this.selectedDataArray = [];
    this.DeleteRequest = null;
    this.isDeleteSingle = false;
  }

}

const deleteConfirmation = new DeleteConfirmation();

export { deleteConfirmation };

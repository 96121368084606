import axios from '../../../axios'
import {
  LIST_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DASHBOARD_SUMMARY,
} from 'utils/apiEndPoints';
import { setMsgInfo } from 'actions';


export const dashboardSummaryService = async () => {
  try {
    const { data } = await axios.get(DASHBOARD_SUMMARY);
    return { data: data?.data };
  } catch (error) {
    throw error;
  }
};

export const getNoificationsService = async () => {
  try {
    const { data } = await axios.get(LIST_ALL_NOTIFICATIONS);

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteNoificationsService = async (id) => {
  try {
    const response = await axios.delete(DELETE_NOTIFICATION + `${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

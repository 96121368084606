import React from 'react'
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
    withPersonalInfo,
    withCustomRouter,
    withTranslation,
    withOrganisation,
    withMsgInfo,
    withAutoMobileInfo,
    withMeasurementUnit,
    withAutoBrand, 
    withEnergySource, 
    withMeansOfTransport, 
    withMotorColors, 
    withYears,
  } from 'components/hoc';
import {
    Select,
    Input,
    Form,
    InputDatePicker,
    ButtonLink,
  } from 'components/common/FormItems';


  const Wrapper = styled.div``;

  const DualSelectWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      min-width: 200px;
      flex: 1;
      margin-right: 10px;
  
      :last-child {
        margin-right: 0px;
      }
    }
  `;

  
const DualInputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
    min-width: 150px;
    flex: 1;
    margin-right: 1rem;

    :last-child {
        margin-right: 0px;
    }
    }
    `;

  const StyledButtonLink = styled(ButtonLink)`
    span {
      text-transform: uppercase;
    }
  `;
  
  const AutoMobileFormSchema = {
    means_of_transport: {
      validation: 'required',
    },
    auto_brand: {
      validation: 'required',
    },

  };

const AutoMobileForm = (props) => {
    const {
        tr, 
        basePath,
        routeParams,
        updateAutoMobile,
        addAutoMobile,
        getAutoMobileById,
        setMsgInfo,
        meansOfTransportLocaleOptions,
        autoBrandLocaleOptions,
        energySourceLocaleOptions,
        motorColorsLocaleOptions,
        yearsLocaleOptions,
      } = props;

    const [formData, setFormData] = React.useState({});
    const [formSubmitting, setFormSubmitting] = React.useState(false);
    const [formLoading, setFormLoading] = React.useState(false);

    const { id } = routeParams;
    const history = useHistory();
    const dispatch = useDispatch();

    const fetchData = async () => {
        if (id) {
        try {
            setFormLoading(true);
            const data = await getAutoMobileById(id);
            setFormData({ ...data });
            setFormLoading(false);
        } catch (e) {
            setFormData({});
            setFormLoading(false);
        }
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const onChange = (e) => {
        if (formSubmitting) return;
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
      };

    const onSubmit = async (e) => {
        try {
        let response;
        setFormSubmitting(true);
        if (id) {
            response = await updateAutoMobile(id, formData);
            dispatch(
            setMsgInfo({
                success: true,
                msg: [`${tr('Auto Mobile updated successfully')}`],
            }),
            );
        } else {
            response = await addAutoMobile(formData);
            dispatch(
            setMsgInfo({
                success: true,
                msg: [`${tr('Auto Mobile added successfully')}`],
            }),
            );
        }
        setFormSubmitting(false);

        history.push(basePath);
        window.scrollTo(0, 0);
    
        if (response?.response?.status >= 400) return;
        return;
        } catch (e) {
        setFormSubmitting(false);
        return;
        }
    };

    return (
        <Wrapper className="form-wrapper">
        <div className="flex">
          <StyledButtonLink
            to={basePath}
            className="option-btn"
            icon="angle double left"
            name="All Entries"
          />
        </div>
        <div className="row">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
            <br />
    
              <Form
                onSubmit={onSubmit}
                formData={formData}
                isLoading={formSubmitting}
                loadingData={formLoading}
                validationSchema={AutoMobileFormSchema}
                title="Auto Mobile"
              >
                <Select
                  optionDefaultValue={tr('Means of Transport Category')}
                  label={tr('Means of Transport Category')}
                  value={formData?.means_of_transport || ''}
                  name="means_of_transport"
                  onChange={onChange}
                  localeOptions={meansOfTransportLocaleOptions}
                  deselectable
                  showRequiredAsterisk
                />

                <Select
                    optionDefaultValue={tr('Choose Brand')}
                    label={tr('Brand')}
                    value={formData?.auto_brand}
                    name="auto_brand"
                    onChange={onChange}
                    localeOptions={autoBrandLocaleOptions}
                    deselectable
                    showRequiredAsterisk
                />

                <DualInputWrapper>
                    <Input
                        className="mr-md-1"
                        value={formData.model_series || ''}
                        onChange={onChange}
                        name="model_series"
                        id="model_series"
                        placeholder="Model Series"
                        label="Model Series"
                    />

                    <Input
                        className="ml-md-1"
                        value={formData.model_series_type || ''}
                        onChange={onChange}
                        name="model_series_type"
                        id="model_series_type"
                        placeholder="Enter model series type"
                        label="Type (as model specification)"
                    />
                </DualInputWrapper>

                <DualSelectWrapper>
                    <Select
                    optionDefaultValue={tr('Choose Manufacture Year')}
                    label={tr('Manufacture year')}
                    value={formData.year_of_manufacture || ''}
                    name="year_of_manufacture"
                    onChange={onChange}
                    localeOptions={yearsLocaleOptions}
                    deselectable
                    />
                    <Select
                        optionDefaultValue={tr('Choose Color')}
                        label={tr('Color')}
                        value={formData?.mot_color}
                        name="mot_color"
                        onChange={onChange}
                        localeOptions={motorColorsLocaleOptions}
                        deselectable
                    />
                </DualSelectWrapper> 

                <Select
                    optionDefaultValue={tr('Choose Energy Source')}
                    label={tr('Fuel or Energy Source')}
                    value={formData?.energy_source || ''}
                    name="energy_source"
                    onChange={onChange}
                    localeOptions={energySourceLocaleOptions}
                    deselectable
                    sort={true}
                />

                <DualSelectWrapper>
                    <Input
                        className="mr-md-1"
                        value={formData.max_speed || ''}
                        onChange={onChange}
                        name="max_speed"
                        id="max_speed"
                        placeholder="Enter Maximum Speed"
                        label="Maximum Speed"
                    />
                    <Select
                        className="ml-md-1"
                        optionDefaultValue={tr('Choose Measurement Unit')}
                        label={tr('Measurement Unit')}
                        value={formData?.measurement_unit}
                        name="measurement_unit"
                        onChange={onChange}
                        localeOptions={props.speedMeasurementLocaleOptions}
                        deselectable
                    />
                </DualSelectWrapper> 

                <DualInputWrapper>
                    <Input
                        className="mr-md-1"
                        value={formData.power || ''}
                        onChange={onChange}
                        name="power"
                        id="power"
                        placeholder="Rated Power"
                        label="Rated Power in KW"
                    />

                    <Input
                        className="ml-md-1"
                        value={formData.cubic_capacity || ''}
                        onChange={onChange}
                        name="cubic_capacity"
                        id="cubic_capacity"
                        placeholder="Cubic Capacity"
                        label="Cubic Capacity in cm³"
                    />
                </DualInputWrapper>

                <DualInputWrapper>
                    <Input
                        className="mr-md-1"
                        value={formData.no_of_doors || ''}
                        onChange={onChange}
                        name="no_of_doors"
                        id="no_of_doors"
                        placeholder="Enter number of doors"
                        label="Number of doors"
                    />

                    <Input
                        className="ml-md-1"
                        value={formData.no_of_seats || ''}
                        onChange={onChange}
                        name="no_of_seats"
                        id="no_of_seats"
                        placeholder="Enter number of seats"
                        label="Number of seats"
                    />
                </DualInputWrapper>
                <Input
                    value={formData.vehicle_id_no || ''}
                    onChange={onChange}
                    name="vehicle_id_no"
                    id="vehicle_id_no"
                    placeholder="Vehicle Identification Number"
                    label="Vehicle Identification Number"
                />

                <InputDatePicker
                    label="Initial Registration Date"
                    value={formData?.initial_reg_date || ''}
                    name="initial_reg_date"
                    onChange={onChange}
              />
            </Form>
            </div>
          </div>
        </Wrapper>
      )
}

export default compose(
    withPersonalInfo,
    withCustomRouter,
    withTranslation,
    withAutoMobileInfo,
    withOrganisation,
    withMsgInfo,
    withAutoBrand, 
    withEnergySource, 
    withMeansOfTransport, 
    withMotorColors, 
    withMeasurementUnit,
    withYears,
  )(AutoMobileForm);
  
import React from "react";
import { connect } from "react-redux";
import {
  getUserDefaultSpace,
  setUserDefaultSpace,
  setSpaceCurrency,
  setMsgInfo,
} from "../../../actions";
import hoistNonReactStatics from 'hoist-non-react-statics'
import { withTranslation } from 'components/hoc';

let loaded = false;

export const withUserDefaultSpace = (WrapperComponent) => {
  class Enhance extends React.Component {
    
    componentDidMount() {
      const { getUserDefaultSpace } = this.props;
      if (loaded) return;
      loaded = true;
      getUserDefaultSpace();
    }

    changeUserDefaultSpace = async (space) => {
      const { setUserDefaultSpace, setMsgInfo, tr} =  this.props;
      try {
        await setUserDefaultSpace({ space });
        setMsgInfo({
          success: true,
          msg: [`${tr('Workspace successfully changed')}`],
        });

        // Add delay using Promise to ensure message is shown
        // await new Promise(resolve => setTimeout(resolve, 1000)); // 1.5 second delay

        // Then reload and scroll
        // window.scrollTo(0, 0);
        // window.location.reload();
      
        // Use useCallback if this function is passed as a prop
        // const delayedReload = () => {
        //   window.scrollTo(0, 0);
        //   window.location.reload();
        // };
        // // Set timeout for reload
        // setTimeout(delayedReload, 2000);

      } catch (error) {
        setMsgInfo({
          success: false,
          msg: [`${tr('Failed to change workspace')}`],
        });
        throw error;
      }
      finally {
        window.location.reload(true);
        window.scroll(0, 0);
      }
    };

    // changeUserDefaultSpace = async (space) => {
    //   const { setUserDefaultSpace } = this.props
    //   await setUserDefaultSpace({ space });
    //   // window.location.reload();
    // }

    render() {
      return (
        <WrapperComponent 
          {...this.props} 
          changeUserDefaultSpace={this.changeUserDefaultSpace}
        />
      )
    }
  }
  const mapStateToProps = ({ userDefaultSpace }) => ({ userDefaultSpace })
  
  const WithUserDefaultSpace = connect(mapStateToProps, {
    getUserDefaultSpace,
    setUserDefaultSpace,
    setSpaceCurrency,
    setMsgInfo,
  })(Enhance);
  hoistNonReactStatics(WithUserDefaultSpace, WrapperComponent)
  // return WithUserDefaultSpace
  return withTranslation(WithUserDefaultSpace);
}

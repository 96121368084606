import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import compose from "lodash/fp/compose";
import { useLocation } from "react-router-dom";
import { 
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
} from "components/hoc";
import MyDataContentTemplate from '../../common/MyDataContentTemplate';
import LottieLoader from 'components/common/LottieLoader';

const PersonGroup = (props) => {
  const location = useLocation();

  const { 
    loaded,
    deleteBulkPersonGroup,
    gotoCreatePath,
    routePathname,
  } = props;



  const mapFields = () => {
    const { personGroup } = props;
    return personGroup.data.map((data) => {
      return { 
        ...data, 
        space_name: data.space,
      };
    });
  };

  if (!loaded) {
    return (
      <>
        <LottieLoader />
      </>
    );
  }

  return (
    <MyDataContentTemplate 
      tableName="person_group"
      columns={[]}
      data={mapFields()}
      toggleForm={gotoCreatePath}
      bulkDelete={deleteBulkPersonGroup}
    />
  );
}

export default compose(
  withTranslation,
  withPersonalInfo,
  withCustomRouter,
  withUserPreferences,
  withPersonGroup,
)(PersonGroup);

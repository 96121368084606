import React from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertToHTML } from 'draft-convert';
import * as mailActions from '../../../actions/dashboard/mail';
import { withDeleteConfirmation, withTranslation, withMsgInfo } from '..';
import { isEmptyValue } from '../../utils/checkValue';

let loaded = false;

const initialState = {
  to_mail: '',
  from_mail: '',
  subject: '',
  // message: "",
  message: EditorState.createEmpty(),
  area: '',
  sendMailLoading: false,
  openComposeForm: false,
};

export const withMail = (WrapperComponent) => {
  class Enhance extends React.Component {
    // constructor(props) {
    //   super(props)
    //   const blocksFromHTML = convertFromHTML(props.selectedMail?.message_html || '');
    //   this.state = {
    //     to_mail: "",
    //     from_mail: "",
    //     subject: "",
    //     // message: "",
    //     message: EditorState.createWithContent(
    //       ContentState.createFromBlockArray(
    //         blocksFromHTML.contentBlocks,
    //         blocksFromHTML.entityMap,
    //       )
    //     ),
    //     area: "",
    //     sendMailLoading: false,
    //     openComposeForm: false,
    //   }
    // };
    state = { ...initialState };

    timerId = null;

    async componentDidMount() {
      try {
        const { getAllMail, getMailServerInbox } = this.props;
        const getMailBoxes = async () => {
          getAllMail();
          getMailServerInbox();
        };
        if (!loaded) {
          await getMailBoxes();
          loaded = true;
        }
        const fiveMinutes = 5 * 60 * 1000;
        this.timerId = setInterval(getMailServerInbox, fiveMinutes);
      } catch (e) {
        loaded = true;
      }
    }

    componentWillUnmount() {
      this.timerId && clearInterval(this.timerId);
    }

    onChange = (e) => {
      const { value, name } = e.target;
      this.setState({ [name]: value });
    };

    redirectToInbox = () => {
      const { history } = this.props;
      history.goBack();
    };

    sendMail = async () => {
      const { addMail, setMsgInfo, tr } = this.props;
      this.setState({ sendMailLoading: true });
      const state = { ...this.state };
      state.message = draftToHtml(convertToRaw(state.message.getCurrentContent()));
      delete state.area;

      try {
        // Attempt to send the mail
        await addMail(state);
        setMsgInfo({
          success: true,
          msg: [`${tr('Mail sent successfully')}`],
        });
        // Reset state and loading flag on success
        this.redirectToInbox();
        this.setState({ ...initialState, sendMailLoading: false });
      } catch (error) {
        // Handle the error and reset loading flag
        console.error('Error sending mail:', error);
        this.setState({ sendMailLoading: false });
      }
    };

    updateMailArea = (area, rowIds) => {
      this.setState({ area });
      if (isEmptyValue(rowIds)) return;
      const { updateMailArea } = this.props;
      const payload = { ids: rowIds, area };
      updateMailArea(payload);
    };

    toggleComposeForm = (data) => {
      const { openComposeForm } = this.state;
      this.setState({
        ...(data ? data : initialState),
        openComposeForm: !openComposeForm,
      });
    };

    deleteBulkMail = async (data) => {
      const { deleteConfirmation, deleteBulkMail } = this.props;
      try {
        await deleteConfirmation.initBulk(deleteBulkMail, data);
      } catch (e) {}
    };
    deleteMailById = async (data) => {
      const { deleteConfirmation, deleteMailById } = this.props;
      try {
        await deleteConfirmation.init(deleteMailById, data);
      } catch (e) {}
    };
    deleteBulkMailPartially = async (data) => {
      const { deleteConfirmation, deleteBulkMailPartially } = this.props;
      try {
        await deleteConfirmation.initBulk(deleteBulkMailPartially, data);
      } catch (e) {}
    };
    deleteMailByIdPartially = async (data) => {
      const { deleteConfirmation, deleteMailByIdPartially } = this.props;
      try {
        await deleteConfirmation.init(deleteMailByIdPartially, data);
      } catch (e) {}
    };
    markMailAsRead = async (data) => {
      try {
        const { markMailAsRead, getAllMail } = this.props;
        const markMailAsReadAction = async () => {
          await markMailAsRead(data);
          await getAllMail();
        };
        await markMailAsReadAction();
      } catch (e) {}
    };
    markMailAsUnread = async (data) => {
      try {
        const { markMailAsUnread, getAllMail } = this.props;
        const markMailAsUnreadAction = async () => {
          await markMailAsUnread(data);
          await getAllMail();
        };
        await markMailAsUnreadAction();
      } catch (e) {}
    };
    undoMailDelete = async (data) => {
      try {
        const { undoMailDelete, getAllMail } = this.props;
        const undoMailDeleteAction = async () => {
          await undoMailDelete(data);
          await getAllMail();
        };
        await undoMailDeleteAction();
      } catch (e) {}
    };
    exportMail = async () => {
      try {
        const { exportMail } = this.props;
        await exportMail();
      } catch (e) {}
    };
    render() {
      const { state, props } = this;
      return (
        <WrapperComponent
          {...props}
          sendMail={this.sendMail}
          deleteMailById={this.deleteMailById}
          deleteBulkMail={this.deleteBulkMail}
          deleteMailByIdPartially={this.deleteMailByIdPartially}
          deleteBulkMailPartially={this.deleteBulkMailPartially}
          sendMailLoading={state.sendMailLoading}
          mailFormData={state}
          onMailChange={this.onChange}
          openComposeForm={state.openComposeForm}
          toggleComposeForm={this.toggleComposeForm}
          updateMailArea={this.updateMailArea}
          markMailAsRead={this.markMailAsRead}
          markMailAsUnread={this.markMailAsUnread}
          undoMailDelete={this.undoMailDelete}
          exportMail={this.exportMail}
          loaded={loaded}
        />
      );
    }
  }
  const mapStateToProps = ({ mail }) => ({ mail });
  const WithMail = connect(mapStateToProps, {
    ...mailActions,
  })(Enhance);
  hoistNonReactStatics(WithMail, WrapperComponent);
  return compose(withDeleteConfirmation, withRouter,  withTranslation, withMsgInfo)(WithMail);
};

import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { withDeleteConfirmation, withPersonalInfo, withTranslation } from '..';
import { bankAccountActions, createAction, setMsgInfo } from '../../../actions';
import * as types from '../../../actions/dashboard/finance/types';
import { transFormInputData } from './utils';

// let loaded = false;

export const withBankAccount = (WrappedComponent) => {
  class WithBankAccount extends Component {
    state = {
      // mode: "create",
      loaded: false,
    };
    constructor(props) {
      super(props);
    }

    async componentDidMount() {
      if (this.state.loaded) return;
      // loaded = true;
      await this.props.getAllBankAccount();
      this.setState({ ...this.state, loaded: true });
    }

    toggleForm = (data) => {
      const {
        bankAccountForm: { showForm },
      } = this.props;
      if (!showForm && data) {
        this.props.getBankAccountById(data.id);
      }
      this.props.setValue({ data: {}, showForm: !showForm });
    };

    togglePersonalBankAccountView = (data) => {
      const {
        bankAccountView: { showView },
      } = this.props;
      this.props.toggleViewModal({ data: {}, showView: !showView });
      if (!showView && data) {
        this.props.getBankAccountById(data.id);
      }
    };

    // togglePersonalBankAccountView = (data) => {
    //   if (data) {
    //     this.props.getBankAccountById(data.id);
    //   }
    // };

    getOrganizationFromBic = (BIC) => {
      this.props.getOrganizationWithBIC(BIC);
    };

    createBankAccount = async (data) => {
      const {
        data: { id },
      } = this.props.bankBIC;
      const accountData = transFormInputData(data, id);
      const res = await this.props.createBankAccount(accountData);
      this.props.getAllBankAccount();
      // this.setState({ openForm: false})
      return res;
    };

    updateBankAccount = async (data) => {
      const {
        singleBankAccount: {
          id,
          BIC_data: { id: orgId },
        },
        updateBankAccount,
      } = this.props;
      const accountData = transFormInputData(data, orgId);
      const res = await updateBankAccount(accountData, id);
      return res;
    };

    deleteBankAccountById = async (data) => {
      const { deleteConfirmation, deleteBankAccountById } = this.props;
      try {
        await deleteConfirmation.init(deleteBankAccountById, data);
      } catch (e) {}
    };

    deleteBulkBankAccount = async (data) => {
      const { deleteConfirmation, deleteBulkBankAccount, tr } = this.props;
      try {
        await deleteConfirmation.initBulk(deleteBulkBankAccount, data);
        // setMsgInfo({
        //   success: true,
        //   msg: [`${tr('Bank Account deleted successfully')}`],
          
        // });
      } catch (e) {}
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          mode={this.state.mode}
          loaded={this.state.loaded}
          toggleForm={this.toggleForm}
          togglePersonalBankAccountView={this.togglePersonalBankAccountView}
          getOrganizationFromBic={this.getOrganizationFromBic}
          createBankAccount={this.createBankAccount}
          updateBankAccount={this.updateBankAccount}
          deleteBankAccountById={this.deleteBankAccountById}
          deleteBulkBankAccount={this.deleteBulkBankAccount}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      ...state.bankAccount,
      // bankAccountForm: state.bankAccountForm,
      // bankAccountView: state.bankAccountView,
    };
  }

  const BankAccount = connect(mapStateToProps, {
    ...bankAccountActions,
    setValue: (data) => createAction(types.BANK_ACCOUNT_FORM, data),
    clearSingleBankData: () => createAction(types.CLEAR_SINGLE_BANK_ACCOUNT_DATA),
    // toggleViewModal: (data) => createAction(types.BANK_ACCOUNT_FORM_VIEW, data)
  })(WithBankAccount);
  hoistNonReactStatics(BankAccount, WrappedComponent);
  return compose(withDeleteConfirmation, withPersonalInfo, withTranslation)(BankAccount);
};

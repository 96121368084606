/* eslint-disable camelcase */
import axios from '../../axios';
import * as types from '../types';
// import createAction from '../createAction';
import { setMsgInfo, createAction } from '..';
import filterError from '../common/filterError';
import { createTypes } from '../createTypes';
import API from '../api';

const baseUrl = `${API}/dashboard`;

export const createContract = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${baseUrl}/contracts`, payload);
      dispatch(createAction(types.CREATE_CONTRACT, data));
      // dispatch(
      //   setMsgInfo({
      //     success: true,
      //     msg: [
      //       'Contract created successfully, you can proceed to add contract items',
      //     ],
      //   }),
      // );
      return data;
    } catch (err) {
      const errors = filterError(err);
      dispatch(
        setMsgInfo({
          success: false,
          msg: errors,
        }),
      );
      throw err;
    }
  };
};

export const createContractItems = (contract_items, contractId) => {
  return async (dispatch) => {
    try {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: true }));
      const { data } = await axios.post(
        `${baseUrl}/contracts/${contractId}/create_items`,
        {
          contract_items,
        },
      );
      dispatch(
        createAction(types.CREATE_CONTRACT, {
          contractItems: data,
          loading: false,
        }),
      );
    } catch (err) {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: false }));
      let errors;
      if (err.response.status === 500) {
        errors = 'Server error please try again';
      } else {
        errors = filterError(err);
        dispatch(setMsgInfo({ success: false, msg: errors }));
        return err;
      }
    }
  };
};

export function updateContract(formData, id) {
  return async (dispatch) => {
    try {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: true }));
      const { data } = await axios.patch(`${baseUrl}/contracts/${id}`, formData);
      dispatch(createAction(types.CREATE_CONTRACT, { data, loading: false }));
      return {
        data,
        error: null,
      };
    } catch (err) {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: false }));
      if (err.response.status === 500) {
        return {
          error: 'Server error please try again',
        };
      }
      const errors = filterError(err);
      dispatch(setMsgInfo({ success: false, msg: errors }));
      return err;
    }
  };
}

export const UpdateContractItems = (contract_items, contractId) => {
  return async (dispatch) => {
    try {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: true }));
      const { data } = await axios.patch(
        `${baseUrl}/contracts/${contractId}/update_items`,
        {
          contract_items,
        },
      );
      dispatch(
        createAction(types.CREATE_CONTRACT, { contractItems: data, loading: false }),
      );
      dispatch(
        setMsgInfo({
          success: true,
          msg: ['Contract items updated'],
        }),
      );
    } catch (err) {
      dispatch(createAction(types.CREATE_CONTRACT, { loading: false }));
      let errors;
      if (err.response.status === 500) {
        errors = 'Server error please try again';
      } else {
        errors = filterError(err);
      }
      dispatch(setMsgInfo({ success: false, msg: errors }));
      return err;
    }
  };
};

export function getContracts(dataArea) {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_CONTRACT,
        payload: { data: [], loading: true },
      });
      const data = await axios.get(
        `${baseUrl}/contracts${dataArea ? `?data_area=${dataArea}` : ''}`,
      );
      dispatch({
        type: types.GET_CONTRACT,
        payload: { data: data.data, loading: false },
      });
    } catch (err) {
      dispatch({
        type: types.GET_CONTRACT,
        payload: { data: [], loading: false },
      });
    }
  };
}

export function deleteContractById(contactId) {
  return async (dispatch) => {
    try {
      await axios.delete(`${baseUrl}/contracts/${contactId}`);
      dispatch({
        type: types.DELETE_CONTRACTS,
        payload: [contactId],
      });
    } catch (err) {
      throw err;
    }
  };
}

export function deleteBulkContract(contractIds) {
  return async (dispatch) => {
    try {
      await axios.post(`${baseUrl}/contracts/bulk_delete`, {
        selected_contract_ids: contractIds,
      });
      dispatch({
        type: types.DELETE_CONTRACTS,
        payload: contractIds,
      });
    } catch (err) {
      throw err;
    }
  };
}


// export const getSingleContract = (id) => {
//   return async (dispatch) => {
//     dispatch({ type: createTypes(types.GET_SINGLE_CONTRACT).loading });
//     try {
//       const { data } = await axios.get(`${baseUrl}/contracts/${id}`);
//       console.log('data', data)
//       dispatch({
//         type: createTypes(types.GET_SINGLE_CONTRACT).success,
//         payload: data,
//       });
//     } catch (err) {
//       const errors = filterError(err);
//       dispatch({
//         type: createTypes(types.GET_SINGLE_CONTRACT).failure,
//         payload: errors,
//       });
//       dispatch(
//         setMsgInfo({
//           success: false,
//           msg: errors,
//         }),
//       );
//     }
//   };
// };

export const getSingleContract = (contractId) => {
  return async (dispatch) => {
   
      dispatch({
        type: types.GET_SINGLE_CONTRACT,
        payload: { data: {}, loading: true },
      });
      try {
      const { data: { contract_items, ...rest } } = await axios.get(`${baseUrl}/contracts/${contractId}`);
      // console.log('rest', rest)
      dispatch({
        type: types.GET_SINGLE_CONTRACT,
        payload: {  data: rest, contract_items, loading: false },
      });
      // return data;
    } catch (err) {
      dispatch({
        type: types.GET_SINGLE_CONTRACT,
        payload: { data: {}, loading: false },
      });
    }
  };
};

export const setContractDetail = (contract) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SINGLE_CONTRACT,
        payload: { data: contract, loading: false },
      });
    } catch (err) {
      dispatch({
        type: types.SET_SINGLE_CONTRACT,
        payload: { data: {}, loading: false },
      });
    }
  };
};

export const getItemReferenceId = (reference) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.GET_ITEM_REFERENCE_ID,
        payload: { data: [], loading: true },
      });

      const { data } = await axios.get(
        `${API}/item-reference-id?reference=${reference}`,
      );
      const mapList = data.map((item) => {
        const itemArry = item;
        const id = itemArry.pop();
        const itemValue = itemArry.join(' ');
        return {
          long_description_eng: itemValue,
          id,
          long_description_deu: itemValue,
        };
      });
      dispatch({
        type: types.GET_ITEM_REFERENCE_ID,
        payload: { data: mapList, loading: false },
      });
    } catch (err) {
      dispatch({
        type: types.GET_ITEM_REFERENCE_ID,
        payload: { data: [], loading: false },
      });
    }
  };
};

export function filterContractById(contactId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.DELETE_CONTRACTS,
        payload: [contactId],
      });
      await axios.get(`${baseUrl}/contracts/${contactId}`);
      dispatch({
        type: types.DELETE_CONTRACTS,
        payload: [contactId],
      });
    } catch (err) {
      throw err;
    }
  };
}

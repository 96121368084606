import React from 'react';
import compose from 'lodash/fp/compose';
import {
  withMail,
  withBulkDelete,
  withTranslation,
  withDataArea,
  withUserLayoutChoice,
  withUserDefaultSpace,
  withUserPreferences,
} from 'components/hoc';
import withFileSize from 'components/hoc/FileSizeChecker/File';
import MailBox from '../MailInbox';


const Sent = (props) => {
  return (
    <>
      <MailBox filterBy="SENT" {...props} />
    </>
  );
};

export default compose(
  withMail,
  withBulkDelete,
  withTranslation,
  withDataArea,
  withUserLayoutChoice,
  withUserDefaultSpace,
  withUserPreferences,
  withFileSize,
)(Sent);

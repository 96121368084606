import React from "react";
import { connect } from "react-redux";
import { getMotorColors } from "actions";
import hoistNonReactStatics from 'hoist-non-react-statics'

let loaded = false;

export const withMotorColors = (WrapperComponent) => {
  class WithMotorColors extends React.Component {
    componentDidMount() {
      const { getMotorColors } = this.props;
      if (loaded) return;
      loaded = true;
      getMotorColors();
    }

    getMotorColorsOptions = () => undefined

    getMotorColorsLocaleOptions = () => {
      const { motorColors } = this.props;
      return motorColors.map(({ created_at, ...rest }) => ({ ...rest }));
    }
    
    render() {
      return (
        <WrapperComponent 
            motorColorsOptions={this.getMotorColorsOptions()} 
            motorColorsLocaleOptions={this.getMotorColorsLocaleOptions()} 
          {...this.props} 
        />
      )
    }
  }
  const mapStateToProps = ({ motorColors }) => ({ motorColors })
  const WithMotorColorsHOC = connect(mapStateToProps, {
    getMotorColors,
  })(WithMotorColors);
  hoistNonReactStatics(WithMotorColorsHOC, WrapperComponent)
  return WithMotorColorsHOC
}
/* eslint-disable */
import axios from '../../../axios';
import * as types from './types';

import { setMsgInfo, createAction } from '../..';
import API from '../../api';

const baseUrl = `${API}/dashboard`;

export const listIncomeTransaction = (category) => async (dispatch) => {
  dispatch(createAction(types.LIST_INCOME_TRANSACTION, { loading: true, data: [] }));
  try {
    const { data } = await axios.get(`${baseUrl}/transactions?category=${category}`);
    dispatch(createAction(types.LIST_INCOME_TRANSACTION, { data, loading: false }));
  } catch (error) {
    dispatch(
      createAction(types.LIST_INCOME_TRANSACTION, { data: [], loading: false }),
    );
    dispatch(
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Problem fetching transaction'],
        }),
      ),
    );
  }
};

export const getSingleIncomeTransaction = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}/transactions/${id}`);
    dispatch(createAction(types.GET_INCOME_TRANSACTION, data));
  } catch (error) {
    dispatch(
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Problem fetching transaction'],
        }),
      ),
    );
  }
};

export const deleteIncomeTransaction = (id) => async (dispatch) => {
  await axios.delete(`${baseUrl}/transactions/${id}`);
  dispatch({
    type: types.DELETE_INCOME_TRANSACTION,
    payload: id,
  });
};

export const deleteBulkIncomeTransaction = (transactionIds) => async (dispatch) => {
  await axios.post(`${baseUrl}/transactions/bulk_delete`, {
    transaction_ids: transactionIds,
  });
  dispatch({
    type: types.DELETE_BULK_INCOME_TRANSACTION,
    payload: transactionIds,
  });
};

export const createIncomeTransaction = (values) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${baseUrl}/transactions`, values);
    dispatch({
      type: types.CREATE_INCOME_TRANSACTION,
      payload: data,
    });
    // dispatch(
    //   dispatch(
    //     setMsgInfo({
    //       success: true,
    //       msg: ['Transaction created successfully'],
    //     }),
    //   ),
    // );
  } catch (error) {
    dispatch(
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Problem creating Transaction'],
        }),
      ),
    );
    return error;
  }
};

export const updateIncomeTransaction = (id, values) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${baseUrl}/transactions/${id}`, values);
    dispatch({
      type: types.UPDATE_INCOME_TRANSACTION,
      payload: data,
    });
  } catch (error) {
    dispatch(
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Problem updating transaction'],
        }),
      ),
    );
    return error;
  }
};

import React from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  withTranslation,
  withAnimal,
  withPersonalInfo,
  withCompanionAnimalInfo,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withMsgInfo,
} from '../../../../hoc';
import {
  InputDatePicker,
  Input,
  Form,
  ButtonLink,
  NewEntriesSelect,
} from '../../../../common/FormItems';
import { excludeUnsetField } from 'components/utils/excludeUnsetField';
import { RESPONSE_STRINGS } from '../../../../../constants';
import axios from '../../../../../axios';
import API from '../../../../../actions/api';
import swal from 'sweetalert';

const Wrapper = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const CompanionAnimalFormSchema = {
  name: {
    validation: 'required',
  },
  animal: {
    validation: 'required',
  },
  date_of_birth: {
    validation: 'required',
  },
};

const CompanionAnimalForm = (props) => {
  const {
    addCompanionAnimalInfo,
    getCompanionAnimalInfoById,
    updateCompanionAnimalInfo,
    routeParams,
    userPreferences,
    basePath,
    setMsgInfo,
  } = props;

  const { id: companionAnimalId } = routeParams;
  const history = useHistory();

  const [formData, setFormData] = React.useState({});
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formLoading, setFormLoading] = React.useState(false);
  const [showEntryForm, setShowEntryForm] = React.useState(false);
  const [entryValues, setEntryValues] = React.useState({
    value: '',
    language: userPreferences.language,
  });
  const [entrySubmitting, setEntrySubmitting] = React.useState(false);

  const dispatch = useDispatch();

  const fetchData = async () => {
    if (companionAnimalId) {
      try {
        setFormLoading(true);
        const { ...data } = await getCompanionAnimalInfoById(companionAnimalId);
        setFormData(data);
      } finally {
        setFormLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  const toggleEntryField = () => {
    setShowEntryForm(!showEntryForm);
  };

  const onEntryChange = (e) => {
    const { value, name } = e.target;
    setEntryValues({ ...entryValues, [name]: value });
  };

  const validateAndSubmitEntry = async (entryType) => {
    if (!(entryValues.name && entryValues.language)) {
      swal(
        tr('Warning!'),
        tr(`Please ensure all required fields are populated.`),
        'warning',
      );
      return;
    }
    try {
      setEntrySubmitting(true);
      const payload = {
        name: entryValues.name,
        language: entryValues.language,
        entry_type: entryType,
      };
      await axios.post(`${API}/propose/entry/`, payload);
      swal(
        tr('Success!'),
        tr(`${RESPONSE_STRINGS?.ENTRY_RESPONSE_SUCCESS_MESSAGE}`),
        'success',
      );
      setEntryValues({});
      setEntrySubmitting(false);
      setShowEntryForm(false);
      return;
    } catch (error) {
      console.log('error', error);
      setEntrySubmitting(false);
      setShowEntryForm(false);
    } finally {
      setEntrySubmitting(false);
      setShowEntryForm(false);
    }
  };

  const onChange = (e) => {
    if (formSubmitting) return;
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    if (formSubmitting) return;
    let payload = excludeUnsetField(formData);

    try {
      setFormSubmitting(true);
      if (!formData.id) {
        await addCompanionAnimalInfo(payload);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Companion Animal added successfully')}`],
          }),
        );
      } else {
        await updateCompanionAnimalInfo(formData.id, payload);
        dispatch(
          setMsgInfo({
            success: true,
            msg: [`${tr('Companion Animal updated successfully')}`],
          }),
        );
      }
      history.push(basePath);
    } catch (e) {
    } finally {
      setFormSubmitting(false);
    }
  };

  const { tr, activeLanguageOptions } = props;

  return (
    <Wrapper className="form-wrapper">
        <div className="flex">
          <StyledButtonLink
            to={basePath}
            className="option-btn"
            icon="angle double left"
            name="All Entries"
          />
        </div>
        <div className="row">
          <div
            className="col-7 ml-10 mt-30 container"
            style={{
              position: 'relative',
              background: 'var(--admincat-color-grey-1)',
            }}
          >
          <br/>
          <Form
            onSubmit={onSubmit}
            formData={formData}
            isLoading={formSubmitting}
            loadingData={formLoading}
            validationSchema={CompanionAnimalFormSchema}
            title="Companion Animal"
          >
            <Input
              onChange={onChange}
              value={formData.name || ''}
              name="name"
              placeholder="Name"
              label="Name"
              showRequiredAsterisk
            />
            <NewEntriesSelect
              value={formData.animal || ''}
              name="animal"
              label={tr('Animal')}
              showRequiredAsterisk
              optionDefaultValue={tr('Choose Animal')}
              localeOptions={props.animalLocaleOptions}
              onChange={(e) => {
                if (e.target.value == 'proposeValue') {
                  toggleEntryField();
                  return;
                } else {
                  onChange(e);
                }
              }}
              entryDefaultLanguage={entryValues.language}
              entryValue={entryValues.name}
              entryLanguageValue={entryValues.language}
              entryValueName={tr('Animal')}
              entryType={tr('Animal')}
              entryLanguageName={'language'}
              onEntryChange={onEntryChange}
              onCreateNewEntry={(e) => {
                e.preventDefault();
                validateAndSubmitEntry('animal');
              }}
              add={showEntryForm}
              toggleEntryField={toggleEntryField}
              options={activeLanguageOptions}
              isLoading={entrySubmitting}
              closeEntryForm={() => {
                toggleEntryField();
              }}
            />
            <InputDatePicker
              label="Birth Date"
              value={formData.date_of_birth || ''}
              name="date_of_birth"
              onChange={onChange}
              showRequiredAsterisk
            />

            <InputDatePicker
              label="Death Date"
              value={formData.date_of_death || ''}
              name="date_of_death"
              onChange={onChange}
            />
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default compose(
  withTranslation,
  withAnimal,
  withPersonalInfo,
  withCompanionAnimalInfo,
  withCustomRouter,
  withUserPreferences,
  withLanguage,
  withMsgInfo,
)(CompanionAnimalForm);
